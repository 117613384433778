import React from 'react';

const Svg = () => {
	const width = 15;
	const height = 15;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path d="M4.0625 4.68946C4.0625 2.66358 5.80222 1.07216 7.83656 1.26601C9.55271 1.4298 10.8668 2.9225 10.9347 4.68079L10.9375 4.82391V7.5L11.2444 7.50014C11.9061 7.50014 12.4486 8.01207 12.4966 8.66148L12.5 8.75514V11.267C12.5 12.5968 11.4546 13.6825 10.1408 13.7471L10.0169 13.7501H4.98312C3.65334 13.7501 2.56762 12.7047 2.50304 11.3909L2.5 11.267V8.75514C2.5 8.09349 3.01239 7.55149 3.66194 7.50358L3.75563 7.50014L4.0625 7.5V4.68946ZM11.2444 8.12514L9.38875 8.125L9.36038 8.12643L3.75562 8.12514C3.43062 8.12514 3.16295 8.37092 3.1287 8.68651L3.125 8.75514V11.267C3.125 12.2565 3.8986 13.0655 4.87395 13.122L4.98312 13.1251H10.0169C11.0064 13.1251 11.8153 12.3515 11.8718 11.3762L11.875 11.267V8.75514C11.875 8.43048 11.629 8.16305 11.3131 8.12884L11.2444 8.12514ZM7.80747 9.63128C7.781 9.4855 7.65341 9.37496 7.5 9.37496C7.32741 9.37496 7.1875 9.51487 7.1875 9.68746V11.5625L7.19253 11.6186C7.21899 11.7644 7.34659 11.875 7.5 11.875C7.67259 11.875 7.8125 11.735 7.8125 11.5625V9.68746L7.80747 9.63128ZM7.77723 1.88819C6.15347 1.73346 4.76065 2.96551 4.69029 4.56263L4.6875 4.68946V7.5H5.3125V4.68927C5.3125 3.52133 6.22759 2.56731 7.37997 2.505L7.5 2.50177C8.66794 2.50177 9.62196 3.41686 9.68426 4.56924L9.6875 4.68927V7.5H10.3125V4.82391C10.3125 3.36368 9.27589 2.1035 7.90656 1.90378L7.77723 1.88819ZM5.94082 4.58652L5.9375 4.68927V7.5H9.0625V4.68927C9.0625 3.86075 8.41787 3.18299 7.60274 3.13009L7.5 3.12677C6.67149 3.12677 5.99372 3.77139 5.94082 4.58652Z" />
		</svg>
	);
};
export default Svg;
