import React, { useEffect } from 'react';
import classNames from 'classnames';
import C from 'constants/Constants';
import { CREDIT_AMOUNT_DEFAULT, CREDIT_AMOUNT_MAX_VALUE, CREDIT_AMOUNT_MIN_VALUE } from 'constants/Credit';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useCreditUtils } from 'utils/credit/credit';
import { useNumberUtils } from 'utils/number/number';

import { Text } from 'components/Atoms/Text';
import RangeSliderNumberInput from 'components/Atoms/Form/RangeSlider/RangeSliderNumberInput/RangeSliderNumberInput';
import RangeSliderInput from 'components/Atoms/Form/RangeSlider/RangeSliderInput/RangeSliderInput';
import SvgAdd from 'components/Atoms/SVG/Icons/SvgAdd';
import SvgClose from 'components/Atoms/SVG/Icons/SvgClose';

import { useDispatch, useSelector } from 'react-redux';
import { setIsShowRedemption, updateAmount } from 'store/App/actions';
import { setExternalCredits } from 'store/ExternalCredit/actions';

import styles from './CreditCalculator.module.scss';

const CreditCalculatorDesiredAmount = () => {
	const { getCurrentFlow } = useUtils();
	const { getSummedExternalCreditAmount } = useCreditUtils();
	const { formatMoneyValue } = useNumberUtils();

	const dispatch = useDispatch();
	const storeSidebarAnimation = useSelector((state => state.app.sidebarAnimation));
	const storeSidebarActive = useSelector((state => state.app.sidebarActive));
	const storeSidebarExtensionActive = useSelector((state => state.app.sidebarExtensionActive));
	const storeSidebarRuntimeAnimation = useSelector((state => state.app.sidebarRuntimeAnimation));
	const storeIsShowRedemption = useSelector((state => state.app.isShowRedemption));
	const storeAmount = useSelector((state => state.app.amount));
	const storeExternalCredits = useSelector((state => state.externalCredit.externalCredits));

	const currentFlow = getCurrentFlow();

	useEffect(() => {
		if (storeSidebarAnimation) {
			openRedemption();
		}
	}, [storeSidebarAnimation]);

	useEffect(() => {
		// Closes loan externalCredit slider (Ablösekredit) if the amount is 0 and user closes right sidebar
		const externalCredit = getSummedExternalCreditAmount();
		if (!storeSidebarActive && externalCredit === 0) {
			closeRedemption();
		}
	}, [storeSidebarActive]);

	const openRedemption = () => {
		dispatch(setIsShowRedemption(true));
	};

	const closeRedemption = () => {
		dispatch(setExternalCredits([]));
		dispatch(setIsShowRedemption(false));
	};

	const calculateTotalValue = () => {
		let wishAmount = storeAmount && !isNaN(storeAmount) ? parseInt(storeAmount) : 0;
		let externalCredit =
			storeExternalCredits && storeExternalCredits.length > 0 && !isNaN(storeExternalCredits[0].remainingBalance)
				? parseInt(storeExternalCredits[0].remainingBalance, 10)
				: 0;

		return formatMoneyValue(wishAmount + externalCredit, false);
	};

	const onChangeExternalCreditSlider = (value) => {
		value = value < CREDIT_AMOUNT_MAX_VALUE - getCreditValue() ? value : CREDIT_AMOUNT_MAX_VALUE - getCreditValue();
		dispatch(setExternalCredits([{ iban: C.DUMMY_IBAN, remainingBalance: value }]));
	};

	const onChangeCreditSlider = value => {
		value = value < CREDIT_AMOUNT_MAX_VALUE - getSummedExternalCreditAmount() ? value : CREDIT_AMOUNT_MAX_VALUE - getSummedExternalCreditAmount();
		if (storeAmount !== value) {
			dispatch(updateAmount(value));
		}
	};

	const getCreditValue = () => {
		return storeAmount && !isNaN(storeAmount) ? parseInt(storeAmount) : CREDIT_AMOUNT_DEFAULT;
	};

	return (
		<>
			<div
				className={classNames(styles.wishWrapper, {
					[styles.isPutBack]: storeSidebarExtensionActive || storeSidebarRuntimeAnimation
				})}
			>
				<div className={styles.rangeSlider}>
					<RangeSliderNumberInput
						label={m('wishAmount.label', 'fields')}
						value={getCreditValue()}
						suffix={'€'}
						onChangeHandler={onChangeCreditSlider}
						hasTooltipIcon={true}
						tooltipIconContent={
							<>{m('wishAmount.tooltip', currentFlow)}</>
						}
						tooltipIconPosition='top'
					/>
					<RangeSliderInput
						min={CREDIT_AMOUNT_MIN_VALUE}
						max={CREDIT_AMOUNT_MAX_VALUE}
						label={m('wishAmount.label', 'fields')}
						onChangeHandler={onChangeCreditSlider}
						value={getCreditValue()}
						step={500}
						tabIndex={100}
					/>
				</div>

				{storeIsShowRedemption ? (
					<div className={styles.totalWrapper}>
						<RangeSliderNumberInput
							label={m('redemptionCredit.label', 'fields')}
							value={getSummedExternalCreditAmount()}
							suffix={'€'}
							onChangeHandler={onChangeExternalCreditSlider}
							hasTooltipIcon={true}
							tooltipIconContent={
								<>{m('redemptionAmount.tooltip', currentFlow)}</>
							}
							tooltipIconPosition='top'
							hasCloseButton={storeIsShowRedemption}
						/>
						<RangeSliderInput
							min={0}
							max={CREDIT_AMOUNT_MAX_VALUE}
							label={m('redemptionAmount.label', 'fields')}
							onChangeHandler={onChangeExternalCreditSlider}
							value={getSummedExternalCreditAmount()}
							step={500}
							tabIndex={101}
						/>

						<div className={styles.totalBox}>
							<div className={styles.totalBoxText}>
								<Text size="l">{m('netLoanAmount.label', 'fields')}</Text>
							</div>
							<div className={styles.totalAmount}>
								<Text size="l"><strong>{calculateTotalValue()} €</strong></Text>
							</div>
						</div>

						<div className={styles.buttonClose} onClick={closeRedemption} aria-label="Kein Ablösekredit">
							<SvgClose />
						</div>
					</div>
				) : (
					<div
						className={styles.redemptionWrapper}
						onClick={() => {
							openRedemption();
						}}
						tabIndex={101}
					>
						<Text size="l">{m('redemptionCredit.add', 'fields')}</Text>
						<div className={styles.iconPlus}>
							<SvgAdd/>
						</div>
					</div>
				)}
			</div>
		</>
	);
};
export default CreditCalculatorDesiredAmount;
