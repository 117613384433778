import React from 'react';

const Svg = () => {
	const width = 24;
	const height = 24;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path d="M18.001 6.23L10.19 16.766l-4.329-4.46A.5.5 0 0 0 5.14 13l4.77 4.917a.498.498 0 0 0 .41.15.497.497 0 0 0 .372-.227l8.153-11.071a.5.5 0 0 0-.843-.54z" />
		</svg>
	);
};
export default Svg;
