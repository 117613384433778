import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useNumberUtils } from 'utils/number/number';

import { Text } from 'components/Atoms/Text';
import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';

import styles from './RangeSliderNumberInput.module.scss';

const RangeSliderNumberInput = props => {
	const { formatMoneyValue } = useNumberUtils();

	return (
		<>
			<div className={styles.row}>
				<span
					className={classNames([
						styles.rangeLabel,
						props.labelClassName ? props.labelClassName : null
					])}
				>
					<div className={styles.tooltipRow}>
						<Text size="l">{props.label}</Text>
						{props.hasTooltipIcon && props.tooltipIconContent ? (
							<TooltipIcon
								text={props.tooltipIconContent}
								type="question"
							/>
						) : null}
					</div>
				</span>

				<span
					className={classNames([
						styles.rangeInput,
						props.valueClassName ? props.valueClassName : null
					])}
				>
					{formatMoneyValue(props.value, false)}
				</span>

				{props.suffix && (
					<span
						className={classNames([
							styles.suffix,
							props.valueClassName ? props.valueClassName : null
						])}
					>
						{props.suffix}
					</span>
				)}
			</div>
		</>
	);
};

RangeSliderNumberInput.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	suffix: PropTypes.string,
	onChangeHandler: PropTypes.func,
	hasTooltipIcon: PropTypes.bool,
	tooltipIconContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tooltipIsClickable: PropTypes.bool,
	tooltipOpenForSeconds: PropTypes.number,
	labelClassName: PropTypes.string,
	valueClassName: PropTypes.string
};

export default RangeSliderNumberInput;
