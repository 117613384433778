import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M19.004 9.004a.998.998 0 0 1-.29.71l-6 5.99a1 1 0 0 1-1.42 0l-6-5.99a1.002 1.002 0 0 1 0-1.419 1.005 1.005 0 0 1 1.42 0l5.29 5.293 5.29-5.293a1 1 0 0 1 1.71.71z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
