import React from 'react';
import PropTypes from 'prop-types';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useDocumentsApi } from 'api/documents';

import Button from 'components/Atoms/Button/Button';
import SvgDownload from 'components/Atoms/SVG/Icons/SvgDownload';

import { useSelector } from 'react-redux';

const DownloadContracts = (props) => {
	const { getCurrentFlow, isCreditSubType } = useUtils();
	const { getContract } = useDocumentsApi();

	const currentFlow = getCurrentFlow();
	const storeProtectValue = useSelector((state => state.app.bcoResults.protectValue));

	return (
		<>
			<Button
				buttonStyle={props.hasFlatButtons ? 'linkFlat' : 'link'}
				onClick={async () => {
					await getContract();
				}}
				prependIcon={<SvgDownload />}
			>
				{m('download.contract.application', currentFlow)}
			</Button>
			{isCreditSubType() && !!storeProtectValue && (
				<Button
					buttonStyle={props.hasFlatButtons ? 'linkFlat' : 'link'}
					onClick={async () => {
						await getContract(null, null, true);
					}}
					prependIcon={<SvgDownload />}
				>
					{m('download.insuranceContract.application', 'global')}
				</Button>
			)}
		</>
	);
};

DownloadContracts.propTypes = {
	hasFlatButtons: PropTypes.bool
};

export default DownloadContracts;
