import React from 'react';
import PropTypes from 'prop-types';
import { useTrackingUtils } from 'utils/tracking/tracking';

import { Container, Row, Col } from 'components/Atoms/Grid';
import { Text, Link } from 'components/Atoms/Text';

import styles from './Footer.module.scss';

const Footer = (props) => {
	const { openCookieLayer } = useTrackingUtils();

	return (
		<>
			{props.extension}
			<footer className={styles.wrapper}>
				<div className={styles.inner}>
					<Container>
						<Row justifyContent="center">
							<Col xs={12} md={5} xxxl={5}>
								<div className={styles.title}>
									<Text color="light">
										&copy; {new Date().getFullYear()} Santander Consumer Bank AG
									</Text>
								</div>
							</Col>
							<Col xs={12} md={7} xxxl={5} textAlign="left" textAlignMd="right">
								<Text color="light">
									<span className={styles.link}>
										<Link
											href="https://www.santander.de/privatkunden/service-kontakt/impressum/?_ga=2.203959888.1328201376.1613030133-1589327932.1613030133"
											target="_blank"
											color="light"
											tabindex={105}
										>
											Impressum
										</Link>
									</span>

									<span className={styles.textSpacer}/>

									<span className={styles.link}>
										<Link
											href="https://www.santander.de/privatkunden/service-kontakt/datenschutz/?_ga=2.259360458.1328201376.1613030133-1589327932.1613030133"
											target="_blank"
											color="light"
											tabindex={106}
										>
											Datenschutz
										</Link>
									</span>

									<span className={styles.textSpacer}/>

									<span className={styles.link}>
										<Link
											onClick={openCookieLayer}
											color="light"
											tabindex={107}
										>
											Cookie Einstellungen
										</Link>
									</span>
								</Text>
							</Col>
						</Row>
					</Container>
				</div>
			</footer>
		</>
	);
};

Footer.propTypes = {
	extension: PropTypes.any
};

export default Footer;
