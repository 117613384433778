import React from 'react';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';

import { Container, Row, Col } from 'components/Atoms/Grid';
import ProgressBarItem from 'components/Organisms/ProgressBar/ProgressBarItem';
import ProgressBarConnector from 'components/Organisms/ProgressBar/ProgressBarConnector';

import { useSelector } from 'react-redux';

import styles from './ProgressBar.module.scss';

const ProgressBar = () => {
	const { getCurrentFlow } = useUtils();

	const currentFlow = getCurrentFlow();

	const storeCurrentStepCategory = useSelector((state => state.page.currentStepCategory));

	return (
		<>
			{storeCurrentStepCategory && (
				<Container>
					<Row justifyContent="center">
						<Col xs={12} md={10} lg={8} xl={7} xxl={6} xxxl={5}>
							<div className={styles.wrapper}>
								<div className={styles.progressBar}>
									<div className={styles.spacer} />
									<ProgressBarItem
										step={1}
										active={storeCurrentStepCategory === 1}
										disabled={false}
										stepName={m('progressBar.step1', currentFlow)}
									/>
									<div className={styles.connector}>
										<ProgressBarConnector disabled={storeCurrentStepCategory < 2} />
									</div>
									<ProgressBarItem
										step={2}
										active={storeCurrentStepCategory === 2}
										disabled={storeCurrentStepCategory < 2}
										stepName={m('progressBar.step2', currentFlow)}
									/>
									<div className={styles.connector}>
										<ProgressBarConnector disabled={storeCurrentStepCategory < 3} />
									</div>
									<ProgressBarItem
										step={3}
										active={storeCurrentStepCategory === 3}
										disabled={storeCurrentStepCategory< 3}
										stepName={m('progressBar.step3', currentFlow)}
									/>
									<div className={styles.spacer} />
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			)}
		</>
	);
};
export default ProgressBar;
