module.exports = {
	title: 'BestCredit',
	progressBar: {
		step1: 'Persönliche Angaben',
		step2: 'Absicherung',
		step3: 'Antrag senden',
		subline: {
			letsGo: "<strong>Los geht's:</strong> In nur {minutes} Minuten zu Ihrem BestCredit Angebot."
		}
	},
	name: {
		neededUtilsAndDocuments: {
			items: {
				iban: 'IBAN Ihres Girokontos'
			}
		},
		request: 'Kreditanfrage starten'
	},
	birthdate: {
		subtitle: 'Für die Beantragung des Kredits müssen Sie volljährig sein.'
	},
	email: {
		subtitle:
			'Ihre E-Mail wird benötigt, damit Sie den BestCredit digital beantragen können. Wir schicken Ihnen anschließend eine E-Mail, mit der Sie Ihren Antrag jederzeit fortführen können.'
	},
	bankAccount: {
		title: 'Bankverbindung Gehaltskonto',
		subtitle:
			'Von diesem Konto erfolgt auch die Abbuchung Ihrer monatlichen Rate.'
	},
	termsAndConditions: {
		acceptTermsAndConditions: {
			description:
				'Ich habe die Datenschutzhinweise, die Werbehinweise sowie die AGB und das Preisverzeichnis der Santander gelesen.',
			linkText: 'Bedingungen und Informationen zu Santander BestCredit',
			linkTextInsurance: 'Bedingungen und Informationen zu Santander BestCredit und Santander Protect'
		},
		submit: 'Jetzt Kredit anfragen'
	},
	thankYou: {
		title:
			'Vielen Dank für Ihre Anfrage. Wir prüfen Ihren Antrag und melden uns bei Ihnen.'
	},
	bcoResponseGreen: {
		title: 'Gute Neuigkeiten!',
		subtitle: 'Ihre Anfrage wurde vorläufig genehmigt.',
		infoText:
			'Wir brauchen dafür nur noch die erforderlichen Nachweise und Ihren unterschriebenen Vertrag.',
		nextSteps: {
			title: 'Ihre letzten Schritte bis zur Auszahlung',
			subtitle: 'Nur noch wenige Schritte bis zur Auszahlung.',
			step1: 'Dokumente hochladen',
			step2: 'Identifikation und Unterschrift',
			step3: 'Auszahlung'
		},
		submitDocumentUpload: 'Jetzt Dokumente hochladen',
		submitIdentification: 'Jetzt Identifizieren'
	},
	identification: {
		subtitle:
			'Mit dem Ident Verfahren unseres Partners WebID Solutions können Sie sicher, einfach und bequem Ihre Identität bestätigen und digital unterschreiben.',
		kontoIdent: {
			title: 'Konto-Ident',
			subtitle: 'Identifizieren mit einer Überweisung von 1 Cent',
			list:
				'<li>Ohne Wartezeit</li><li>Jederzeit, egal ob zu Hause oder unterwegs</li><li>Sicher und SSL verschlüsselt</li>',
			toggleButton: 'Wie funktioniert es',
			toggleText:
				'<p class="text text--primary text--size--m mb--5"><strong>Identifizieren</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Fotos von Ausweis und Gesicht machen</li><li>Log-in ins Online-Banking, Überweisung von 1 Cent bestätigen</li><li>TAN-Eingabe</li></ol></div><p class="text text--primary text--size--m mb--5"><strong>Digital unterschreiben:</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Vertrag sichten</li><li>Per TAN-Eingabe unterschreiben</li></ol></div>'
		},
		videoIdent: {
			title: 'Video-Ident',
			subtitle: 'Videogespräch mit einem WebID-Agenten',
			list:
				'<li>Kurze Wartezeit</li><li>Von 7:00 bis 22:00 Uhr von überall möglich</li><li>Sicher und SSL verschlüsselt</li>',
			toggleButton: 'Wie funktioniert es',
			toggleText:
				'<p class="text text--primary text--size--m mb--5"><strong>Identifizieren</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Ausweis bereit halten</li><li>Videocall mit WebID-Agenten durchführen</li><li>TAN-Eingabe</li></ol></div><p class="text text--primary text--size--m mb--5"><strong>Digital unterschreiben:</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Vertrag sichten</li><li>Per TAN-Eingabe unterschreiben</li></ol></div>'
		},
		digitalIdent: {
			title: 'Digitale Unterschrift',
			subtitle:
				'Personalausweis abfotografieren, Vertrag sichten und per TAN-Eingabe unterschreiben',
			list:
				'<li>Ohne Wartezeit</li><li>Jederzeit, egal ob zu Hause oder unterwegs</li><li>Sicher und SSL verschlüsselt</li>'
		}
	},
	download: {
		contract: {
			application: 'Kreditantrag herunterladen',
			pdftitle: 'Kreditantrag.pdf'
		}
	},
	addressVerification: {
		title: 'Bitte bestätigen Sie Ihre Adresse.',
		subtitle:
			'Um den Vorgang abzuschließen benötigen wir von Ihnen noch eines der folgenden Dokumente: Meldebescheinigung, oder eine Rechnung für Telefon, Strom oder Gas.',
		submitExtension1: 'Kein Dokument zur Hand?',
		submitExtension2: 'Später hochladen'
	},
	addressSelection: {
		title: 'Welche Adresse ist korrekt?',
		subtitle:
			'Die von Ihnen angegebene Adresse stimmt nicht mit der Adresse aus dem Video-Ident Verfahren überein. Welche Adresse ist aktueller?',
		items: {
			webIdAddress: {
				title: 'Adresse in Ihrem Ausweis',
				subtitle: 'Bereits bestätigt. Direkte Kreditbeantragung.'
			},
			originalAddress: {
				title: 'Angegebene Adresse',
				subtitle: 'Erfordert einen weiteren Nachweis.'
			}
		}
	},
	addressVerificationPostponed: {
		title:
			'Wir haben Ihnen eine E-Mail geschickt. So können Sie jederzeit zurückkehren.',
		subtitle:
			'Wir können Ihren Antrag erst dann bearbeiten, wenn Sie alle benötigten Dokumente vollständig hochgeladen haben.'
	},
	activateLoan: {
		title: 'Sie haben Ihren Kreditantrag erfolgreich digital unterschrieben.',
		subtitle:
			'Herzlichen Glückwunsch! Ihr Kreditvertrag ist nun verbindlich abgeschlossen. Das Geld ist bald auf Ihrem Konto',
		button: 'Jetzt Kredit auszahlen'
	},
	loanSuccess: {
		title: 'Geschafft! Ihr Geld ist unterwegs.',
		subtitle:
			'Der Kredit steht zur Auszahlung bereit und wird in Kürze Ihrem Konto gutgeschrieben!',
		accountNr: 'Kreditkontonummer',
		button: 'Schließen'
	},
	creditDone: {
		title:
			'Geschafft! Nach einer finalen, positiven Bewertung ist das Geld bald auf Ihrem Konto.',
		button: 'Schließen'
	},
	wishAmount: {
		tooltip:
			'Der hier angezeigte Kreditbetrag beinhaltet lediglich die von Ihnen gewünschte Kreditsumme und keine eventuellen Ablösekredite.'
	},
	redemptionAmount: {
		tooltip:
			'Bitte geben Sie hier den Gesamtbetrag aller bereits bestehenden Kredite an, die Sie ablösen möchten.'
	},
	finalStepsTwoBorrowers: {
		title: 'Letzte Schritte zu Ihrem Kredit',
		subtitle:
			'Wir brauchen dafür nur noch die erforderlichen Nachweise und Ihre beiden Identifikationen.',
		subtitleSa:
			'Wir brauchen dafür nur noch die erforderlichen Nachweise und Identifikationen des zweiten Darlehensnehmers.',
		finished: 'Alles erledigt',
		continue: 'Weiter',
		documents: 'Dokumente',
		identification: 'Identifikation',
		signature: 'Unterschrift',
		open: 'offen',
		complete: 'Komplett',
		documentState: {
			none: 'Noch keine',
			missing: 'Weitere Dokumente nötig',
			complete: 'Komplett'
		},
		contractState: {
			notSigned: 'Noch nicht unterschrieben',
			signed: 'Unterschrieben'
		}
	},
	exitPopup: {
		title: 'BestCredit',
		subhead: 'Gehen Sie noch nicht!',
		description: 'Verwirklichen Sie Ihre Träume mit dem Santander BestCredit.<br />Schließen Sie noch heute bequem digital ab und das Geld ist schon bald auf Ihrem Konto.',
		continueButton: 'Fortfahren'
	}
};
