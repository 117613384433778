import React from 'react';
import { m } from 'utils/messages/messages';
import { Title, Text } from 'components/Atoms/Text';
import C from 'constants/Constants';
import { useLocation } from 'react-router-dom';

const ErrorTechnical = () => {
	const location = useLocation();
	const isApp = location.pathname.endsWith(C.ROUTES.ERROR_TECHNICAL_APP);

	const phoneNumber = isApp ? '02161 - 90 60 708' : '02161 - 90 60 942';
	const phoneNumberLink = isApp ? '+4921619060708' : '+4921619060942';

	return (
		<>
			<Title mb>{m('pages.error.technical.title', 'global')}</Title>
			<Text>{m('pages.error.technical.subtitle', 'global', { phoneNumber: phoneNumber, phoneNumberLink: phoneNumberLink }, true)}</Text>
		</>
	);
};
export default ErrorTechnical;
