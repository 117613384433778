import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { m } from 'utils/messages/messages';
import { useTimeUtils } from 'utils/time/time';
import { usePhoneUtils } from 'utils/phone/phone';
import { useFlowUtils } from 'utils/flow/flow';
import { useKeycodeUtils } from 'utils/keycodes/keycodes';

import { Link, Text, Title } from 'components/Atoms/Text';
import SvgSpeechBubbleHelp from 'components/Atoms/SVG/Icons/SvgSpeechBubbleHelp';
import SvgPhone from 'components/Atoms/SVG/Icons/SvgPhone';
import SvgCalendar from 'components/Atoms/SVG/Icons/SvgCalendar';
import SvgClose from 'components/Atoms/SVG/Icons/SvgClose';

import styles from './AppointmentButton.module.scss';

const AppointmentButton = (props) => {
	const { isInOfficeHours } = useTimeUtils();
	const { getPhoneNumber } = usePhoneUtils();
	const { getReturn } = useKeycodeUtils();
	const { isCreditAuxMoneySanc, isCreditErgoSanc, isGiroErgoUid } = useFlowUtils();

	const [phoneNumber] = useState(getPhoneNumber());

	const [isOpen, setIsOpen] = useState(false);
	let timeout = null;

	useEffect(() => {
		const appointmentButtonAutoShow = sessionStorage.getItem('appointmentButtonAutoShow');
		if (appointmentButtonAutoShow !== 'false') {
			setupTimer();
		}
	}, []);

	const setupTimer = () => {
		window.addEventListener('mousedown', resetTimer);
		window.addEventListener('keypress', resetTimer);
		window.addEventListener('touchmove', resetTimer);
		startTimer();
	};

	const startTimer = () => {
		timeout = window.setTimeout(() => {
			setIsOpen(true);
			window.removeEventListener('mousedown', resetTimer);
			window.removeEventListener('keypress', resetTimer);
			window.removeEventListener('touchmove', resetTimer);
			sessionStorage.setItem('appointmentButtonAutoShow', 'false');
		}, 15000);
	};

	const resetTimer = () => {
		window.clearTimeout(timeout);
		startTimer();
	};

	const onButtonClick = () => {
		setIsOpen(!isOpen);
	};

	const handleKeyDownButton = (event) => {
		if (getReturn(event)) {
			onButtonClick();
		}
	};

	const onClose = () => {
		setIsOpen(false);
	};

	const handleKeyDownClose = (event) => {
		if (getReturn(event)) {
			onClose(event);
		}
	};

	const show = useMemo(() => {
		return !isCreditAuxMoneySanc && !isCreditErgoSanc && !isGiroErgoUid && (isInOfficeHours || !props.hideAppointmentLink);
	}, [isInOfficeHours, props.hideAppointmentLink, isCreditAuxMoneySanc, isCreditErgoSanc, isGiroErgoUid]);

	return (
		<>
			{show && (
				<div
					className={classNames([
						styles.appointmentBox,
						isOpen ? styles.appointmentBoxActive : null
					])}
				>
					<div
						className={classNames([styles.appointmentButton])}
						onClick={onButtonClick}
						onKeyDown={handleKeyDownButton}
						tabIndex={200}
					>
						<div className={classNames([styles.appointmentButtonTitle])}>
							{m('appointmentButton.buttonText', 'global')}
						</div>
						<div className={classNames([styles.appointmentButtonIcon])}>
							<SvgSpeechBubbleHelp />
						</div>
					</div>
					<div
						className={classNames([
							styles.appointmentCloseBackground,
							isOpen ? styles.appointmentCloseBackgroundActive : null
						])}
						onClick={onClose}
					/>
					<div
						className={classNames([
							styles.appointmentBoxContent,
							isOpen ? styles.appointmentBoxContentActive : null
						])}
					>
						<div
							className={classNames([styles.appointmentButtonClose])}
							onClick={onClose}
							onKeyDown={handleKeyDownClose}
							tabIndex={203}
							aria-label="Kontakt-Dialog schließen"
						>
							<SvgClose />
						</div>
						<Title size="s">{m('appointmentButton.title', 'global')}</Title>
						{isInOfficeHours ? (
							<>
								<div className={classNames([styles.appointmentBoxRow])}>
									<div className={styles.appointmentBoxIcon}>
										<SvgPhone />
									</div>
									<div className={styles.appointmentBoxText}>
										<Text>
											{m('appointmentButton.phone.title', 'global')}<br />
											<Link href={'tel:' + phoneNumber?.link} tabindex={201}>
												{phoneNumber?.text}
											</Link>
										</Text>
									</div>
								</div>
							</>
						) : (
							<></>
						)}
						{!props.hideAppointmentLink && (
							<div className={styles.appointmentBoxRow}>
								<div className={styles.appointmentBoxIcon}>
									<SvgCalendar/>
								</div>
								<div className={styles.appointmentBoxText}>
									<Text>
										{m('appointmentButton.appointment.title', 'global')}<br />
										<Link
											href="https://service.santander.de/webapps/apps/terminanfrage?skip=thema&appointment-topic=Direktkredit"
											target="_blank"
											tabindex={202}
										>
											{m('appointmentButton.appointment.linkText', 'global')}
										</Link>
									</Text>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

AppointmentButton.propTypes = {
	hideAppointmentLink: PropTypes.bool
};

export default AppointmentButton;
