import React from 'react';

const Svg = () => {
	const width = 24;
	const height = 24;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path d="M14.954 13.007a.5.5 0 0 1 .09.992l-.09.008H7.547C8.39 16.37 10.642 18 13.182 18a6.012 6.012 0 0 0 4.912-2.554.5.5 0 1 1 .818.575A7.011 7.011 0 0 1 13.182 19a7.02 7.02 0 0 1-6.62-4.743l-.08-.25H5.5a.5.5 0 0 1-.09-.992l.09-.008h9.454zM13.182 5a7.028 7.028 0 0 1 5.67 2.894.5.5 0 1 1-.809.587A6.027 6.027 0 0 0 13.182 6c-2.45 0-4.645 1.525-5.55 3.763l-.085.226h7.407a.5.5 0 0 1 .09.992l-.09.008H5.5a.5.5 0 0 1-.09-.992l.09-.008h.98A7.032 7.032 0 0 1 13.181 5z" />
		</svg>
	);
};
export default Svg;
