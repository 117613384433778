import React from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';

import Form from 'components/Molecules/Form/Form';
import { Text, Title } from 'components/Atoms/Text';

const UploadDocumentsLater = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid } = useTrackingUtils();

	const currentFlow = getCurrentFlow();

	const onSubmit = () => {
		continueValid();
		goToPage(currentFlow + C.ROUTES.UPLOAD_DOCUMENTS);
	};

	return (
		<>
			<Title mb>{m('pages.uploadDocumentsLater.title', 'global')}</Title>
			<Text size="l" mb>{m('pages.uploadDocumentsLater.subtitle', 'global')}</Text>
			<Form
				onSubmit={onSubmit}
				submitText={m('finishNow.label', 'fields')}
				submitTabindex={1}
			/>
		</>
	);
};
export default UploadDocumentsLater;
