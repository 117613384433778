import { PROTECT_MAX_AGE } from 'constants/Credit';
import { useDateUtils } from 'utils/date/date';

import { useSelector } from 'react-redux';

export const useInsuranceUtils = () => {
	const { getAge } = useDateUtils();

	const storeBirthdate = useSelector((state => state.birthdate.birthdate));
	const storeOccupation = useSelector((state => state.occupation.occupation));

	const showInsurancePage = () => {
		if (storeBirthdate) {
			const age = getAge(storeBirthdate);
			if (age && storeOccupation !== 'STUDENT' && storeOccupation !== 'FREELANCER' && storeOccupation !== 'UNEMPLOYED') {
				return age < PROTECT_MAX_AGE;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	return {
		showInsurancePage
	};
};
