import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M18.35 5.65a.48.48 0 0 0-.7 0L12 11.29 6.36 5.65a.5.5 0 0 0-.71 0 .48.48 0 0 0 0 .7L11.29 12l-5.64 5.64a.5.5 0 0 0 0 .71.48.48 0 0 0 .7 0L12 12.71l5.64 5.64a.51.51 0 0 0 .36.15.5.5 0 0 0 .35-.85L12.71 12l5.64-5.64a.5.5 0 0 0 0-.71z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
