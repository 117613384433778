import React from 'react';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';

import { Text, Title } from 'components/Atoms/Text';
import Divider from 'components/Atoms/Divider/Divider';
import PhoneLink from 'components/Atoms/Partials/PhoneLink/PhoneLink';

import { useSelector } from 'react-redux';

const ThankYou = () => {
	const { getCurrentFlow } = useUtils();

	const currentFlow = getCurrentFlow();

	const storeFirstName = useSelector((state => state.name.firstName));
	const storeLastName = useSelector((state => state.name.lastName));
	const storeApplicationId = useSelector((state => state.app.applicationId));

	return (
		<>
			<Title mb>
				{m('thankYou.title', currentFlow, {
					firstName: storeFirstName,
					lastName: storeLastName
				})}
			</Title>

			<Text size="l" mb>
				{m('thankYou.subtitle', currentFlow, null, true)}{' '}
				<PhoneLink />
			</Text>

			{storeApplicationId && (
				<Text mb>
					<strong>
						{m('pages.thankYou2.yourNumber', 'global')}:
					</strong>{' '}
					<span data-testid={'application-id'}>
						{storeApplicationId}
					</span>
				</Text>
			)}

			<Divider />

			<Text mb>
				{m('thankYou.contact', currentFlow)}
			</Text>
		</>
	);
};
export default ThankYou;
