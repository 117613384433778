import React from 'react';

const Svg = () => {
	const width = 24;
	const height = 24;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				d="M19.0041 9.00434C19.0056 9.26976 18.9013 9.52487 18.7141 9.71331L12.7141 15.7046C12.5263 15.8937 12.2707 16 12.0041 16C11.7375 16 11.4819 15.8937 11.2941 15.7046L5.29409 9.71331C4.90197 9.32176 4.90197 8.68692 5.29409 8.29537C5.68621 7.90382 6.32197 7.90382 6.71409 8.29537L12.0041 13.5877L17.2941 8.29537C17.4819 8.10633 17.7375 8 18.0041 8C18.2707 8 18.5263 8.10633 18.7141 8.29537C18.9013 8.48382 19.0056 8.73892 19.0041 9.00434Z"/>
		</svg>
	);
};
export default Svg;
