import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './LoadingSpinner.module.scss';

const LoadingSpinner = (props) => {
	return (
		<div
			className={classNames([
				styles.wrapper,
				props.icon ? styles.l : styles.m
			])}
		>
			<div className={styles.loader} />
			{props.icon && <div className={styles.icon}>{props.icon}</div>}
		</div>
	);
};

LoadingSpinner.propTypes = {
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default LoadingSpinner;
