import React from 'react';
import { m } from 'utils/messages/messages';

import { Link, Text, Title } from 'components/Atoms/Text';
import SvgShareLink from 'components/Atoms/SVG/Icons/SvgShareLink';

const AllowAdvertisingModalContent = () => {
	return (
		<>
			<Title size="s" mb>{m('allowAdvertising.popup.headline', 'fields')}</Title>
			<Text mb>
				{m('allowAdvertising.popup.content1', 'fields')}{' '}
				<Link href="https://www.santander.de/werbehinweise" target="_blank">
					{m('allowAdvertising.popup.linkText', 'fields')}
					<SvgShareLink />
				</Link>
				{m('allowAdvertising.popup.content2', 'fields', null, true)}
			</Text>
			<Text>
				{m('allowAdvertising.popup.content3', 'fields', null, true)}{' '}
				<Link href="https://www.santander.de/werbehinweise" target="_blank">
					{m('allowAdvertising.popup.linkText', 'fields')}
					<SvgShareLink />
				</Link>
				{m('allowAdvertising.popup.content4', 'fields')}
			</Text>
		</>
	);
};
export default AllowAdvertisingModalContent;
