import React from 'react';
import PropTypes from 'prop-types';
import { m } from 'utils/messages/messages';

import { Container, Row, Col } from 'components/Atoms/Grid';
import { Title } from 'components/Atoms/Text';
import Header from 'components/Organisms/Header/Header';
import Footer from 'components/Organisms/Footer/Footer';

import ServiceHotline from 'components/Atoms/Partials/ServiceHotline/ServiceHotline';

import styles from './Error.module.scss';

const Page404 = () => {
	return (
		<>
			<Header />
			<Container>
				<Row justifyContent="center">
					<Col xs={12} md={10} lg={8} xl={7} xxl={6} xxxl={5}>
						<main>
							<div className={styles.headerSpacer} />

							<Title mb>{m('error.404', 'fields')}</Title>

							<Row justifyContent='center'>
								<Col xs={12}>
									<ServiceHotline />
								</Col>
							</Row>
						</main>
					</Col>
				</Row>
			</Container>
			<Footer />
		</>
	);
};

Page404.propTypes = {
	location: PropTypes.object
};

export default Page404;
