import axios from 'axios';
import C from 'constants/Constants';
import { useApiUtils } from 'utils/api/api';
import { useTrackingUtils } from 'utils/tracking/tracking';

import { useDispatch } from 'react-redux';
import { updateCcbData, updateCcb90Data, updateWuestenrotData } from 'store/App/actions';

export const useQuotationApi = () => {
	const { getApiBaseURL, startApiCall, stopApiCall, checkUnauthorized } = useApiUtils();
	const { error } = useTrackingUtils();

	const dispatch = useDispatch();

	const getLoanRates = async (
		condition,
		onSuccess = () => {},
		onError = () => {}
	) => {
		startApiCall();

		await axios
			.get(getApiBaseURL() + '/loan-rates/' + condition, {
				headers: {
					'Authorization': process.env.REACT_APP_DEFAULT_AUTH_TOKEN
				}
			})
			.then((response) => {
				onLoanRatesSuccess(
					response.data,
					condition,
					onSuccess
				);
			})
			.catch((err) => {
				const status = err?.response?.status ?? 500;
				error(status, 'GET /loan-rates call failed');
				const unauthorized = checkUnauthorized(status);
				if (unauthorized) return null;

				stopApiCall();
				onError(status);
			});
	};

	const onLoanRatesSuccess = (data, condition, onSuccess = () => {}) => {
		if (condition === C.CONDITIONS.CCB90) {
			dispatch(updateCcb90Data(data));
		} else if (condition === C.CONDITIONS.WUESTENROT) {
			dispatch(updateWuestenrotData(data));
		} else {
			dispatch(updateCcbData(data));
		}
		stopApiCall();
		onSuccess(data);
	};

	return {
		getLoanRates
	};
};
