import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M9.004 19.004a.998.998 0 0 1-1.004-1 1 1 0 0 1 .295-.71l5.293-5.29-5.293-5.29a1.005 1.005 0 0 1 0-1.42 1.002 1.002 0 0 1 1.418 0l5.992 6a1 1 0 0 1 0 1.42l-5.992 6a.998.998 0 0 1-.709.29z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
