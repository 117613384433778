/** Actions Type */
const SET_APPLICATION_ID = 'SET_APPLICATION_ID';
const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
const UPDATE_INITIAL_PAGE_LOAD = 'UPDATE_INITIAL_PAGE_LOAD';
const UPDATE_BUTTON_LOADING = 'UPDATE_BUTTON_LOADING';
const UPDATE_APP_LOADING = 'UPDATE_APP_LOADING';
const IS_SHOW_OVERLAY = 'IS_SHOW_OVERLAY';
const UPDATE_MONTHLY_RATE = 'UPDATE_MONTHLY_RATE';
const UPDATE_AMOUNT = 'UPDATE_AMOUNT';
const UPDATE_RUNTIME = 'UPDATE_RUNTIME';
const SET_RUNTIME_LIMIT = 'SET_RUNTIME_LIMIT';
const UPDATE_RUNTIME_INDEX = 'UPDATE_RUNTIME_INDEX';
const SET_RUNTIME_NOTIFICATION = 'SET_RUNTIME_NOTIFICATION';
const UPDATE_CONDITION = 'UPDATE_CONDITION';
const UPDATE_SANC = 'UPDATE_SANC';
const UPDATE_UID = 'UPDATE_UID';
const SET_REQUEST_FROM_CROSS_SELLING = 'SET_REQUEST_FROM_CROSS_SELLING';
const UPDATE_INCOME = 'UPDATE_INCOME';
const SET_SIDEBAR_ACTIVE = 'SET_SIDEBAR_ACTIVE';
const SET_SIDEBAR_ANIMATION = 'SET_SIDEBAR_ANIMATION';
const UPDATE_SIDEBAR_RUNTIME_ANIMATION = 'UPDATE_SIDEBAR_RUNTIME_ANIMATION';
const UPDATE_SIDEBAR_EXTENSION_ACTIVE = 'UPDATE_SIDEBAR_EXTENSION_ACTIVE';
const UPDATE_SIDEBAR_RATE_CHECK = 'UPDATE_SIDEBAR_RATE_CHECK';
const SET_IS_SHOW_REDEMPTION = 'SET_IS_SHOW_REDEMPTION';
const SET_VC_JWT_TOKEN = 'SET_VC_JWT_TOKEN';
const SET_CROSS_SELL_VC_JWT_TOKEN = 'SET_CROSS_SELL_VC_JWT_TOKEN';
const UPDATE_CCB_DATA = 'UPDATE_CCB_DATA';
const UPDATE_CCB_90_DATA = 'UPDATE_CCB_90_DATA';
const UPDATE_WUESTENROT_DATA = 'UPDATE_WUESTENROT_DATA';
const UPDATE_FIRST_RATE_DATE = 'UPDATE_FIRST_RATE_DATE';
const UPDATE_WEB_ID_ADDRESS_NEEDED = 'UPDATE_WEB_ID_ADDRESS_NEEDED';
const UPDATE_CAPS_UPLOAD_DONE = 'UPDATE_CAPS_UPLOAD_DONE';
const UPDATE_WEB_ID_STARTED = 'UPDATE_WEB_ID_STARTED';
const UPDATE_WEB_ID_FINISHED = 'UPDATE_WEB_ID_FINISHED';
const UPDATE_IS_REQUEST_FROM_CAPS = 'UPDATE_IS_REQUEST_FROM_CAPS';
export const UPDATE_REDIRECT_VC_URL = 'UPDATE_REDIRECT_VC_URL';
const UPDATE_RELOGIN = 'UPDATE_RELOGIN';
const UPDATE_SKIP_LEGI = 'UPDATE_SKIP_LEGI';
const SET_HIDE_TITLE = 'SET_HIDE_TITLE';
const UPDATE_BCO_RESULTS = 'UPDATE_BCO_RESULTS';

/** Actions Creators */
export const setApplicationId = payload => ({
	type: SET_APPLICATION_ID,
	payload
});

export const setAccessToken = payload => ({
	type: SET_ACCESS_TOKEN,
	payload
});

export const setAuthToken = payload => ({
	type: SET_AUTH_TOKEN,
	payload
});


export const updateInitialPageLoad = payload => ({
	type: UPDATE_INITIAL_PAGE_LOAD,
	payload
});

export const updateButtonLoading = payload => ({
	type: UPDATE_BUTTON_LOADING,
	payload
});

export const updateAppLoading = payload => ({
	type: UPDATE_APP_LOADING,
	payload
});

export const updateOverlay = payload => ({
	type: IS_SHOW_OVERLAY,
	payload
});

export const updateMonthlyRate = payload => ({
	type: UPDATE_MONTHLY_RATE,
	payload
});

export const updateAmount = payload => ({
	type: UPDATE_AMOUNT,
	payload
});

export const updateRuntime = payload => ({
	type: UPDATE_RUNTIME,
	payload
});

export const setRuntimeLimit = payload => ({
	type: SET_RUNTIME_LIMIT,
	payload
});

export const updateRuntimeIndex = payload => ({
	type: UPDATE_RUNTIME_INDEX,
	payload
});

export const setRuntimeNotification = payload => ({
	type: SET_RUNTIME_NOTIFICATION,
	payload
});

export const updateIncome = payload => ({
	type: UPDATE_INCOME,
	payload
});

export const updateCondition = payload => ({
	type: UPDATE_CONDITION,
	payload
});

export const updateSanc = payload => ({
	type: UPDATE_SANC,
	payload
});

export const updateUid = payload => ({
	type: UPDATE_UID,
	payload
});

export const setRequestFromCrossSelling = payload => ({
	type: SET_REQUEST_FROM_CROSS_SELLING,
	payload
});


export const setSidebarActive = payload => ({
	type: SET_SIDEBAR_ACTIVE,
	payload
});

export const setSidebarAnimation = payload => ({
	type: SET_SIDEBAR_ANIMATION,
	payload
});

export const updateSidebarRuntimeAnimation = payload => ({
	type: UPDATE_SIDEBAR_RUNTIME_ANIMATION,
	payload
});

export const updateSidebarRateChecked = payload => ({
	type: UPDATE_SIDEBAR_RATE_CHECK,
	payload
});

export const updateSidebarExtensionActive = payload => ({
	type: UPDATE_SIDEBAR_EXTENSION_ACTIVE,
	payload
});

export const setIsShowRedemption = payload => ({
	type: SET_IS_SHOW_REDEMPTION,
	payload
});

export const setVcJwtToken = payload => ({
	type: SET_VC_JWT_TOKEN,
	payload
});

export const setCrossSellVcJwtToken = payload => ({
	type: SET_CROSS_SELL_VC_JWT_TOKEN,
	payload
});

export const updateCcbData = payload => ({
	type: UPDATE_CCB_DATA,
	payload
});

export const updateCcb90Data = payload => ({
	type: UPDATE_CCB_90_DATA,
	payload
});

export const updateWuestenrotData = payload => ({
	type: UPDATE_WUESTENROT_DATA,
	payload
});

export const updateFirstRateDate = payload => ({
	type: UPDATE_FIRST_RATE_DATE,
	payload
});

export const updateWebIdAddressNeeded = payload => ({
	type: UPDATE_WEB_ID_ADDRESS_NEEDED,
	payload
});

export const updateCapsUploadDone = payload => ({
	type: UPDATE_CAPS_UPLOAD_DONE,
	payload
});

export const updateWebIdStarted = payload => ({
	type: UPDATE_WEB_ID_STARTED,
	payload
});

export const updateWebIdFinished = payload => ({
	type: UPDATE_WEB_ID_FINISHED,
	payload
});

export const updateIsRequestFromCaps = payload => ({
	type: UPDATE_IS_REQUEST_FROM_CAPS,
	payload
});

export const updateRedirectVcUrl = payload => ({
	type: UPDATE_REDIRECT_VC_URL,
	payload
});

export const updateRelogin = payload => ({
	type: UPDATE_RELOGIN,
	payload
});

export const updateSkipLegi = payload => ({
	type: UPDATE_SKIP_LEGI,
	payload
});

export const setHideTitle = payload => ({
	type: SET_HIDE_TITLE,
	payload
});

export const updateBcoResults = payload => ({
	type: UPDATE_BCO_RESULTS,
	payload
});
