export const C = {
	TEST: 'test',
	OTHER: 'other',
	API_CONNECTION: 'api_connection',
	API_MISSING_DATA: 'api_missing_data',
	API_OTHER: 'api_other',
	API_MOBILE_NOT_VERIFIED: 'mobile_not_verified',
	EMPTY_FIELDS: 'empty_fields',
	INVALID_FIELDS: 'invalid_fields',
	AMOUNT_TOO_HIGH: 'amount_too_high',
	BIRTHDATE_INVALID: 'birthdate_invalid',
	DAY: 'day',
	MONTH: 'month',
	YEAR: 'year',
	YEAR_LESS_THAN_1900: 'year_less_than_1900',
	UNDER_18: 'under_18',
	EMAIL: 'email',
	REGISTERED_BUT_NOT_VERIFIED: 'registered_but_not_verified',
	MOBILE_NUMBER_TOO_SHORT:'mobile_number_too_short',
	MOBILE_NUMBER_SAME: 'mobile_number_same',
	MOBILE_NUMBER_NO_GERMAN_PREFIX: 'mobile_number_no_german_prefix',
	HAS_SAME_MOBILE_NUMBER: 'has_same_mobile_number',
	OCCUPATION_MONTH: 'opccupation_month',
	OCCUPATION_YEAR: 'occupation_year',
	OCCUPATION_START_IN_THE_FUTURE: 'occupation_start_in_the_future',
	OCCUPATION_START_BEFORE_BIRTHDATE: 'occupation_start_before_birthdate',
	OCCUPATION_LIMITED_MONTH: 'opccupation_limited_month',
	OCCUPATION_LIMITED_YEAR: 'occupation_limited_year',
	OCCUPATION_LIMITED_START_IN_THE_PAST: 'occupation_limited_start_in_the_past',
	OCCUPATION_LIMITED_START_BEFORE_OCCUPATION_START: 'occupation_limited_start_before_occupation_start',
	IBAN: 'iban',
	ALL_UPPER_LOWER_CASE: 'all_upper_lower_case'
};
