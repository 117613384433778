/** Actions Type */
export const SET_PROTECT = 'SET_PROTECT';
export const SET_PROTECT_RUNTIME = 'SET_PROTECT_RUNTIME';
export const SET_PROTECT_COVERAGE = 'SET_PROTECT_COVERAGE';

/** Actions Creators */
export const setProtect = payload => ({
	type: SET_PROTECT,
	payload
});

export const setProtectRuntime = payload => ({
	type: SET_PROTECT_RUNTIME,
	payload
});

export const setProtectCoverage = payload => ({
	type: SET_PROTECT_COVERAGE,
	payload
});
