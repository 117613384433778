import _ from 'lodash';
import { UPDATE_REDIRECT_VC_URL } from './actions';

export const defaultState = {
	applicationId: null,
	vcJwtToken: null,
	authToken: null,
	crossSellVcJwtToken: null,
	accessToken: null,
	initialPageLoad: true,
	buttonLoading: false,
	appLoading: 'none',
	isShowOverlay: false,
	monthlyRate: null,
	amount: 10000,
	runtime: 84,
	runtimeLimit: 96,
	runtimeIndex: 8,
	runtimeNotification: false,
	income: null,
	sidebarExtensionActive: false,
	sidebarActive: false,
	sidebarAnimation: false,
	sidebarRuntimeAnimation: false,
	isSidebarRateChecked: false,
	isShowRedemption: false,
	condition: 'CCB',
	sanc: null,
	uid: null,
	requestFromCrossSelling: false,
	ccbData: {},
	ccb90Data: {},
	wuestenrotData: {},
	firstRateDate: null,
	addressSelectionNeeded: false,
	documentUploadNeeded: false,
	capsUploadDone: false,
	webIdStarted: false,
	webIdFinished: false,
	isRequestFromCaps: false,
	redirectVcUrl: null,
	relogin: false,
	skipLegiLP1: false,
	skipLegiLP2: false,
	hideTitle: false,
	bcoResults: {
		creditAmountNet: null,
		creditAmountTotal: null,
		applicationId: null,
		status: null,
		interest: null,
		rateMonthlyAmount: null,
		runtimeInMonths: null,
		redemptionAmount: null,
		effectiveAnnualInterestRate: null,
		fixedBorrowingRate: null,
		skipContract: false,
		aaReady: false,
		downOrUpsellActivateFlag: false,
		downOrUpSellOption: null,
		downOrUpSellValue: 5500,
		maxMonthlyRate: null,
		maxUpsellAmount: 10000,
		optionAmount: {
			creditAmountNet: null,
			creditAmountTotal: null,
			interestAmount: null,
			rateMonthlyAmount: null,
			runtimeInMonths: null,
			redemptionAmount: null,
			effectiveAnnualInterestRate: null,
			fixedBorrowingRate: null
		},
		protect: null,
		protectValue: null,
		protectRuntime: null,
		protectCoverage: null
	}
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_APPLICATION_ID':
			return _.merge({}, state, {
				applicationId: action.payload
			});
		case 'SET_ACCESS_TOKEN':
			return _.merge({}, state, {
				accessToken: action.payload.registrationToken
			});
		case 'SET_AUTH_TOKEN':
			return _.merge({}, state, {
				authToken: action.payload
			});
		case 'UPDATE_INITIAL_PAGE_LOAD':
			return _.merge({}, state, {
				initialPageLoad: action.payload
			});
		case 'UPDATE_BUTTON_LOADING':
			return _.merge({}, state, {
				buttonLoading: action.payload
			});
		case 'UPDATE_APP_LOADING':
			return _.merge({}, state, {
				appLoading: action.payload
			});
		case 'IS_SHOW_OVERLAY':
			return _.merge({}, state, {
				isShowOverlay: action.payload
			});
		case 'UPDATE_MONTHLY_RATE':
			return _.merge({}, state, {
				monthlyRate: action.payload
			});
		case 'UPDATE_AMOUNT':
			return _.merge({}, state, {
				amount: action.payload
			});
		case 'UPDATE_INCOME':
			return _.merge({}, state, {
				income: action.payload
			});
		case 'UPDATE_RUNTIME':
			return _.merge({}, state, {
				runtime: action.payload
			});
		case 'SET_RUNTIME_LIMIT':
			return { ...state, runtimeLimit: action.payload };
		case 'UPDATE_RUNTIME_INDEX':
			return _.merge({}, state, {
				runtimeIndex: action.payload
			});
		case 'SET_RUNTIME_NOTIFICATION':
			return { ...state, runtimeNotification: action.payload };
		case 'UPDATE_CONDITION':
			return _.merge({}, state, {
				condition: action.payload
			});
		case 'UPDATE_SANC':
			return _.merge({}, state, {
				sanc: action.payload
			});
		case 'UPDATE_UID':
			return _.merge({}, state, {
				uid: action.payload
			});
		case 'SET_REQUEST_FROM_CROSS_SELLING':
			return { ...state, requestFromCrossSelling: action.payload };
		case 'SET_SIDEBAR_ACTIVE':
			return { ...state, sidebarActive: action.payload };
		case 'SET_SIDEBAR_ANIMATION':
			return { ...state, sidebarAnimation: action.payload };
		case 'UPDATE_SIDEBAR_RUNTIME_ANIMATION':
			return _.merge({}, state, {
				sidebarRuntimeAnimation: action.payload
			});
		case 'UPDATE_SIDEBAR_RATE_CHECK':
			return _.merge({}, state, {
				isSidebarRateChecked: action.payload
			});
		case 'UPDATE_SIDEBAR_EXTENSION_ACTIVE':
			return _.merge({}, state, {
				sidebarExtensionActive: action.payload
			});
		case 'SET_IS_SHOW_REDEMPTION':
			return { ...state, isShowRedemption: action.payload };
		case 'SET_VC_JWT_TOKEN':
			return { ...state, vcJwtToken: action.payload };
		case 'SET_CROSS_SELL_VC_JWT_TOKEN':
			return { ...state, crossSellVcJwtToken: action.payload };
		case 'UPDATE_CCB_DATA':
			return _.merge({}, state, {
				ccbData: action.payload
			});
		case 'UPDATE_CCB_90_DATA':
			return _.merge({}, state, {
				ccb90Data: action.payload
			});
		case 'UPDATE_WUESTENROT_DATA':
			return _.merge({}, state, {
				wuestenrotData: action.payload
			});
		case 'UPDATE_FIRST_RATE_DATE':
			return _.merge({}, state, {
				firstRateDate: action.payload
			});
		case 'UPDATE_WEB_ID_ADDRESS_NEEDED':
			return _.merge({}, state, {
				addressSelectionNeeded: action.payload.addressSelectionNeeded,
				documentUploadNeeded: action.payload.documentUploadNeeded
			});
		case 'UPDATE_CAPS_UPLOAD_DONE':
			return _.merge({}, state, {
				capsUploadDone: action.payload
			});
		case 'UPDATE_WEB_ID_STARTED':
			return _.merge({}, state, {
				webIdStarted: action.payload
			});
		case 'UPDATE_WEB_ID_FINISHED':
			return _.merge({}, state, {
				webIdFinished: action.payload
			});
		case 'UPDATE_IS_REQUEST_FROM_CAPS':
			return _.merge({}, state, {
				isRequestFromCaps: action.payload
			});
		case UPDATE_REDIRECT_VC_URL:
			return _.merge({}, state, {
				redirectVcUrl: action.payload
			});
		case 'UPDATE_RELOGIN':
			return _.merge({}, state, {
				relogin: action.payload
			});
		case 'UPDATE_SKIP_LEGI':
			return _.merge({}, state, {
				skipLegiLP1: !!action.payload.skipLegiLP1,
				skipLegiLP2: !!action.payload.skipLegiLP2
			});
		case 'SET_HIDE_TITLE':
			return { ...state, hideTitle: action.payload };
		case 'UPDATE_BCO_RESULTS':
			return _.merge({}, state, {
				bcoResults: {
					creditAmountNet:
						action.payload.kreditbetragNetto ||
						action.payload.kreditbetragNetto === 0
							? parseFloat(action.payload.kreditbetragNetto)
							: null,
					creditAmountTotal:
						action.payload.kreditbetragGesamt ||
						action.payload.kreditbetragGesamt === 0
							? parseFloat(action.payload.kreditbetragGesamt)
							: null,
					applicationId: action.payload.scbAntragId,
					status: action.payload.status,
					interest:
						action.payload.zinsenGesamt || action.payload.zinsenGesamt === 0
							? parseFloat(action.payload.zinsenGesamt)
							: null,
					rateMonthlyAmount:
						action.payload.ratenbetragMonat ||
						action.payload.ratenbetragMonat === 0
							? parseFloat(action.payload.ratenbetragMonat)
							: null,
					runtimeInMonths:
						action.payload.laufzeitInMonaten ||
						action.payload.laufzeitInMonaten === 0
							? parseInt(action.payload.laufzeitInMonaten)
							: null,
					redemptionAmount:
						action.payload.abloesebetrag || action.payload.abloesebetrag === 0
							? parseFloat(action.payload.abloesebetrag)
							: null,
					protect: action.payload.protect ?? null,
					protectValue: action.payload.protectValue ? parseFloat(action.payload.protectValue) : null,
					protectRuntime: action.payload.protectRuntime ?? null,
					protectCoverage: action.payload.protectCoverage ?? null,
					effectiveAnnualInterestRate:
						action.payload.effektivzinsPaProz ||
						action.payload.effektivzinsPaProz === 0
							? parseFloat(action.payload.effektivzinsPaProz)
							: null,
					fixedBorrowingRate:
						action.payload.nominalzinsPaProz ||
						action.payload.nominalzinsPaProz === 0
							? parseFloat(action.payload.nominalzinsPaProz)
							: null,
					downOrUpSellOption: action.payload.downOrUpSellOption,
					downOrUpSellValue:
						action.payload.downOrUpSellValue ||
						action.payload.downOrUpSellValue === 0
							? parseFloat(action.payload.downOrUpSellValue)
							: null,
					downOrUpsellActivateFlag: action.payload.downOrUpsellActivateFlag,
					skipContract: !!action.payload.skipContract,
					aaReady: !!action.payload.aaReady
				}
			});
		default:
			return state;
	}
};

export default reducers;
