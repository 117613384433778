import C from 'constants/Constants';

import Login from 'components/Pages/Registration/Login/Login';
import Name from 'components/Pages/Registration/Name/Name';
import Birthdate from 'components/Pages/Registration/Birthdate/Birthdate';
import Email from 'components/Pages/Registration/Email/Email';
import EmailSent from 'components/Pages/Registration/EmailSent/EmailSent';
import EmailConfirmed from 'components/Pages/Registration/EmailConfirmed/EmailConfirmed';
import EmailNotConfirmed from 'components/Pages/Registration/EmailNotConfirmed/EmailNotConfirmed';
import MobileNumber from 'components/Pages/Registration/MobileNumber/MobileNumber';
import Otp from 'components/Pages/Registration/Otp/Otp';
import Nationality from 'components/Pages/ApplicationData/Nationality/Nationality';
import Address from 'components/Pages/ApplicationData/Address/Address';
import PreviousAddress from 'components/Pages/ApplicationData/PreviousAddress/PreviousAddress';
import MaritalStatus from 'components/Pages/ApplicationData/MaritalStatus/MaritalStatus';
import Occupation from 'components/Pages/ApplicationData/Occupation/Occupation';
import BankAccount from 'components/Pages/ApplicationData/BankAccount/BankAccount';
import FinancialStatusDAC from 'components/Pages/DAC/FinancialStatusDAC/FinancialStatusDAC';
import DigitalAccountCheck from 'components/Pages/DAC/DigitalAccountCheck/DigitalAccountCheck';
import DigitalAccountCheckSuccess from 'components/Pages/DAC/DigitalAccountCheckSuccess/DigitalAccountCheckSuccess';
import DigitalAccountCheckFailure from 'components/Pages/DAC/DigitalAccountCheckFailure/DigitalAccountCheckFailure';
import NewIbanDAC from 'components/Pages/DAC/NewIbanDAC/NewIbanDAC';
import ExternalCredit from 'components/Pages/ApplicationData/ExternalCredit/ExternalCredit';
import Insurance from 'components/Pages/Insurance/Insurance';
import TermsAndConditions from 'components/Pages/Application/TermsAndConditions/TermsAndConditions';
import ApplicationApprovedCredit from 'components/Pages/Application/Approved/Credit/ApplicationApprovedCredit';
import ThankYouCredit from 'components/Pages/Application/ThankYou/ThankYouCredit';
import ThankYouCredit2 from 'components/Pages/Application/ThankYou/ThankYouCredit2';
import UploadDocuments from 'components/Pages/Documents/UploadDocuments/UploadDocuments';
import UploadDocumentsLater from 'components/Pages/Documents/UploadDocumentsLater/UploadDocumentsLater';
import Identification from 'components/Pages/Legitimation/Identification/Identification';
import PostIdent from 'components/Pages/Legitimation/PostIdent/PostIdent';
import DigitalPostident from 'components/Pages/Legitimation/DigitalPostident/DigitalPostident';
import DigitalPostidentConfirmation from 'components/Pages/Legitimation/DigitalPostidentConfirmation/DigitalPostidentConfirmation';
import WebIdDone from 'components/Pages/Legitimation/WebIdDone/WebIdDone';
import AddressVerification from 'components/Pages/Legitimation/AddressVerification/AddressVerification';
import AddressSelection from 'components/Pages/Legitimation/AddressSelection/AddressSelection';
import AddressVerificationPostponed from 'components/Pages/Legitimation/AddressVerificationPostponed/AddressVerificationPostponed';
import ActivateCredit from 'components/Pages/Done/Activate/Credit/ActivateCredit';
import CreditSuccess from 'components/Pages/Done/Success/Credit/CreditSuccess';
import CreditDone from 'components/Pages/Done/Success/Credit/CreditDone';
import FinalStepsTwoBorrowers from 'components/Pages/Flows/Credit/SecondApplicant/FinalStepsTwoBorrowers/FinalStepsTwoBorrowers';
import Upsell from 'components/Pages/Flows/Credit/Upsell/Upsell';
import ErrorTechnical from 'components/Pages/Error/Technical/ErrorTechnical';

export const routes = (flow, gtmType) => {
	let routes = [];
	routes = routes.concat([
		{
			subpage: C.ROUTES.LOGIN,
			component: Login,
			gtmStep: 'login',
			gtmStatus: 'login_pending'
		},
		{
			subpage: C.ROUTES.INDEX,
			component: Name,
			stepCategory: 1,
			gtmStep: 'userdata_name',
			gtmStatus: 'registration_pending',
			showDurationHint: true,
			hasExitWindowPopup: true,
			hasRightSidebar: true
		},
		{
			subpage: C.ROUTES.BIRTHDATE,
			component: Birthdate,
			stepCategory: 1,
			gtmStep: 'userdata_birthdate',
			gtmStatus: 'registration_pending',
			hasRightSidebar: true
		},
		{
			subpage: C.ROUTES.EMAIL,
			component: Email,
			stepCategory: 1,
			gtmStep: 'userdata_email',
			gtmStatus: 'registration_pending',
			hasRightSidebar: true
		},
		{
			subpage: C.ROUTES.EMAIL_SENT,
			component: EmailSent,
			stepCategory: 1,
			gtmStep: 'userdata_email_confirmation',
			gtmStatus: 'registration_pending',
			gtmSubStatus: 'email_confirmation_pending'
		},
		{
			subpage: C.ROUTES.EMAIL_CONFIRMED + '/:accessToken',
			component: EmailConfirmed,
			stepCategory: 1,
			gtmStep: 'userdata_email_confirmation',
			gtmStatus: 'registration_pending',
			gtmSubStatus: 'email_confirmed'
		},
		{
			subpage: C.ROUTES.EMAIL_NOT_CONFIRMED,
			component: EmailNotConfirmed,
			stepCategory: 1,
			gtmStep: 'userdata_email_confirmation',
			gtmStatus: 'registration_pending',
			gtmSubStatus: 'email_not_confirmed'
		},
		{
			subpage: C.ROUTES.MOBILE_NUMBER,
			component: MobileNumber,
			stepCategory: 1,
			gtmStep: 'userdata_mobile_number',
			gtmStatus: 'registration_pending',
			needsRegistrationToken: true
		},
		{
			subpage: C.ROUTES.OTP,
			component: Otp,
			stepCategory: 1,
			gtmStep: 'userdata_mobile_number_confirmation',
			gtmStatus: 'registration_pending',
			gtmSubStatus: 'mobile_number_confirmation_pending',
			needsRegistrationToken: true
		},
		{
			subpage: C.ROUTES.OTP + '/:isRegistration',
			component: Otp,
			stepCategory: 1,
			gtmStep: 'userdata_mobile_number_confirmation',
			gtmStatus: 'registration_pending',
			gtmSubStatus: 'mobile_number_confirmation_pending',
			needsRegistrationToken: true
		},
		{
			subpage: C.ROUTES.NATIONALITY,
			component: Nationality,
			stepCategory: 1,
			gtmStep: 'personaldata_nationality',
			gtmStatus: 'application_pending',
			hasRightSidebar: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.ADDRESS,
			component: Address,
			stepCategory: 1,
			gtmStep: 'personaldata_address',
			gtmStatus: 'application_pending',
			hasRightSidebar: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.PREVIOUS_ADDRESS,
			component: PreviousAddress,
			stepCategory: 1,
			gtmStep: 'personaldata_previous_address',
			gtmStatus: 'application_pending',
			hasRightSidebar: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.MARITAL_STATUS,
			component: MaritalStatus,
			stepCategory: 1,
			gtmStep: 'personaldata_marital_status',
			gtmStatus: 'application_pending',
			hasRightSidebar: true,
			showAppointmentButton: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.OCCUPATION,
			component: Occupation,
			stepCategory: 1,
			gtmStep: 'personaldata_occupation',
			gtmStatus: 'application_pending',
			hasRightSidebar: true,
			showAppointmentButton: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.BANK_ACCOUNT,
			component: BankAccount,
			stepCategory: 1,
			gtmStep: 'personaldata_current_account',
			gtmStatus: 'application_pending',
			hasRightSidebar: true,
			showAppointmentButton: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.FINANCIAL_STATUS_DAC,
			component: FinancialStatusDAC,
			stepCategory: 1,
			gtmStep: 'personaldata_financial_status',
			gtmStatus: 'application_pending',
			hasRightSidebar: true,
			showAppointmentButton: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.DIGITAL_ACCOUNT_CHECK,
			component: DigitalAccountCheck,
			stepCategory: 1,
			gtmStep: 'personaldata_digital_account_check',
			gtmStatus: 'application_pending',
			hasRightSidebar: true,
			creditCalculatorBlocked: true,
			showAppointmentButton: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.DIGITAL_ACCOUNT_CHECK_SUCCESS,
			component: DigitalAccountCheckSuccess,
			stepCategory: 1,
			gtmStep: 'personaldata_digital_account_check_success',
			gtmStatus: 'application_pending',
			hasRightSidebar: true,
			showAppointmentButton: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.DIGITAL_ACCOUNT_CHECK_FAILURE,
			component: DigitalAccountCheckFailure,
			stepCategory: 1,
			gtmStep: 'personaldata_digital_account_check_failure',
			gtmStatus: 'application_pending',
			hasRightSidebar: true,
			showAppointmentButton: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.DIGITAL_ACCOUNT_CHECK_IBAN,
			component: NewIbanDAC,
			stepCategory: 1,
			gtmStep: 'personaldata_digital_account_check_iban',
			gtmStatus: 'application_pending',
			hasRightSidebar: true,
			showAppointmentButton: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.EXTERNAL_CREDIT,
			component: ExternalCredit,
			stepCategory: 1,
			gtmStep: 'personaldata_external_credit',
			gtmStatus: 'application_pending',
			hasRightSidebar: true,
			showAppointmentButton: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.INSURANCE,
			component: Insurance,
			stepCategory: 2,
			gtmStep: 'personaldata_ppi',
			gtmStatus: 'application_pending',
			hasRightSidebar: true,
			creditCalculatorBlocked: true,
			showAppointmentButton: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.TERMS_AND_CONDITIONS,
			component: TermsAndConditions,
			stepCategory: 3,
			gtmStep: 'personaldata_terms_conditions',
			gtmStatus: 'application_pending',
			hasRightSidebar: true,
			creditCalculatorBlocked: true,
			showAppointmentButton: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.BCO_RESPONSE_GREEN,
			component: ApplicationApprovedCredit,
			gtmStep: 'bco_response',
			gtmStatus: 'bco_response',
			gtmSubStatus: 'bco_green',
			showAppointmentButton: true,
			hideAppointmentLink: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.UPSELL,
			component: Upsell,
			gtmStep: 'upsell_downsell',
			gtmStatus: 'bco_response',
			showAppointmentButton: true,
			hideAppointmentLink: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.FINANCIAL_STATUS_DAC + '/:isReminder',
			component: FinancialStatusDAC,
			gtmStep: 'personaldata_financial_status_reminder',
			gtmStatus: 'application_pending',
			showAppointmentButton: true,
			hideAppointmentLink: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.DIGITAL_ACCOUNT_CHECK + '/:isReminder',
			component: DigitalAccountCheck,
			gtmStep: 'personaldata_digital_account_check_reminder',
			gtmStatus: 'application_pending',
			showAppointmentButton: true,
			hideAppointmentLink: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.DIGITAL_ACCOUNT_CHECK_SUCCESS + '/:isReminder',
			component: DigitalAccountCheckSuccess,
			gtmStep: 'personaldata_digital_account_check_success_reminder',
			gtmStatus: 'application_pending',
			showAppointmentButton: true,
			hideAppointmentLink: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.DIGITAL_ACCOUNT_CHECK_FAILURE + '/:isReminder',
			component: DigitalAccountCheckFailure,
			gtmStep: 'personaldata_digital_account_check_failure_reminder',
			gtmStatus: 'application_pending',
			showAppointmentButton: true,
			hideAppointmentLink: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.UPLOAD_DOCUMENTS,
			component: UploadDocuments,
			gtmStep: 'upload_documents',
			gtmStatus: 'documents_pending',
			showAppointmentButton: true,
			hideAppointmentLink: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.UPLOAD_DOCUMENTS_LATER,
			component: UploadDocumentsLater,
			gtmStep: 'upload_documents_later',
			gtmStatus: 'documents_later',
			showAppointmentButton: true,
			hideAppointmentLink: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.IDENTIFICATION,
			component: Identification,
			gtmStep: 'identification_selection',
			gtmStatus: 'identification_pending',
			showAppointmentButton: true,
			hideAppointmentLink: true,
			needsVcJwtToken: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.POSTIDENT,
			component: PostIdent,
			gtmStep: 'postident',
			gtmStatus: 'identification_pending',
			showAppointmentButton: true,
			hideAppointmentLink: true,
			needsVcJwtToken: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.DIGITAL_POSTIDENT_CONFIRMATION,
			component: DigitalPostidentConfirmation,
			gtmStep: 'digital_postident_confirmation',
			gtmStatus: 'identification_pending',
			showAppointmentButton: true,
			hideAppointmentLink: true,
			needsVcJwtToken: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.DIGITAL_POSTIDENT,
			component: DigitalPostident,
			gtmStep: 'digital_postident',
			gtmStatus: 'identification_pending',
			showAppointmentButton: true,
			hideAppointmentLink: true,
			needsVcJwtToken: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.WEB_ID_DONE,
			component: WebIdDone,
			hideFlowTitle: true
		},
		{
			subpage: C.ROUTES.ADDRESS_SELECTION,
			component: AddressSelection,
			gtmStep: 'address_selection',
			gtmStatus: 'identification_pending',
			gtmIdentificationDocument: 'idcard',
			needsVcJwtToken: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.ADDRESS_VERIFICATION,
			component: AddressVerification,
			gtmStep: 'address_verification',
			gtmStatus: 'identification_pending',
			gtmSubStatus: 'upload_pending',
			gtmIdentificationDocument: 'idcard',
			needsVcJwtToken: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.ADDRESS_VERIFICATION_POSTPONED,
			component: AddressVerificationPostponed,
			gtmStep: 'address_verification_postponed',
			gtmStatus: 'identification_pending',
			gtmSubStatus: 'address_verification_pending',
			gtmIdentificationDocument: 'idcard',
			needsVcJwtToken: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.ACTIVATE_LOAN,
			component: ActivateCredit,
			gtmStep: 'activate_loan',
			gtmStatus: 'activate_loan',
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.LOAN_SUCCESS,
			component: CreditSuccess,
			gtmStep: 'loan_success',
			gtmStatus: 'loan_success',
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.LOAN_DONE,
			component: CreditDone,
			gtmStep: 'application_end',
			gtmStatus: 'identification_successfull',
			gtmIdentificationDocument: 'idcard',
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.UPLOAD_DOCUMENTS + '/:isReminder',
			component: UploadDocuments,
			gtmStep: 'upload_documents_reminder',
			gtmStatus: 'documents_pending',
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.FINAL_STEPS_TWO_BORROWERS,
			component: FinalStepsTwoBorrowers,
			gtmStep: 'final_steps_two_borrowers',
			gtmStatus: 'application_pending',
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.THANKYOU,
			component: ThankYouCredit,
			gtmStep: 'thank_you',
			gtmStatus: 'application_end'
		},
		{
			subpage: C.ROUTES.THANKYOU2,
			component: ThankYouCredit2,
			gtmStep: 'thank_you_skip_contract',
			gtmStatus: 'bco_response',
			gtmSubStatus: 'bco_green'
		},
		{
			subpage: C.ROUTES.ERROR_TECHNICAL,
			component: ErrorTechnical,
			gtmStep: 'error_technical',
			gtmStatus: 'application_end'
		},
		{
			subpage: C.ROUTES.ERROR_TECHNICAL_APP,
			component: ErrorTechnical,
			gtmStep: 'error_technical',
			gtmStatus: 'application_end'
		}
	]);

	for (const route of routes) {
		route.flow = flow;
		route.gtmType = gtmType;
	}

	return routes;
};
export default routes;
