import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filter } from 'lodash';
import classNames from 'classnames';
import { usePageUtils } from 'utils/page/page';

import { rootRoutes } from 'routes/rootRoutes';

import styles from '../Dev.module.scss';

const SubpageSelector = (props) => {
	const { goToPage, replaceSubpageVariable } = usePageUtils();

	const [subpageItems, setSubpageItems] = useState([]);

	useEffect(() => {
		setSubpageItems(getSubpageItemsOfFlow());
	}, [props.reducers.page.currentFlow]);

	const getSubpageItemsOfFlow = () => {
		const flowRoutes = filter(rootRoutes, (route) => {
			return route.flow === props.reducers.page.currentFlow;
		});
		const subpageItems = [];
		for (const flowRoute of flowRoutes) {
			subpageItems.push({
				label: flowRoute.subpage,
				value: flowRoute.subpage
			});
		}
		return subpageItems;
	};

	const onChange = (event) => {
		const index = event.nativeEvent.target.selectedIndex;
		const selectedItem = subpageItems[index - 1];
		const flow = props.reducers.page.currentFlow;
		const subpage = replaceSubpageVariable(selectedItem.value);
		goToPage(flow + subpage);
	};

	return (
		<div className={styles.selector}>
			<select
				className={classNames(
					styles.selectorInput,
					!props.reducers.page.currentSubpage ? styles.selectorInputDisabled : null
				)}
				onChange={onChange}
				value={props.reducers.page.currentSubpage ? props.reducers.page.currentSubpage : ''}
			>
				<option disabled hidden value="">
					Keine Unterseiten
				</option>
				{subpageItems.map((item, index) => (
					<option key={'subpage-selector-item-' + index} value={item.value}>
						{item.label}
					</option>
				))}
			</select>
		</div>
	);
};

SubpageSelector.propTypes = {
	reducers: PropTypes.object
};

const mapStateToProps = state => {
	return {
		reducers: state
	};
};

export default connect(mapStateToProps, null)(SubpageSelector);

