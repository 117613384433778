import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import C from 'constants/Constants';
import { CREDIT_AMOUNT_MAX_VALUE, CREDIT_RUNTIME_STEPS } from 'constants/Credit';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useNumberUtils } from 'utils/number/number';
import { useCreditUtils } from 'utils/credit/credit';
import { useApplicationApi } from 'api/application';

import { Text, Title } from 'components/Atoms/Text';
import Box from 'components/Molecules/Box/Box';
import { Col, Row } from 'components/Atoms/Grid';
import Button from 'components/Atoms/Button/Button';
import Form from 'components/Molecules/Form/Form';
import Divider from 'components/Atoms/Divider/Divider';
import CreditDetails from 'components/Organisms/CreditDetails/CreditDetails';
import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';
import SvgEdit from 'components/Atoms/SVG/Icons/SvgEdit';
import SvgClose from 'components/Atoms/SVG/Icons/SvgClose';
import RangeSliderWithNumberInput from 'components/Atoms/Form/RangeSlider/RangeSliderWithNumberInput/RangeSliderWithNumberInput';

import { useDispatch, useSelector } from 'react-redux';
import { setUpsellAlreadyDone } from 'store/Upsell/actions';
import { updateAmount, updateRuntime } from 'store/App/actions';

import styles from './Upsell.module.scss';
import SvgReload from 'components/Atoms/SVG/Icons/SvgReload';

const Upsell = () => {
	const { getCurrentFlow } = useUtils();
	const { formatMoneyValue } = useNumberUtils();
	const { goToPage } = usePageUtils();
	const { continueValid } = useTrackingUtils();
	const { getMonthlyRate, getProtectMonthlyRateByProtect } = useCreditUtils();
	const { postApplication } = useApplicationApi();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeAmount = useSelector((state => state.app.amount));
	const storeRuntime = useSelector((state => state.app.runtime));

	const storeUpsellCreditAmountNet = useSelector((state => state.upsell.creditAmountNet));
	const storeUpsellRateMonthlyAmount = useSelector((state => state.upsell.rateMonthlyAmount));

	const storeUpsellRuntimeInMonths = useSelector((state => state.upsell.runtimeInMonths));
	const storeUpsellFixedBorrowingRate = useSelector((state => state.upsell.fixedBorrowingRate));
	const storeUpsellEffectiveAnnualInterestRate = useSelector((state => state.upsell.effectiveAnnualInterestRate));
	const storeUpsellInterestAmount = useSelector((state => state.upsell.interestAmount));

	const storeMaxUpsellAmount = useSelector((state => state.upsell.maxUpsellAmount));
	const storeMaxMonthlyRate = useSelector((state => state.upsell.maxMonthlyRate));

	const storeFirstRateDate = useSelector((state => state.app.firstRateDate));
	const storeProtect = useSelector((state => state.app.bcoResults?.protect));
	const storeProtectCoverage = useSelector((state => state.app.bcoResults?.protectCoverage));

	const [isEditViewOpen, setIsEditViewOpen] = useState(false);
	const [initialAmount] = useState(storeAmount);
	const [initialRuntime] = useState(storeRuntime);
	const [chosenAmount, setChosenAmount] = useState(storeUpsellCreditAmountNet);
	const [chosenRuntime, setChosenRuntime] = useState(storeUpsellRuntimeInMonths);

	const [submitted, setSubmitted] = useState(false);

	useEffect(() => {
		dispatch(setUpsellAlreadyDone(true));
	}, []);

	useEffect(() => {
		if (submitted) {
			if (initialAmount !== storeAmount || initialRuntime !== storeRuntime) {
				postApplication(
					() => {
						goToNextPage();
					},
					() => {},
					() => {},
					true
				);
			} else {
				dispatch(setUpsellAlreadyDone(true));
				goToPage(currentFlow + C.ROUTES.BCO_RESPONSE_GREEN);
			}
		}
	}, [submitted]);

	useEffect(() => {
		if (!isEditViewOpen) {
			onEditReset();
		}
	}, [isEditViewOpen]);

	const hasProtect = storeProtect && storeProtectCoverage;

	const calcMonthlyRate = useMemo(() => {
		if (
			storeUpsellRateMonthlyAmount &&
			chosenAmount === storeUpsellCreditAmountNet &&
			chosenRuntime === storeUpsellRuntimeInMonths
		) {
			return storeUpsellRateMonthlyAmount + (hasProtect ? getProtectMonthlyRateByProtect(storeProtect, storeProtectCoverage) : 0);
		}
		return getMonthlyRate(chosenAmount, chosenRuntime) + (hasProtect ? getProtectMonthlyRateByProtect(storeProtect, storeProtectCoverage) : 0);
	}, [chosenAmount, chosenRuntime]);

	const isMonthlyRateHigherThanMaxMonthlyRate = useMemo(() => {
		return storeMaxMonthlyRate && calcMonthlyRate > storeMaxMonthlyRate;
	}, [calcMonthlyRate, storeMaxMonthlyRate]);

	const totalAmount = useMemo(() => {
		return (storeUpsellCreditAmountNet ?? 0) +
			(storeUpsellInterestAmount ?? 0);
	}, [
		storeUpsellCreditAmountNet,
		storeUpsellInterestAmount
	]);

	const firstRateDate = useMemo(() => {
		return storeFirstRateDate
			? moment(storeFirstRateDate, 'DD.MM.YYYY').format('DD.MM.YYYY')
			: null;
	}, [storeFirstRateDate]);

	const onChangeRuntime = (value) => {
		const newRuntimeInMonths = CREDIT_RUNTIME_STEPS[value];
		if (newRuntimeInMonths <= CREDIT_RUNTIME_STEPS[CREDIT_RUNTIME_STEPS.length - 1] && chosenRuntime !== newRuntimeInMonths) {
			setChosenRuntime(newRuntimeInMonths);
		}
	};

	const goToNextPage = () => {
		continueValid();
		dispatch(setUpsellAlreadyDone(true));
		goToPage(currentFlow + C.ROUTES.BCO_RESPONSE_GREEN);
	};

	const onEditReset = () => {
		setChosenAmount(storeUpsellCreditAmountNet);
		setChosenRuntime(storeUpsellRuntimeInMonths);
	};

	const onSubmit = () => {
		if (!isMonthlyRateHigherThanMaxMonthlyRate) {
			if (chosenAmount === storeAmount && chosenRuntime === storeRuntime) {
				goToNextPage();
			} else {
				dispatch(updateAmount(chosenAmount));
				dispatch(updateRuntime(chosenRuntime));
				setSubmitted(true);
			}
		}
	};

	return (
		<>
			<Title mb>{m('pages.upsell.title', 'global')}</Title>
			<Text size="l" mb>{m('pages.upsell.subtitle', 'global', { amount: formatMoneyValue(storeUpsellCreditAmountNet ?? 0) })}</Text>

			{!isEditViewOpen ? (
				<>
					<Text size="l" mb><strong>{m('pages.upsell.offer.title', 'global')}</strong></Text>
					<Box shadow borderRadius>
						<Row>
							<Col xs={6}>
								<Text size="xl"><strong>{m('general.creditAmount', 'global')}:</strong></Text>
							</Col>
							<Col xs={6} textAlign="right">
								<Text size="xl"><strong><span className="text--highlight">{formatMoneyValue(storeUpsellCreditAmountNet ?? 0)} €</span></strong></Text>
							</Col>
						</Row>
						<Row>
							<Col xs={6}>
								<div className={styles.tooltipRow}>
									<Text size="l">
										{m(hasProtect ? 'rate.protectLong' : 'monthlyRate.label', 'fields')}:&nbsp;
									</Text>
									<TooltipIcon
										text={
											<>
												{m(
													'pages.upsell.offer.monthlyRateInfo',
													'global'
												)}
											</>
										}
									/>
								</div>
							</Col>
							<Col xs={6} textAlign="right">
								<Text size="l"><strong>{formatMoneyValue((storeUpsellRateMonthlyAmount ?? 0) + (hasProtect ? getProtectMonthlyRateByProtect(storeProtect, storeProtectCoverage) : 0), true)} €</strong></Text>
							</Col>
						</Row>
						<Divider noLine noMarginBottom/>
						<CreditDetails
							monthlyRate={storeUpsellRateMonthlyAmount ?? 0}
							runtimeInMonths={storeUpsellRuntimeInMonths}
							effectiveAnnualInterestRate={storeUpsellEffectiveAnnualInterestRate}
							fixedBorrowingRate={storeUpsellFixedBorrowingRate}
							interest={storeUpsellInterestAmount}
							firstRateDate={firstRateDate}
							protect={storeProtect}
							protectCoverage={storeProtectCoverage}
							totalAmount={totalAmount}
						/>
					</Box>

					<div className="text-align-right mt--15">
						<Button
							onClick={() => setIsEditViewOpen(true)}
							buttonStyle="link"
							appendIcon={<SvgEdit />}
						>
							Bearbeiten
						</Button>
					</div>
				</>
			) : (
				<>
					<Row>
						<Col>
							<Text size="l" mb><strong>{m('pages.upsell.edit.title', 'global')}</strong></Text>
						</Col>
						<Col textAlign="right">
							<div
								className={styles.closeIcon}
								onClick={() => setIsEditViewOpen(false)}
							>
								<SvgClose />
							</div>
						</Col>
					</Row>
					<Box shadow borderRadius>
						<Text size="l" mb>{m('pages.upsell.edit.subtitle', 'global')}</Text>
						<RangeSliderWithNumberInput
							min={1000}
							max={storeMaxUpsellAmount ?? CREDIT_AMOUNT_MAX_VALUE}
							value={chosenAmount}
							label={m('general.creditAmount', 'global')}
							suffix="€"
							onChangeHandler={setChosenAmount}
							step={500}
							labelClassName={styles.upsellAmount}
							valueClassName={styles.upsellAmountValue}
						/>

						<RangeSliderWithNumberInput
							min={0}
							max={CREDIT_RUNTIME_STEPS.length - 1}
							value={CREDIT_RUNTIME_STEPS.indexOf(chosenRuntime)}
							customLabelValue={chosenRuntime}
							label={m('wishRuntime.label', 'fields')}
							suffix={m('month.plural', 'fields')}
							hasTooltipIcon
							tooltipIconContent={m('wishRuntime.tooltip', 'fields')}
							onChangeHandler={onChangeRuntime}
							step={1}
							valueClassName={styles.upsellRuntimeValue}
						/>

						<Row>
							<Col xs={6}>
								<div className={styles.tooltipRow}>
									<Text size="l"><strong>{m(hasProtect ? 'rate.protectLong' : 'monthlyRate.label', 'fields')}:</strong></Text>
									<TooltipIcon text={m('pages.upsellDownsell.monthlyRateInfo', 'global')} />
								</div>
							</Col>
							<Col xs={6} textAlign="right">
								<Text size="l"><strong><span className={isMonthlyRateHigherThanMaxMonthlyRate ? 'text--error' : 'text--primary'}>{formatMoneyValue(calcMonthlyRate, true)} €</span></strong></Text>
								{isMonthlyRateHigherThanMaxMonthlyRate && (
									<Text size="s">
										<span className="text--error">
											{m(
												'pages.upsellDownsell.upsell.monthlyRateTooHigh',
												'global'
											)}
										</span>
									</Text>
								)}
							</Col>
						</Row>
					</Box>
				</>
			)}

			<Form
				onSubmit={onSubmit}
				clearErrors={() => {}}
				submitTabindex={7}
				submitText={isEditViewOpen ? m('save.label', 'fields') : null}
				onPrevButton={() => {
					if (isEditViewOpen) {
						onEditReset();
					} else {
						goToPage(currentFlow + C.ROUTES.BCO_RESPONSE_GREEN);
					}
				}}
				prevButtonText={isEditViewOpen ? m('reset.label', 'fields') : null}
				prevButtonIcon={isEditViewOpen ? <SvgReload /> : null}
				submitDisabled={isEditViewOpen && isMonthlyRateHigherThanMaxMonthlyRate}
			/>
		</>
	);
};
export default Upsell;
