import _ from 'lodash';
import * as actions from './actions';

const defaultState = {
	sessionKey: '',
	transaction: '',
	status: null,
	enabled: true
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case actions.INIT_DIGITAL_ACCOUNT_CHECK:
			return _.merge({}, state, {
				sessionKey: action.payload.sessionKey,
				transaction: action.payload.transaction
			});
		case actions.DAC_PROCESS_RESULT_SUCCESS:
			return _.merge({}, state, {
				status: action.payload.status
			});
		case actions.DIGITAL_ACCOUNT_CHECK_FAILED:
			return _.merge({}, state, {
				enabled: false
			});
		case actions.DIGITAL_ACCOUNT_CHECK_CLEAR:
			return {
				...state,
				sessionKey: '',
				transaction: '',
				status: null,
				enabled: null
			};
		default:
			return state;
	}
};

export default reducers;
