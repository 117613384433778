import React from 'react';
import PropTypes from 'prop-types';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useFlowUtils } from 'utils/flow/flow';

import { Container, Row, Col } from 'components/Atoms/Grid';
import { Text } from 'components/Atoms/Text';
import SvgUrgent from 'components/Atoms/SVG/Icons/SvgUrgent';

import styles from './DurationHint.module.scss';

const DurationHint = (props) => {
	const { getCurrentFlow } = useUtils();
	const { isGmkHyundai } = useFlowUtils();

	const currentFlow = getCurrentFlow();

	return (
		<Container>
			<Row justifyContent="center">
				<Col xs={12} md={10} lg={8} xl={7} xxl={6} xxxl={5}>
					<div className={styles.wrapper}>
						<SvgUrgent />
						<Text size="l">
							{m(
								(isGmkHyundai ? 'hyundai.' : '') + 'progressBar.subline.letsGo',
								currentFlow,
								{ minutes: props.duration ?? '4-6' },
								true
							)}
						</Text>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

DurationHint.propTypes = {
	duration: PropTypes.string
};

export default DurationHint;
