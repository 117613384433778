import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Text } from 'components/Atoms/Text';
import SvgCheck from 'components/Atoms/SVG/Icons/SvgCheck';

import styles from './ProgressBarItem.module.scss';


const ProgressBarItem = (props) => {
	return (
		<div
			className={classNames([
				styles.item,
				props.active ? styles.active : null,
				props.disabled ? styles.disabled : null
			])}
		>
			<div className={styles.outerCircle}>
				<div className={styles.innerCircle}>
					<span className={styles.step}>
						{!props.active && !props.disabled ? <SvgCheck /> : props.step}
					</span>
				</div>
			</div>
			{props.stepName && (
				<div className={styles.stepName}>
					<Text size="s">{props.stepName}</Text>
				</div>
			)}
		</div>
	);
};

ProgressBarItem.propTypes = {
	step: PropTypes.number,
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	stepName: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default ProgressBarItem;
