import React, { useEffect } from 'react';
import { m } from 'utils/messages/messages';
import { useNumberUtils } from 'utils/number/number';

import { Text } from 'components/Atoms/Text';
import { DropdownInput } from 'components/Atoms/Form';
import Table from 'components/Pages/Insurance/Protect/Table/Table';

import { useDispatch, useSelector } from 'react-redux';
import { setProtectCoverage } from 'store/Insurance/actions';

const Protect = () => {
	const { roundUp } = useNumberUtils();

	const dispatch = useDispatch();
	const storeProtectCoverage = useSelector((state => state.insurance.protectCoverage));
	const storeIncome = useSelector((state => state.financialStatus.income));

	const coverageItems = [
		/*{ value: '100', label: '100 €' },*/
		{ value: '200', label: '200 €' },
		{ value: '300', label: '300 €' },
		{ value: '400', label: '400 €' },
		{ value: '500', label: '500 €' },
		{ value: '600', label: '600 €' },
		{ value: '700', label: '700 €' },
		{ value: '800', label: '800 €' },
		{ value: '900', label: '900 €' },
		{ value: '1000', label: '1.000 €' }
	];

	useEffect(() => {
		if(storeProtectCoverage === null) {
			if (storeIncome) {
				dispatch(setProtectCoverage(roundUp((parseInt(storeIncome) * 0.4), 100, 1000)));
			} else {
				dispatch(setProtectCoverage(parseInt(coverageItems[0].value)));
			}
		}
	}, []);

	const setProtectCoverageDropdown = (value) => {
		dispatch(setProtectCoverage(parseInt(value)));
	};

	return (
		<>
			<Text mb>{m('pages.insurance.protect.body.description', 'global')}</Text>
			<DropdownInput
				value={storeProtectCoverage + ''}
				setValue={setProtectCoverageDropdown}
				items={coverageItems}
				label={m('pages.insurance.protect.body.coverage.label', 'global')}
				tabindex={1}
				testId="protect-monthly-rate"
			/>
			<Text mb><strong>{m('pages.insurance.protect.body.selectTitle', 'global')}:</strong></Text>
			<Table />
		</>
	);
};
export default Protect;
