import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Modal from 'components/Atoms/Modal/Modal';
import SvgQuestionmarkCircle from 'components/Atoms/SVG/Icons/SvgQuestionmarkCircle';

import styles from './PopupIcon.module.scss';

const PopupIcon = props => {
	const [isOpenPopup, setIsOpenPopup] = useState(false);

	const openPopup = (event) => {
		event.stopPropagation();
		setIsOpenPopup(true);
	};

	return (
		<>
			<div
				onClick={openPopup}
				className={classNames(
					styles.icon,
					props.inTitleXl ? styles.inTitleXl : null
				)}
			>
				<SvgQuestionmarkCircle />
			</div>
			<Modal
				isOpen={isOpenPopup}
				onClose={() => {
					setIsOpenPopup(false);
				}}
			>
				{props.content}
			</Modal>
		</>
	);
};

PopupIcon.propTypes = {
	content: PropTypes.any,
	inTitleXl: PropTypes.bool
};

export default PopupIcon;
