import React, { useState } from 'react';
import C from 'constants/Constants';
import { CHAR_NUMBER_INPUT } from 'constants/Regex';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useValidationUtils } from 'utils/validation/validation';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { usePageUtils } from 'utils/page/page';

import { Col, Row } from 'components/Atoms/Grid';
import { Title, Text, Link } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import { TextInput } from 'components/Atoms/Form';
import PopupIcon from 'components/Atoms/PopupIcon/PopupIcon';
import SvgShareLink from 'components/Atoms/SVG/Icons/SvgShareLink';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import { setNationalId } from 'store/NationalId/actions';

const NationalId = () => {
	const { getCurrentFlow } = useUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput } = useValidationUtils();
	const { continueInvalid, continueValid } = useTrackingUtils();
	const { goToPage } = usePageUtils();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeNationalId = useSelector((state => state.nationalId.nationalId));

	const [nationalIdError, setNationalIdError] = useState(null);

	const clearErrors = () => {
		setNationalIdError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;

		if (isEmpty(storeNationalId)) {
			setNationalIdError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();
		goToPage(currentFlow + C.ROUTES.CLEARING_ACCOUNT);
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const autofill = () => {
		dispatch(setNationalId('DE'));
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Row>
				<Col grow>
					<Title mb>{m('nationalId.title', currentFlow)}</Title>
				</Col>
				<Col noGrow>
					<PopupIcon
						content={
							<>
								<Title size="s" mb>{m('nationalId.headline', currentFlow)}</Title>
								<Text mb>{m('nationalId.content', currentFlow)}</Text>
								<Text>
									<Link href={m('nationalId.link', currentFlow)} target="_blank">
										{m('nationalId.linkText', currentFlow)}
										<SvgShareLink />
									</Link>
								</Text>
							</>
						}
						inTitleXl
					/>
				</Col>
			</Row>
			<Text size="l" mb>{m('nationalId.subtitle', currentFlow)}</Text>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={2}
				onPrevButton={() => { goToPage(currentFlow + C.ROUTES.STOCK_PROFICIENCY); }}
			>
				<TextInput
					value={storeNationalId}
					setValue={(value) => { dispatch(setNationalId(value)); }}
					label={m('nationalId.label', 'fields')}
					hasError={!!nationalIdError}
					message={nationalIdError}
					maxLength={40}
					regex={CHAR_NUMBER_INPUT}
					tabindex={1}
					testId="national-id"
				/>
			</Form>
		</>
	);
};
export default NationalId;
