import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';

import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';
import HelperText from 'components/Atoms/Form/HelperText/HelperText';
import Ripple from 'components/Atoms/Ripple/Ripple';

import textInputStyles from '../TextInput/TextInput.module.scss';
import styles from './RadioButtonInput.module.scss';

const RadioButtonInput = (props) => {
	const [id] = useState(uuid());

	const [hasFocus, setHasFocus] = useState(false);

	const refs = [
		useRef(),
		useRef(),
		useRef(),
		useRef(),
		useRef(),
		useRef(),
		useRef(),
		useRef(),
		useRef(),
		useRef(),
		useRef()
	];

	const onChange = (event) => {
		props.setValue(event.target.value);
	};

	const itemsStyle = useMemo(() => {
		switch (props.btnStyle) {
			case 'switch':
				return styles.itemsSwitch;
			case 'tab':
				return styles.itemsTab;
			default:
				return styles.itemsDefault;
		}
	}, [props.btnStyle]);

	const btnStyle = useMemo(() => {
		switch (props.btnStyle) {
			case 'switch':
				return styles.switch;
			case 'tab':
				return styles.tab;
			default:
				return styles.default;
		}
	}, [props.btnStyle]);

	return (
		<fieldset
			className={classNames(
				textInputStyles.wrapper,
				styles.wrapper,
				props.maxWidth ? styles.wrapperMaxWidth : null
			)}
		>
			{props.label && (
				<div className={textInputStyles.labelWrapper}>
					<legend className={textInputStyles.label}>{props.label}</legend>
					{props.tooltip && (
						<div className={textInputStyles.tooltip}>
							<TooltipIcon text={props.tooltip} />
						</div>
					)}
				</div>
			)}
			<div
				className={classNames(
					styles.items,
					itemsStyle,
					props.hasError ? styles.itemsError : null,
					props.disabled ? styles.itemsDisabled : null,
					hasFocus ? styles.itemsHasFocus : null,
					!props.value ? styles.noSelection : null,
					props.maxWidth ? styles.itemsMaxWidth : null
				)}
			>
				{props.items?.map((item, index) => {
					return (
						<div key={id + '-' + index} className={styles.inputWrapper}>
							<input
								type="radio"
								id={id + '-' + index}
								name={id}
								className={classNames(
									styles.input,
									props.hasError ? styles.error : null,
									props.hasError ? 'input--error' : null,
									hasFocus ? styles.hasFocus : null
								)}
								value={item.value}
								onChange={onChange}
								onFocus={() => setHasFocus(true)}
								onBlur={() => setHasFocus(false)}
								checked={props.value + '' === item.value + ''}
								disabled={props.disabled}
								tabIndex={props.tabindex}
								data-testid={props.testId + '-' + index ?? null}
							/>
							<label
								ref={index < refs.length ? refs[index] : null}
								htmlFor={id + '-' + index}
								className={classNames(styles.label, btnStyle)}
							>
								{item.prependIcon && (
									<span className={styles.prependIcon}>{item.prependIcon}</span>
								)}
								<span className={styles.text}>{item.label}</span>
								{item.appendIcon && (
									<span className={styles.prependIcon}>{item.appendIcon}</span>
								)}
								{props.btnStyle !== 'tab' && (
									<div className={styles.ripple}>
										<Ripple
											rippleRef={index < refs.length ? refs[index] : null}
											color={
												props.value + '' === item.value + ''
													? '#0a4346'
													: '#cccccc'
											}
										/>
									</div>
								)}
							</label>
						</div>
					);
				})}
			</div>
			<HelperText
				type={props.hasError ? 'error' : 'light'}
				message={props.message}
				hasIcon
			/>
		</fieldset>
	);
};

RadioButtonInput.propTypes = {
	value: PropTypes.string,
	setValue: PropTypes.func,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	items: PropTypes.array,
	btnStyle: PropTypes.string,
	maxWidth: PropTypes.bool,
	disabled: PropTypes.bool,
	interactionDisabled: PropTypes.bool,
	hasError: PropTypes.bool,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tabindex: PropTypes.number,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	testId: PropTypes.string
};

export default RadioButtonInput;
