import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useFlowUtils } from 'utils/flow/flow';
import { useKeycodeUtils } from 'utils/keycodes/keycodes';

import { Text, Title } from 'components/Atoms/Text';
import Button from 'components/Atoms/Button/Button';
import SvgClose from 'components/Atoms/SVG/Icons/SvgClose';
import SvgHandEuro from 'components/Atoms/SVG/Icons/SvgHandEuro';

import styles from './ExitWindowPopup.module.scss';

const ExitWindowPopup = () => {
	const { getCurrentFlow, isCreditSubType } = useUtils();
	const { gmkHyundaiMsgPrefix } = useFlowUtils();
	const { getReturn } = useKeycodeUtils();

	const currentFlow = getCurrentFlow();

	const [isOpen, setIsOpen] = useState(false);
	const [isOpenWait, setIsOpenWait] = useState(false);
	const [isEnabled, setIsEnabled] = useState(false);

	let inactivityTimer = null;
	let time = 30;

	const enablePopUp = useCallback(event => {
		event.preventDefault();
		setIsEnabled(true);
	}, []);

	const disablePopUp = useCallback(event => {
		event.preventDefault();
		setIsEnabled(false);
	}, []);

	useEffect(() => {
		resetTimer();
		document.addEventListener('mousemove', resetTimer);
		document.addEventListener('keydown', resetTimer);

		document.addEventListener('mouseenter', enablePopUp);
		document.addEventListener('mouseleave', disablePopUp);
		return () => {
			document.removeEventListener('mousemove', resetTimer);
			document.removeEventListener('keydown', resetTimer);

			document.removeEventListener('mouseenter', enablePopUp);
			document.removeEventListener('mouseleave', disablePopUp);
		};
	}, []);

	const resetTimer = () => {
		const exitPopupTriggered = localStorage.getItem('exitPopupTriggered') === 'true';
		if (!exitPopupTriggered) {
			clearTimeout(inactivityTimer);

			inactivityTimer = setTimeout(() => {
				setIsOpenWait(true);
				localStorage.setItem('exitPopupTriggered', 'true');
			}, time * 1000);
		}
	};

	const openPopUp = () => {
		if (isEnabled) {
			const exitPopupTriggered = localStorage.getItem('exitPopupTriggered') === 'true';

			if (!exitPopupTriggered) {
				setIsOpen(true);
				localStorage.setItem('exitPopupTriggered', 'true');
				const submitButtonArray = document.querySelectorAll(
					`[tabindex="300"]`
				);
				if (submitButtonArray.length > 0) {
					submitButtonArray[0].focus();
				}
			}
		}
	};

	const closePopUp = () => {
		setIsOpen(false);
		setIsOpenWait(false);
	};

	const handleKeyDown = (event) => {
		if (getReturn(event)) {
			closePopUp();
		}
	};

	return (
		<>
			<div className={styles.exitWindowPopupTrigger} onMouseEnter={openPopUp} />
			<div
				className={classNames([
					styles.exitWindowPopup,
					isOpen || isOpenWait ? styles.exitWindowPopupActive : null
				])}
			>
				<div className={styles.exitWindowPopupInner}>
					<div
						className={styles.closeButton}
						onClick={closePopUp}
						onKeyDown={handleKeyDown}
						tabIndex={301}
					>
						<SvgClose />
					</div>
					{isCreditSubType() && (
						<div className={styles.icon}>
							<SvgHandEuro />
						</div>
					)}
					<div className="text-align-center">
						<Title size="s" mb>
							<strong>{m(`${gmkHyundaiMsgPrefix}exitPopup.title`, currentFlow)}</strong>
						</Title>
						<Text size="l" mb>
							<strong>{m('exitPopup.subhead', currentFlow)}</strong>
						</Text>
						<Text>
							{m('exitPopup.description', currentFlow, null, true)}
						</Text>
					</div>
					<div className={styles.buttonRow}>
						<Button onClick={closePopUp} tabindex={300}>
							{m('exitPopup.continueButton', currentFlow)}
						</Button>
					</div>
				</div>
				<div className={styles.exitWindowPopupCloseBg} onClick={closePopUp} />
			</div>
		</>
	);
};
export default ExitWindowPopup;
