import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Title.module.scss';

const Title = (props) => {
	const size = useMemo(() => {
		switch (props.size) {
			case 's':
				return styles.s;
			case 'm':
				return styles.m;
			case 'l':
				return styles.l;
			default:
				return styles.xl;
		}
	}, [props.size]);

	const classes = useMemo(() => {
		return classNames(styles.title, size, props.mb ? styles.mb : null);
	}, [size, props.mb]);

	return (
		<>
			{{
				's': <h4 className={classes}>{props.children}</h4>,
				'm': <h3 className={classes}>{props.children}</h3>,
				'l': <h2 className={classes}>{props.children}</h2>,
				'xl': <h1 className={classes}>{props.children}</h1>
			}[props.size] ?? <h1 className={classes}>{props.children}</h1>}
		</>
	);
};

Title.propTypes = {
	color: PropTypes.string,
	size: PropTypes.string,
	mb: PropTypes.bool,
	children: PropTypes.any
};

export default Title;
