import React from 'react';
import PropTypes from 'prop-types';

import { useNumberUtils } from 'utils/number/number';
import { useCreditUtils } from 'utils/credit/credit';

const ProtectCoverage = (props) => {
	const { formatMoneyValue } = useNumberUtils();
	const { getProtectCoverage } = useCreditUtils();

	return (
		<>
			{props.isDecimal
				? formatMoneyValue(getProtectCoverage(), true)
				: getProtectCoverage()}
		</>
	);
};

ProtectCoverage.propTypes = {
	isDecimal: PropTypes.bool
};

export default ProtectCoverage;
