import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { C as Protect } from 'constants/enums/Protect';
import { m } from 'utils/messages/messages';

import { useSelector } from 'react-redux';

const ProtectTarif = (props) => {
	const storeProtect = useSelector((state => state.insurance.protect));

	const protectTarif = useMemo(() => {
		const protect = props.customProtect ?? storeProtect;
		const protectFormatted = protect ? protect.toLowerCase() : null;
		if (protectFormatted === Protect.STANDARD) return m('protect.tarif.items.standard', 'fields');
		if (protectFormatted === Protect.PREMIUM) return m('protect.tarif.items.premium', 'fields');
		if (protectFormatted === Protect.BASIC) return m('protect.tarif.items.basic', 'fields');
		return '';
	}, [storeProtect, props.customProtect]);

	return <>{protectTarif}</>;
};

ProtectTarif.propTypes = {
	customProtect: PropTypes.string
};

export default ProtectTarif;
