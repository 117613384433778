import React from 'react';

const SvgStopwatch5 = () => {
	const width = 24;
	const height = 24;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path d="m12,1.88C6.4,1.88,1.86,6.42,1.86,12.02c0,4.37,2.8,8.26,6.96,9.63.27.09.55-.06.64-.32.09-.27-.06-.55-.32-.64-3.74-1.24-6.26-4.73-6.26-8.66,0-5.04,4.08-9.13,9.12-9.13s9.13,4.08,9.13,9.12c0,.28.23.51.51.51s.51-.23.51-.51c0-5.6-4.54-10.14-10.14-10.14Z" />
			<path d="m12.6,13.03c.24-.04.42-.25.42-.5v-5.16c-.05-.24-.26-.42-.51-.42-.28,0-.51.23-.51.51v4.56h-4.65c-.24.05-.42.26-.42.51,0,.28.23.51.51.51h5.16Z" />
			<path d="m16.56,17.77c-.21,0-.42.02-.65.07l.16-1.83h2.47c.05-.23.08-.48.08-.75h-3.29l-.28,3.38c.43-.11.83-.16,1.21-.16,1.15,0,1.72.48,1.72,1.45s-.62,1.51-1.85,1.51c-.48,0-.88-.05-1.19-.16-.08.25-.11.49-.11.73.43.11.88.16,1.36.16.84,0,1.49-.19,1.94-.58.45-.39.68-.95.68-1.69s-.2-1.24-.6-1.6-.95-.54-1.65-.54Z" />
		</svg>
	);
};
export default SvgStopwatch5;
