import React from 'react';
import PropTypes from 'prop-types';

import { useNumberUtils } from 'utils/number/number';
import { useCreditUtils } from 'utils/credit/credit';

const MonthlyRate = (props) => {
	const { formatMoneyValue } = useNumberUtils();
	const { getMonthlyRate, getMonthlyRateWithProtect } = useCreditUtils();

	return (
		<>
			{props.isDecimal
				? formatMoneyValue(props.withProtect ? getMonthlyRateWithProtect() : getMonthlyRate(), true)
				: props.withProtect ? getMonthlyRateWithProtect() : getMonthlyRate()}
		</>
	);
};

MonthlyRate.propTypes = {
	isDecimal: PropTypes.bool,
	withProtect: PropTypes.bool
};

export default MonthlyRate;
