import { useMemo } from 'react';

export const useTimeUtils = () => {
	const officeDay = [8, 20];

	const isInOfficeHours = useMemo(() => {
		const now = new Date();

		const weekday = new Intl.DateTimeFormat('en-US', { timeZone: 'Europe/Berlin', weekday: 'short' }).format(now);
		if (weekday === 'Sat' || weekday === 'Sun') {
			return false;
		}

		const time = new Intl.DateTimeFormat('de-DE', {
			timeZone: 'Europe/Berlin',
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
			hour12: false
		}).formatToParts(now);

		const hour = parseInt(time.find((part) => part.type === 'hour').value, 10);
		return hour >= officeDay[0] && hour < officeDay[1];
	}, [officeDay]);

	return {
		isInOfficeHours
	};
};
