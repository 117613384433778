import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { v4 as uuid } from 'uuid';

import styles from './TooltipPopup.module.scss';

const TooltipPopup = (props) => {
	const id = uuid();

	return (
		<span className={styles.tooltip}>
			<span className={styles.tooltipTrigger} data-tip="" data-for={id}>
				{props.children}
			</span>
			<ReactTooltip
				id={id}
				effect="solid"
				type={props.theme ? props.theme : 'light'}
				place={props.position ? props.position : 'top'}
			>
				{props.content}
			</ReactTooltip>
		</span>
	);
};

TooltipPopup.propTypes = {
	theme: PropTypes.string,
	position: PropTypes.string,
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	children: PropTypes.any
};

export default TooltipPopup;
