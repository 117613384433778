/** Actions Type */
export const INIT_DIGITAL_ACCOUNT_CHECK = 'INIT_DIGITAL_ACCOUNT_CHECK';
export const DAC_PROCESS_RESULT_SUCCESS = 'DAC_PROCESS_RESULT_SUCCESS';
export const DIGITAL_ACCOUNT_CHECK_FAILED = 'DIGITAL_ACCOUNT_CHECK_FAILED';
export const UPDATE_DAC_STATUS = 'UPDATE_DAC_STATUS';
export const DIGITAL_ACCOUNT_CHECK_CLEAR = 'DIGITAL_ACCOUNT_CHECK_CLEAR';

/** Actions Creators */
export const initDigitalAccountCheck = payload => ({
	type: INIT_DIGITAL_ACCOUNT_CHECK,
	payload
});

export const dacProcessResultSuccess = payload => ({
	type: DAC_PROCESS_RESULT_SUCCESS,
	payload
});

export const digitalAccountCheckFailed = () => ({
	type: DIGITAL_ACCOUNT_CHECK_FAILED
});

export const digitalAccountCheckClear = () => ({
	type: DIGITAL_ACCOUNT_CHECK_CLEAR
});
