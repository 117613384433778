import C from 'constants/Constants';
import { useEnvUtils } from 'utils/env/env';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useDacApi } from 'api/dac';

import { useDispatch, useSelector } from 'react-redux';
import { digitalAccountCheckFailed } from 'store/DigitalAccountCheck/actions';

export const useDacUtils = () => {
	const { isServerPro } = useEnvUtils();
	const { continueInvalid, dacError } = useTrackingUtils();
	const { getAccountScreeningInitSession, getAccountScreeningProcessResult } = useDacApi();

	const dispatch = useDispatch();
	const storeDacSessionKey = useSelector((state => state.digitalAccountCheck.sessionKey));
	const storeReferenceIban = useSelector((state => state.bankAccount.referenceIban));

	const initSession = async (isReminder = false, redoInitCall = false) => {
		await getAccountScreeningInitSession(isReminder, redoInitCall);
	};

	const finish = (isReminder) => {
		if (window.xs2a && storeDacSessionKey) {
			window.xs2a.finish(async () => {
				if (!isServerPro()) {
					console.log('DAC - finish');
				}
				await getAccountScreeningProcessResult(
					false,
					null,
					isReminder
				);
			});
		}
	};

	const abort = (onInvalid) => {
		if (window.xs2a && storeDacSessionKey) {
			window.xs2a.abort(() => {
				if (!isServerPro()) {
					console.log('DAC - abort');
				}
				dispatch(digitalAccountCheckFailed());
				continueInvalid();
				onInvalid();
			});
		}
	};

	const login = (setXs2aStatus) => {
		if (window.xs2a && storeDacSessionKey) {
			window.xs2a.login(() => {
				if (!isServerPro()) {
					console.log('DAC - login');
				}
				setXs2aStatus(C.DAC_XS2A_STATUS.ACCOUNT);
			});
		}
	};

	const error = (onInvalid) => {
		if (window.xs2a && storeDacSessionKey) {
			window.xs2a.error((errorCode, __, recoverable) => {
				if (!isServerPro()) {
					console.log('DAC - error');
					console.log('error', errorCode);
					console.log('recoverable', recoverable);
				}
				continueInvalid();
				dacError(errorCode);

				if (errorCode === 'trans_not_possible' || errorCode === 'tech_error') {
					onInvalid();
				}
				if (recoverable) {
					return;
				}
				onInvalid();
			});
		}
	};

	const bank = () => {
		if (window.xs2a && storeDacSessionKey) {
			window.xs2a.bank(() => {
				if (!isServerPro()) {
					console.log('DAC - bank');
				}
				let timeout;
				const fillInput = () => {
					const input = document.getElementById('XS2A-bank_code');
					const submitBtn = document.getElementsByClassName('xs2a-submit')[0];
					if (input && submitBtn) {
						// Use testing bank on DEV and PRE
						if (!isServerPro()) {
							input.value = '88888888';
						} else {
							input.value = storeReferenceIban;
						}
						setTimeout(() => {
							submitBtn.click();
						}, 100);
						window.clearTimeout(timeout);
					} else {
						timeout = window.setTimeout(fillInput, 100);
					}
				};
				window.clearTimeout(timeout);
				fillInput();
			});
		}
	};

	const configure = (onBack) => {
		if (window.xs2a && storeDacSessionKey) {
			window.xs2a.configure({
				'back-button-text': 'Zurück zur manuellen Eingabe',
				'focus-onload': false,
				'back-button-action': () => {
					onBack();
				}
			});
		}
	};

	const init = () => {
		if (window.xs2a && storeDacSessionKey) {
			window.xs2a.init();
		}
	};

	return {
		initSession,
		finish,
		abort,
		login,
		error,
		bank,
		configure,
		init
	};
};
