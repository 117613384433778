import React from 'react';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';

import { Title } from 'components/Atoms/Text';
import SantanderBranchHint from 'components/Atoms/Partials/SantanderBranchHint/SantanderBranchHint';

const ThankYouRejected = () => {
	const { getCurrentFlow } = useUtils();

	const currentFlow = getCurrentFlow();

	return (
		<>
			<Title mb>{m('thankYouRejected.title', currentFlow)}</Title>
			<SantanderBranchHint />
		</>
	);
};
export default ThankYouRejected;
