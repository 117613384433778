import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M21.497 15a.5.5 0 0 1 .5.5v2.535A3.974 3.974 0 0 1 18.024 22H5.973A3.974 3.974 0 0 1 2 18.035v-2.536a.5.5 0 0 1 1 0v2.536a2.974 2.974 0 0 0 2.973 2.967h12.051a2.974 2.974 0 0 0 2.973-2.967v-2.536a.5.5 0 0 1 .5-.499zM12 2a.53.53 0 0 1 .526.426l.009.094v12.706l3.052-2.967a.546.546 0 0 1 .756 0c.186.18.207.46.062.663l-.062.072-3.964 3.854a.549.549 0 0 1-.705.045l-.053-.045-3.964-3.854a.51.51 0 0 1 0-.735c.185-.18.473-.2.682-.06l.074.06 3.052 2.967V2.52c0-.287.24-.52.535-.52z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
