import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TooltipPopup from 'components/Atoms/Tooltip/TooltipPopup';
import Modal from 'components/Atoms/Modal/Modal';
import SvgQuestionmarkCircle from 'components/Atoms/SVG/Icons/SvgQuestionmarkCircle';

import styles from './TooltipIcon.module.scss';

const TooltipIcon = (props) => {

	const [modalIsOpen, setModalIsOpen] = useState(false);

	const toggleModal = (event) => {
		console.log('open');
		event.stopPropagation();
		setModalIsOpen(!modalIsOpen);
	};

	return (
		<span
			className={styles.icon}
			onClick={(event) => {
				event.stopPropagation();
			}}
		>
			<span className={styles.iconTooltip}>
				<TooltipPopup content={props.text}>
					<SvgQuestionmarkCircle />
				</TooltipPopup>
			</span>
			<span className={styles.iconModal} onClick={toggleModal}>
				<SvgQuestionmarkCircle />
			</span>
			<Modal
				isOpen={modalIsOpen}
				onClose={() => {
					setModalIsOpen(false);
				}}
			>
				{props.text}
			</Modal>
		</span>
	);
};

TooltipIcon.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
};

export default TooltipIcon;
