import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{
					fill: props.color ? props.color : null
				}}
				d="M9.908 3.206c.9-1.556 3.105-1.606 4.102-.126l.096.153 7.567 15.146c.903 1.562-.165 3.503-1.929 3.616l-.162.005H4.418c-1.804 0-2.952-1.895-2.181-3.453l.075-.14 7.596-15.2zm3.317.5a1.417 1.417 0 0 0-2.368-.128l-.076.116L3.192 18.88a1.414 1.414 0 0 0 1.098 2.114l.128.006h15.164c1.046 0 1.715-1.086 1.272-2.03l-.061-.117-7.568-15.146zM12 17a.5.5 0 0 1 .492.41l.008.09v1a.5.5 0 0 1-.992.09l-.008-.09v-1a.5.5 0 0 1 .5-.5zm0-7.5a.5.5 0 0 1 .492.41l.008.09v5a.5.5 0 0 1-.992.09L11.5 15v-5a.5.5 0 0 1 .5-.5z"
			/>
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
