import React from 'react';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useIbanUtils } from 'utils/iban/iban';
import { useDocumentsApi } from 'api/documents';

import { Row, Col } from 'components/Atoms/Grid';
import { Text, Title } from 'components/Atoms/Text';
import Divider from 'components/Atoms/Divider/Divider';
import Form from 'components/Molecules/Form/Form';
import SvgIllustrationDataUpload from 'components/Atoms/SVG/Illustrations/SvgIllustrationDataUpload';
import ServiceHotline from 'components/Atoms/Partials/ServiceHotline/ServiceHotline';

import { useSelector } from 'react-redux';

import styles from './DepotPurchaseSuccess.module.scss';

const DepotPurchaseSuccess = () => {
	const { getCurrentFlow } = useUtils();
	const { formatIban } = useIbanUtils();
	const { getContract } = useDocumentsApi();

	const currentFlow = getCurrentFlow();

	const storeDepotNumber = useSelector((state => state.depotAccount.depotNumber));
	const storeBankCode = useSelector((state => state.depotAccount.bankCode));
	const storeClearingAccountIban = useSelector((state => state.depotAccount.clearingAccountIban));

	return (
		<>
			<Row>
				<Col xs={12} sm={6} md={7}>
					<Title mb>{m('depotPurchaseSuccess.title', currentFlow)}</Title>
				</Col>

				<Col xs={12} sm={6} md={5}>
					<div className={styles.image}>
						<SvgIllustrationDataUpload />
					</div>
				</Col>
			</Row>

			<div className={styles.blueBox}>
				<Text>{m('depotPurchaseSuccess.depotnumber', currentFlow)}</Text>
				<Text size="xl">{storeDepotNumber}</Text>
			</div>

			<div className={styles.blueBox}>
				<Text>{m('depotPurchaseSuccess.bankCode', currentFlow)}</Text>
				<Text size="xl">{storeBankCode}</Text>
			</div>

			<div className={styles.blueBox}>
				<Text>{m('depotPurchaseSuccess.clearingaccountnumber', currentFlow)}</Text>
				<Text size="xl">{formatIban(storeClearingAccountIban)}</Text>
			</div>

			<Text mb>
				<span
					dangerouslySetInnerHTML={{
						__html: m(
							'depotPurchaseSuccess.infoText',
							currentFlow
						)
					}}
				/>
			</Text>

			<Form
				onSubmit={async () => await getContract()}
				submitText={m('depotPurchaseSuccess.downloadButtonText', currentFlow)}
				clearErrors={() => {}}
				submitTabindex={1}
			/>

			<Divider noLine noMarginTop />

			<ServiceHotline />

			<Divider noLine noMarginTop />
		</>
	);
};
export default DepotPurchaseSuccess;
