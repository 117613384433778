import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Text.module.scss';

const Text = (props) => {
	const color = useMemo(() => {
		switch (props.color) {
			case 'highlight':
				return 'text--highlight';
			case 'action':
				return 'text--action';
			case 'light':
				return 'text--light';
			case 'white':
				return 'text--white';
			case 'error':
				return 'text--error';
			case 'success':
				return 'text--success';
			default:
				return 'text--primary';
		}
	}, [props.color]);

	const size = useMemo(() => {
		switch (props.size) {
			case 'xl':
				return 'text--size--xl';
			case 'l':
				return 'text--size--l';
			case 's':
				return 'text--size--s';
			default:
				return 'text--size--m';
		}
	}, [props.size]);

	return (
		<p
			className={classNames([
				size,
				color,
				props.noWrap ? 'no-wrap' : null,
				props.mb ? styles.mb : null
			])}
		>
			{props.children}
		</p>
	);
};

Text.propTypes = {
	color: PropTypes.string,
	size: PropTypes.string,
	mb: PropTypes.bool,
	noWrap: PropTypes.bool,
	children: PropTypes.any
};

export default Text;
