import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { findIndex, find } from 'lodash';
import classNames from 'classnames';
import C from 'constants/Constants';
import { usePageUtils } from 'utils/page/page';

import { rootRoutes } from 'routes/rootRoutes';

import styles from '../Dev.module.scss';

const FlowSelector = (props) => {
	const { goToPage, replaceSubpageVariable } = usePageUtils();

	const flowItems = [];
	for (const flow of Object.values(C.FRONTEND)) {
		flowItems.push({ label: flow, value: flow });
	}

	const hasSubpageInFlow = (flow) => {
		const index = findIndex(rootRoutes, (route) => {
			return route.flow === flow && route.subpage === props.reducers.page.currentSubpage;
		});
		return index !== -1;
	};

	const getRouteBySubpage = (flow) => {
		return find(rootRoutes, (route) => {
			return route.flow === flow && route.subpage === props.reducers.page.currentSubpage;
		});
	};

	const onChange = (event) => {
		const index = event.nativeEvent.target.selectedIndex;
		const selectItemValue = event.nativeEvent.target[index].value;
		if (hasSubpageInFlow(selectItemValue)) {
			const route = getRouteBySubpage(selectItemValue);
			const subpage = replaceSubpageVariable(route.subpage);
			goToPage(selectItemValue + subpage);
		} else {
			goToPage(selectItemValue + C.ROUTES.INDEX);
		}
	};

	return (
		<div className={styles.selector}>
			<select
				className={classNames(
					styles.selectorInput,
					!props.reducers.page.currentFlow ? styles.selectorInputDisabled : null
				)}
				onChange={onChange}
				value={props.reducers.page.currentFlow ? props.reducers.page.currentFlow : ''}
			>
				<option disabled hidden value="">
					Kein Flow
				</option>
				{flowItems.map((item, index) => (
					<option
						key={'flow-selector-item-' + item.value + '-' + index}
						value={item.value}
					>
						{item.label}
					</option>
				))}
			</select>
		</div>
	);
};

FlowSelector.propTypes = {
	reducers: PropTypes.object
};

const mapStateToProps = state => {
	return {
		reducers: state
	};
};

export default connect(mapStateToProps, null)(FlowSelector);
