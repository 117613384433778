import React from 'react';
import { m } from 'utils/messages/messages';

import { Text } from 'components/Atoms/Text';
import SvgSecurityLock from 'components/Atoms/SVG/Icons/SvgSecurityLock';

import styles from './SecureConnection.module.scss';

const SecureConnection = () => {
	return (
		<div className={styles.wrappper}>
			<SvgSecurityLock />
			<Text size="s" color="light">
				<strong>{m('secureConnection.title', 'fields')}</strong>
			</Text>
		</div>
	);
};
export default SecureConnection;
