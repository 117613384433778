import React, { useEffect } from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';

import { Title } from 'components/Atoms/Text';
import ServiceHotline from 'components/Atoms/Partials/ServiceHotline/ServiceHotline';

const ThankYou = () => {
	const { getCurrentFlow } = useUtils();
	const { preventHistoryBackFor } = usePageUtils();
	const { ecommercePurchase } = useTrackingUtils();

	const currentFlow = getCurrentFlow();

	useEffect(() => {
		preventHistoryBackFor(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
		ecommercePurchase();
	}, []);

	return (
		<>
			<Title mb>{m('thankYou.title', currentFlow)}</Title>
			<ServiceHotline />
		</>
	);
};
export default ThankYou;
