import React, { useRef, useState } from 'react';
import C from 'constants/Constants';
import { C as Autocomplete } from 'constants/enums/Autocomplete';
import { C as FormErrorCode } from 'constants/enums/FormErrorCode';
import {
	CHAR_NUMBER_SPACE_DASH_DOT_INPUT,
	CHAR_SPACE_DASH_DOT_INPUT,
	NUMBER_INPUT
} from 'constants/Regex';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useItemsUtils } from 'utils/items/items';

import { Col, Row } from 'components/Atoms/Grid';
import Form from 'components/Molecules/Form/Form';
import { Text, Title } from 'components/Atoms/Text';
import { TextInput, DropdownInput, CheckboxInput } from 'components/Atoms/Form';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import { setStreet, setNumber, setPostcode, setCity, setState, setHasPreviousAddress } from 'store/Address/actions';

const Address = () => {
	const { getCurrentFlow, isStaticSubType } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid, ecommerceAddShippingInfo } = useTrackingUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput, addErrorMessage } = useValidationUtils();
	const { getStateDropdownItems } = useItemsUtils();

	const stateDropdownItems = getStateDropdownItems();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeStreet = useSelector((state => state.address.street));
	const storeStreetNumber = useSelector((state => state.address.number));
	const storePostcode = useSelector((state => state.address.postcode));
	const storeCity = useSelector((state => state.address.city));
	const storeState = useSelector((state => state.address.state));
	const storeHasPreviousAddress = useSelector((state => state.address.hasPreviousAddress));

	const [streetError, setStreetError] = useState(null);
	const [streetNumberError, setStreetNumberError] = useState(null);
	const [postcodeError, setPostcodeError] = useState(null);
	const [cityError, setCityError] = useState(null);
	const [stateError, setStateError] = useState(null);
	const [hasPreviousAddressError, setHasPreviousAddressError] = useState(null);

	const showPreviousAddressField = !isStaticSubType(null, [C.FRONTEND.GIRO]);

	const cityRef = useRef(null);

	const clearErrors = () => {
		setStreetError(null);
		setStreetNumberError(null);
		setPostcodeError(null);
		setCityError(null);
		setStateError(null);
		setHasPreviousAddressError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;
		let isValidPostcode = true;
		let isValidCity = true;
		let isValidStreet = true;

		if (isEmpty(storePostcode)) {
			setPostcodeError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		} else if (storePostcode.length !== 5) {
			setPostcodeError(m('validation.error.postcode.length', 'fields'));
			isValidPostcode = false;
		}
		if (isEmpty(storeCity)) {
			setCityError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		} else if (storeCity.length < 2) {
			setCityError(m('validation.error.city.length', 'fields'));
			isValidCity = false;
		}
		if (isEmpty(storeStreet)) {
			setStreetError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		} else if (storeStreet.length < 3) {
			setStreetError(m('validation.error.street.length', 'fields'));
			isValidStreet = false;
		}
		if (isEmpty(storeStreetNumber)) {
			setStreetNumberError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}
		if (isStaticSubType()) {
			if (isEmpty(storeState)) {
				setStateError(m('validation.error.required', 'fields'));
				hasEmptyFields = true;
			}
		}
		await hasEmptyFieldsError(hasEmptyFields);

		if (!hasEmptyFields && (!isValidPostcode || !isValidCity || !isValidStreet)) {
			await addErrorMessage(
				FormErrorCode.INVALID_FIELDS,
				m('validation.error.hasInvalidFields', 'fields')
			);
		}


		const isValid = !hasEmptyFields && isValidPostcode && isValidCity && isValidStreet;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();

		ecommerceAddShippingInfo();

		if (storeHasPreviousAddress) {
			goToPage(currentFlow + C.ROUTES.PREVIOUS_ADDRESS);
		} else if (currentFlow === C.FRONTEND.VISACARD) {
			goToPage(currentFlow + C.ROUTES.BANK_ACCOUNT);
		} else {
			goToPage(currentFlow + C.ROUTES.MARITAL_STATUS);
		}
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const autofill = () => {
		dispatch(setStreet('Madrider Straße'));
		dispatch(setNumber('1'));
		dispatch(setPostcode('41069'));
		dispatch(setCity('Mönchengladbach'));
		if (isStaticSubType()) {
			dispatch(setState('Nordrhein-Westfalen'));
		}
		dispatch(setHasPreviousAddress(false));
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Title mb>{m('pages.address.title', 'global')}</Title>
			<Text size="l" mb>{m('pages.address.subtitle', 'global')}</Text>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={7}
				onPrevButton={() => { goToPage(currentFlow + C.ROUTES.NATIONALITY); }}
			>
				<Row>
					<Col xs={8}>
						<TextInput
							value={storeStreet}
							setValue={(value) => { dispatch(setStreet(value)); }}
							label={m('street.label', 'fields')}
							hasError={!!streetError}
							message={streetError}
							maxLength={50}
							regex={CHAR_SPACE_DASH_DOT_INPUT}
							autoComplete={Autocomplete.ADDRESS_LINE_1}
							tabindex={1}
							testId="street"
						/>
					</Col>
					<Col xs={4}>
						<TextInput
							value={storeStreetNumber}
							setValue={(value) => { dispatch(setNumber(value)); }}
							label={m('houseNumber.short', 'fields')}
							hasError={!!streetNumberError}
							message={streetNumberError}
							maxLength={8}
							regex={CHAR_NUMBER_SPACE_DASH_DOT_INPUT}
							autoComplete={Autocomplete.ADDRESS_LINE_2}
							tabindex={2}
							testId="house-number"
						/>
					</Col>
					<Col xs={4}>
						<TextInput
							value={storePostcode}
							setValue={(value) => { dispatch(setPostcode(value)); }}
							label={m('postcode.label', 'fields')}
							hasError={!!postcodeError}
							message={postcodeError}
							maxLength={5}
							regex={NUMBER_INPUT}
							autoComplete={Autocomplete.POSTAL_CODE}
							nextRefOnMaxLength={cityRef}
							tabindex={3}
							testId="postal-code"
						/>
					</Col>
					<Col xs={8}>
						<TextInput
							ref={cityRef}
							value={storeCity}
							setValue={(value) => { dispatch(setCity(value)); }}
							label={m('city.label', 'fields')}
							hasError={!!cityError}
							message={cityError}
							maxLength={50}
							regex={CHAR_SPACE_DASH_DOT_INPUT}
							autoComplete={Autocomplete.ADDRESS_LEVEL_2}
							tabindex={4}
							testId="city"
						/>
					</Col>
				</Row>

				{isStaticSubType() && (
					<DropdownInput
						value={storeState}
						setValue={(value) => { dispatch(setState(value)); }}
						items={stateDropdownItems}
						label={m('state.label', 'fields')}
						hasError={!!stateError}
						message={stateError}
						tabindex={5}
						testId="state"
					/>
				)}

				{showPreviousAddressField && (
					<CheckboxInput
						value={storeHasPreviousAddress}
						setValue={(value) => { dispatch(setHasPreviousAddress(value)); }}
						label={m('hasPreviousAddress.description', 'fields')}
						hasError={!!hasPreviousAddressError}
						message={hasPreviousAddressError}
						tabindex={6}
						testId="previous-address"
					/>
				)}
			</Form>
		</>
	);
};
export default Address;
