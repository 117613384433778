import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const List = (props) => {
	const color = useMemo(() => {
		switch (props.color) {
			case 'highlight':
				return 'text--highlight';
			case 'action':
				return 'text--action';
			case 'light':
				return 'text--light';
			case 'white':
				return 'text--white';
			case 'error':
				return 'text--error';
			case 'success':
				return 'text--success';
			default:
				return 'text--primary';
		}
	}, [props.color]);

	const size = useMemo(() => {
		switch (props.size) {
			case 'xl':
				return 'text--size--xl';
			case 'l':
				return 'text--size--l';
			case 's':
				return 'text--size--s';
			default:
				return 'text--size--m';
		}
	}, [props.size]);

	const style = useMemo(() => {
		switch (props.listStyle) {
			case 'checks':
				return 'list--checks';
			default:
				return null;
		}
	}, [props.listStyle]);

	return (
		<div className={classNames('list', style, size, color)}>
			{props.listType === 'ol' ? <ol>{props.children}</ol> : <ul>{props.children}</ul>}
		</div>
	);
};

List.propTypes = {
	color: PropTypes.string,
	size: PropTypes.string,
	listType: PropTypes.string,
	listStyle: PropTypes.string,
	children: PropTypes.any
};

export default List;
