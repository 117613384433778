import React, { useEffect, useState } from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useItemsUtils } from 'utils/items/items';

import { Text, Title } from 'components/Atoms/Text';
import { Col, Row } from 'components/Atoms/Grid';
import Form from 'components/Molecules/Form/Form';
import { RadioButtonInput } from 'components/Atoms/Form';
import PopupIcon from 'components/Atoms/PopupIcon/PopupIcon';
import StockProficiencyExperiences from './StockProficiencyExperiences/StockProficiencyExperiences';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import {
	setHasStockProficiency,
	setBonds,
	setBondsFrequency,
	setBondsVolume,
	setStocks,
	setStocksFrequency,
	setStocksVolume,
	setFundOfFunds,
	setFundOfFundsFrequency,
	setFundOfFundsVolume,
	setOpenPropertyFunds,
	setOpenPropertyFundsFrequency,
	setOpenPropertyFundsVolume,
	setCertificates,
	setCertificatesFrequency,
	setCertificatesVolume,
	setDerivates,
	setDerivatesFrequency,
	setDerivatesVolume,
	reset
} from 'store/StockProficiency/actions';

const StockProficiency = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { clear, isEmpty, hasEmptyFieldsError, focusFirstErrorInput } = useValidationUtils();
	const { getHasStockProficiencyItems } = useItemsUtils();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeNationality = useSelector((state => state.nationality.nationality));

	const storeHasStockProficiency = useSelector((state => state.stockProficiency.hasStockProficiency));

	const storeBonds = useSelector((state => state.stockProficiency.bonds));
	const storeBondsFrequency = useSelector((state => state.stockProficiency.bondsFrequency));
	const storeBondsVolume = useSelector((state => state.stockProficiency.bondsVolume));

	const storeStocks = useSelector((state => state.stockProficiency.stocks));
	const storeStocksFrequency = useSelector((state => state.stockProficiency.stocksFrequency));
	const storeStocksVolume = useSelector((state => state.stockProficiency.stocksVolume));

	const storeFundOfFunds = useSelector((state => state.stockProficiency.fundOfFunds));
	const storeFundOfFundsFrequency = useSelector((state => state.stockProficiency.fundOfFundsFrequency));
	const storeFundOfFundsVolume = useSelector((state => state.stockProficiency.fundOfFundsVolume));

	const storeOpenPropertyFunds = useSelector((state => state.stockProficiency.openPropertyFunds));
	const storeOpenPropertyFundsFrequency = useSelector((state => state.stockProficiency.openPropertyFundsFrequency));
	const storeOpenPropertyFundsVolume = useSelector((state => state.stockProficiency.openPropertyFundsVolume));

	const storeCertificates = useSelector((state => state.stockProficiency.certificates));
	const storeCertificatesFrequency = useSelector((state => state.stockProficiency.certificatesFrequency));
	const storeCertificatesVolume = useSelector((state => state.stockProficiency.certificatesVolume));

	const storeDerivates = useSelector((state => state.stockProficiency.derivates));
	const storeDerivatesFrequency = useSelector((state => state.stockProficiency.derivatesFrequency));
	const storeDerivatesVolume = useSelector((state => state.stockProficiency.derivatesVolume));

	const hasStockProficiencyItems = getHasStockProficiencyItems();

	const [hasStockProficiencyError, setHasStockProficiencyError] = useState(null);

	const [bondsError, setBondsError] = useState(null);
	const [bondsFrequencyError, setBondsFrequencyError] = useState(null);
	const [bondsVolumeError, setBondsVolumeError] = useState(null);

	const [stocksError, setStocksError] = useState(null);
	const [stocksFrequencyError, setStocksFrequencyError] = useState(null);
	const [stocksVolumeError, setStocksVolumeError] = useState(null);

	const [fundOfFundsError, setFundOfFundsError] = useState(null);
	const [fundOfFundsFrequencyError, setFundOfFundsFrequencyError] = useState(null);
	const [fundOfFundsVolumeError, setFundOfFundsVolumeError] = useState(null);

	const [openPropertyFundsError, setOpenPropertyFundsError] = useState(null);
	const [openPropertyFundsFrequencyError, setOpenPropertyFundsFrequencyError] = useState(null);
	const [openPropertyFundsVolumeError, setOpenPropertyFundsVolumeError] = useState(null);

	const [certificatesError, setCertificatesError] = useState(null);
	const [certificatesFrequencyError, setCertificatesFrequencyError] = useState(null);
	const [certificatesVolumeError, setCertificatesVolumeError] = useState(null);

	const [derivatesError, setDerivatesError] = useState(null);
	const [derivatesFrequencyError, setDerivatesFrequencyError] = useState(null);
	const [derivatesVolumeError, setDerivatesVolumeError] = useState(null);

	useEffect(() => {
		if (storeHasStockProficiency === 'false') {
			clear();
			clearErrors();
			dispatch(reset());
		}
	}, [storeHasStockProficiency]);

	const clearErrors = () => {
		setHasStockProficiencyError(null);

		setBondsError(null);
		setBondsFrequencyError(null);
		setBondsVolumeError(null);

		setStocksError(null);
		setStocksFrequencyError(null);
		setStocksVolumeError(null);

		setFundOfFundsError(null);
		setFundOfFundsFrequencyError(null);
		setFundOfFundsVolumeError(null);

		setOpenPropertyFundsError(null);
		setOpenPropertyFundsFrequencyError(null);
		setOpenPropertyFundsVolumeError(null);

		setCertificatesError(null);
		setCertificatesFrequencyError(null);
		setCertificatesVolumeError(null);

		setDerivatesError(null);
		setDerivatesFrequencyError(null);
		setDerivatesVolumeError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;

		if (isEmpty(storeHasStockProficiency)) {
			setHasStockProficiencyError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		} else {
			if (storeHasStockProficiency === 'true') {
				if (
					isEmpty(storeBonds) &&
					isEmpty(storeStocks) &&
					isEmpty(storeFundOfFunds) &&
					isEmpty(storeOpenPropertyFunds) &&
					isEmpty(storeCertificates) &&
					isEmpty(storeDerivates)
				) {
					hasEmptyFields = true;
				} else {
					if (!isEmpty(storeBonds) && (isEmpty(storeBondsFrequency) || isEmpty(storeBondsVolume)) && storeBondsFrequency !== 'ZERO') {
						setBondsError(m('validation.error.stockProficiency.experiencesAreMissing', 'fields'));
						hasEmptyFields = true;
						if (isEmpty(storeBondsFrequency)) {
							setBondsFrequencyError(m('validation.error.required', 'fields'));
						}
						if (!isEmpty(storeBondsFrequency) && storeBondsFrequency !== 'ZERO' && isEmpty(storeBondsVolume)) {
							setBondsVolumeError(m('validation.error.required', 'fields'));
						}
					}
					if (!isEmpty(storeStocks) && (isEmpty(storeStocksFrequency) || isEmpty(storeStocksVolume)) && storeStocksFrequency !== 'ZERO') {
						setStocksError(m('validation.error.stockProficiency.experiencesAreMissing', 'fields'));
						hasEmptyFields = true;
						if (isEmpty(storeStocksFrequency)) {
							setStocksFrequencyError(m('validation.error.required', 'fields'));
						}
						if (!isEmpty(storeStocksFrequency) && storeStocksFrequency !== 'ZERO' && isEmpty(storeStocksVolume)) {
							setStocksVolumeError(m('validation.error.required', 'fields'));
						}
					}
					if (!isEmpty(storeFundOfFunds) && (isEmpty(storeFundOfFundsFrequency) || isEmpty(storeFundOfFundsVolume)) && storeFundOfFundsFrequency !== 'ZERO') {
						setFundOfFundsError(m('validation.error.stockProficiency.experiencesAreMissing', 'fields'));
						hasEmptyFields = true;
						if (isEmpty(storeFundOfFundsFrequency)) {
							setFundOfFundsFrequencyError(m('validation.error.required', 'fields'));
						}
						if (!isEmpty(storeFundOfFundsFrequency) && storeFundOfFundsFrequency !== 'ZERO' && isEmpty(storeFundOfFundsVolume)) {
							setFundOfFundsVolumeError(m('validation.error.required', 'fields'));
						}
					}
					if (!isEmpty(storeOpenPropertyFunds) && (isEmpty(storeOpenPropertyFundsFrequency) || isEmpty(storeOpenPropertyFundsVolume)) && storeOpenPropertyFundsFrequency !== 'ZERO') {
						setOpenPropertyFundsError(m('validation.error.stockProficiency.experiencesAreMissing', 'fields'));
						hasEmptyFields = true;
						if (isEmpty(storeOpenPropertyFundsFrequency)) {
							setOpenPropertyFundsFrequencyError(m('validation.error.required', 'fields'));
						}
						if (!isEmpty(storeOpenPropertyFundsFrequency) && storeOpenPropertyFundsFrequency !== 'ZERO' && isEmpty(storeOpenPropertyFundsVolume)) {
							setOpenPropertyFundsVolumeError(m('validation.error.required', 'fields'));
						}
					}
					if (!isEmpty(storeCertificates) && (isEmpty(storeCertificatesFrequency) || isEmpty(storeCertificatesVolume)) && storeCertificatesFrequency !== 'ZERO') {
						setCertificatesError(m('validation.error.stockProficiency.experiencesAreMissing', 'fields'));
						hasEmptyFields = true;
						if (isEmpty(storeCertificatesFrequency)) {
							setCertificatesFrequencyError(m('validation.error.required', 'fields'));
						}
						if (!isEmpty(storeCertificatesFrequency) && storeCertificatesFrequency !== 'ZERO' && isEmpty(storeCertificatesVolume)) {
							setCertificatesVolumeError(m('validation.error.required', 'fields'));
						}
					}
					if (!isEmpty(storeDerivates) && (isEmpty(storeDerivatesFrequency) || isEmpty(storeDerivatesVolume)) && storeDerivatesFrequency !== 'ZERO') {
						setDerivatesError(m('validation.error.stockProficiency.experiencesAreMissing', 'fields'));
						hasEmptyFields = true;
						if (isEmpty(storeDerivatesFrequency)) {
							setDerivatesFrequencyError(m('validation.error.required', 'fields'));
						}
						if (!isEmpty(storeDerivatesFrequency) && storeDerivatesFrequency !== 'ZERO' && isEmpty(storeDerivatesVolume)) {
							setDerivatesVolumeError(m('validation.error.required', 'fields'));
						}
					}
				}
			}
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			continueValid();
			const prioCountry = ['DE', 'FR', 'HU', 'IE', 'AT', 'LU'].find((country) => country === storeNationality);
			goToPage(currentFlow + (prioCountry ? C.ROUTES.CLEARING_ACCOUNT : C.ROUTES.NATIONAL_ID));
		} else {
			continueInvalid();
		}
	};

	const autofill = () => {
		dispatch(setHasStockProficiency("false"));
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Row>
				<Col grow>
					<Title mb>{m('stockProficiency.title', currentFlow)}</Title>
				</Col>
				<Col noGrow>
					<PopupIcon
						content={
							<>
								<Title size="s" mb>{m('stockProficiency.infoTitle', currentFlow)}</Title>
								<Text>{m('stockProficiency.infoText', currentFlow, null, true)}</Text>
							</>
						}
						inTitleXl
					/>
				</Col>
			</Row>
			<Text size="l" mb>{m('stockProficiency.subtitle', currentFlow)}</Text>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={20}
				onPrevButton={() => { goToPage(currentFlow + C.ROUTES.TIN); }}
			>
				<RadioButtonInput
					value={storeHasStockProficiency !== null ? storeHasStockProficiency + '' : null}
					setValue={(value) => { dispatch(setHasStockProficiency(value)); }}
					items={hasStockProficiencyItems}
					hasError={!!hasStockProficiencyError}
					message={hasStockProficiencyError}
					tabindex={1}
					testId="has-stock-proficiency"
				/>

				{storeHasStockProficiency === 'true' && (
					<>
						<Title size="s" mb>{m('stockProficiency.proficiency', currentFlow)}</Title>

						<StockProficiencyExperiences
							label={m('stockProficiency.bonds', currentFlow)}
							value={storeBonds}
							setValue={(value) => { dispatch(setBonds(value)); }}
							message={bondsError}
							valueFrequency={storeBondsFrequency}
							setValueFrequency={(value) => { dispatch(setBondsFrequency(value)); }}
							frequencyError={bondsFrequencyError}
							valueVolume={storeBondsVolume}
							setValueVolume={(value) => { dispatch(setBondsVolume(value)); }}
							volumeError={bondsVolumeError}
							tabindex={2}
							testId="bonds"
						/>

						<StockProficiencyExperiences
							label={m('stockProficiency.stocks', currentFlow)}
							value={storeStocks}
							setValue={(value) => { dispatch(setStocks(value)); }}
							message={stocksError}
							valueFrequency={storeStocksFrequency}
							setValueFrequency={(value) => { dispatch(setStocksFrequency(value)); }}
							frequencyError={stocksFrequencyError}
							valueVolume={storeStocksVolume}
							setValueVolume={(value) => { dispatch(setStocksVolume(value)); }}
							volumeError={stocksVolumeError}
							tabindex={5}
							testId="stocks"
						/>

						<StockProficiencyExperiences
							label={m('stockProficiency.fundOfFunds', currentFlow)}
							value={storeFundOfFunds}
							setValue={(value) => { dispatch(setFundOfFunds(value)); }}
							message={fundOfFundsError}
							valueFrequency={storeFundOfFundsFrequency}
							setValueFrequency={(value) => { dispatch(setFundOfFundsFrequency(value)); }}
							frequencyError={fundOfFundsFrequencyError}
							valueVolume={storeFundOfFundsVolume}
							setValueVolume={(value) => { dispatch(setFundOfFundsVolume(value)); }}
							volumeError={fundOfFundsVolumeError}
							tabindex={8}
							testId="fund-of-funds"
						/>

						<StockProficiencyExperiences
							label={m('stockProficiency.openPropertyFunds', currentFlow)}
							value={storeOpenPropertyFunds}
							setValue={(value) => { dispatch(setOpenPropertyFunds(value)); }}
							message={openPropertyFundsError}
							valueFrequency={storeOpenPropertyFundsFrequency}
							setValueFrequency={(value) => { dispatch(setOpenPropertyFundsFrequency(value)); }}
							frequencyError={openPropertyFundsFrequencyError}
							valueVolume={storeOpenPropertyFundsVolume}
							setValueVolume={(value) => { dispatch(setOpenPropertyFundsVolume(value)); }}
							volumeError={openPropertyFundsVolumeError}
							tabindex={11}
							testId="open-property-funds"
						/>

						<StockProficiencyExperiences
							label={m('stockProficiency.certificates', currentFlow)}
							value={storeCertificates}
							setValue={(value) => { dispatch(setCertificates(value)); }}
							message={certificatesError}
							valueFrequency={storeCertificatesFrequency}
							setValueFrequency={(value) => { dispatch(setCertificatesFrequency(value)); }}
							frequencyError={certificatesFrequencyError}
							valueVolume={storeCertificatesVolume}
							setValueVolume={(value) => { dispatch(setCertificatesVolume(value)); }}
							volumeError={certificatesVolumeError}
							tabindex={14}
							testId="certificates"
						/>

						<StockProficiencyExperiences
							label={m('stockProficiency.derivates', currentFlow)}
							value={storeDerivates}
							setValue={(value) => { dispatch(setDerivates(value)); }}
							message={derivatesError}
							valueFrequency={storeDerivatesFrequency}
							setValueFrequency={(value) => { dispatch(setDerivatesFrequency(value)); }}
							frequencyError={derivatesFrequencyError}
							valueVolume={storeDerivatesVolume}
							setValueVolume={(value) => { dispatch(setDerivatesVolume(value)); }}
							volumeError={derivatesVolumeError}
							tabindex={17}
							testId="derivates"
						/>
					</>
				)}
			</Form>
		</>
	);
};
export default StockProficiency;
