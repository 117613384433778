import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Box from 'components/Molecules/Box/Box';
import { Text, Title } from 'components/Atoms/Text';

import styles from './NeededUtilsAndDocuments.module.scss';

const NeededUtilsAndDocuments = (props) => {
	return (
		<Box borderRadius shadow size="l">
			<div className={classNames([
				styles.wrapper,
				props.image ? styles.hasImage : null
			])}>
				{props.title && (
					<div className={styles.title}>
						<Title size="m" mb>{props.title}</Title>
					</div>
				)}
				<div className={styles.items}>
					{props.items.map((item, index) => (
						<div
							key={'needed-utils-and-documents-item-' + index}
							className={styles.item}
						>
							{item.icon && (
								<div className={styles.icon}>
									{item.icon}
								</div>
							)}
							<div className={styles.text}>
								<Text size="s">{item.text}</Text>
							</div>
						</div>
					))}
				</div>
				{props.image && (
					<div className={styles.image}>
						{props.image}
					</div>
				)}
			</div>
		</Box>
	);
};

NeededUtilsAndDocuments.propTypes = {
	title: PropTypes.string,
	items: PropTypes.array,
	image: PropTypes.any
};

export default NeededUtilsAndDocuments;
