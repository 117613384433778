import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useApplicationApi } from 'api/application';

import { Col, Row } from 'components/Atoms/Grid';
import { Text, Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import HandHoldingOK from 'components/Atoms/SVG/Illustrations/SvgIllustrationHandHoldingCheck';

import styles from './DigitalAccountCheckSuccess.module.scss';

const DigitalAccountCheckSuccess = () => {
	const { getCurrentFlow, isCreditCardSubType } = useUtils();
	const { hasEmptyFieldsError, focusFirstErrorInput } = useValidationUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { postApplication } = useApplicationApi();

	const currentFlow = getCurrentFlow();

	const params = useParams();

	const [isReminder, setIsReminder] = useState(false);
	const [isNextButtonDisabled, setNextButtonDisabled] = useState(false);

	useEffect(() => {
		parseParam();
	}, []);

	const parseParam = async () => {
		if (params.isReminder && params.isReminder === 'reminder') {
			setIsReminder(true);
			setNextButtonDisabled(true);
			await postApplication(
				() => {},
				() => {},
				() => {
					setNextButtonDisabled(false);
				},
				true
			);
		}
	};

	const clearErrors = () => {};

	const validateFields = async () => {
		let hasEmptyFields = false;

		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			continueValid();
			if (isCreditCardSubType()) {
				goToPage(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
			} else {
				goToPage(currentFlow + (isReminder ? C.ROUTES.UPLOAD_DOCUMENTS : C.ROUTES.EXTERNAL_CREDIT));
			}
		} else {
			continueInvalid();
		}
	};

	return (
		<>
			<Row>
				<Col xs={12} md={6}>
					<div className={styles.iconGroup}>
						<HandHoldingOK />
					</div>
				</Col>
				<Col xs={12} md={6}>
					<div className={styles.textGroup}>
						<Title mb>
							{m(
								'pages.digitalAccountCheckSuccess.title',
								'global',
								null,
								true
							)}
						</Title>
						<Text size="l">
							{m('pages.digitalAccountCheckSuccess.subtitle', 'global')}
						</Text>
					</div>
				</Col>
			</Row>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={1}
				submitDisabled={isNextButtonDisabled}
			/>
		</>
	);
};
export default DigitalAccountCheckSuccess;
