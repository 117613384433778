import { clone, isEmpty } from 'lodash';
import {
	CREDIT_AMOUNT_MAX_VALUE,
	CREDIT_AMOUNT_DEFAULT,
	CREDIT_RUNTIME_STEPS,
	CREDIT_RUNTIME_DEFAULT
} from 'constants/Credit';
import { C as Protect } from 'constants/enums/Protect';
import { useIbanUtils } from 'utils/iban/iban';
import { useNumberUtils } from 'utils/number/number';
import { useDateUtils } from 'utils/date/date';

import { useDispatch, useSelector } from 'react-redux';
import { setRuntimeLimit, setSidebarActive, updateRuntime, updateSidebarRuntimeAnimation } from 'store/App/actions';

export const useCreditUtils = () => {
	const { monthDiff } = useDateUtils();
	const { trimIban } = useIbanUtils();
	const { roundUp } = useNumberUtils();

	const dispatch = useDispatch();
	const storeAmount = useSelector((state => state.app.amount));
	const storeRuntime = useSelector((state => state.app.runtime));
	const storeIsSidebarRateChecked = useSelector((state => state.app.isSidebarRateChecked));
	const storeCcbData = useSelector((state => state.app.ccbData));
	const storeExternalCredits = useSelector((state => state.externalCredit.externalCredits));
	const storeProtect = useSelector((state => state.insurance.protect));
	const storeProtectCoverage = useSelector((state => state.insurance.protectCoverage));
	const storeIncome = useSelector((state => state.financialStatus.income));
	const storeAdditionalIncome = useSelector((state => state.financialStatus.additionalIncome));
	const storeOccupationLimitedMonth = useSelector((state => state.occupation.occupationLimitedMonth));
	const storeOccupationLimitedYear = useSelector((state => state.occupation.occupationLimitedYear));

	const storeBcoTotalAmount = useSelector((state => state.app.bcoResults.creditAmountTotal));

	const getValidRuntimeLimit = (value) => {
		const sliderValues = CREDIT_RUNTIME_STEPS;
		let validRuntimeLimitObj = {
			validRuntimeLimit: 12,
			validRuntimeIndex: 0
		};

		for (
			let sliderValueIndex = 0;
			sliderValueIndex < sliderValues.length;
			sliderValueIndex++
		) {
			let item = sliderValues[sliderValueIndex];
			let itemBefore = sliderValues[sliderValueIndex - 1];

			if (value < item) {
				validRuntimeLimitObj = {
					validRuntimeLimit: itemBefore,
					validRuntimeIndex: sliderValueIndex
				};
				break;
			} else if (value >= CREDIT_RUNTIME_STEPS[CREDIT_RUNTIME_STEPS.length - 1]) {
				validRuntimeLimitObj = {
					validRuntimeLimit: CREDIT_RUNTIME_STEPS[CREDIT_RUNTIME_STEPS.length - 1],
					validRuntimeIndex: sliderValues.length
				};
			}
		}

		return validRuntimeLimitObj;
	};

	const getSummedExternalCreditAmount = (customExternalCredits = null) => {
		const externalCredits = customExternalCredits ? customExternalCredits : storeExternalCredits;
		let summedRemainingBalance = 0;
		for (const externalCredit of externalCredits) {
			summedRemainingBalance += externalCredit.remainingBalance ? parseInt(externalCredit.remainingBalance) : 0;
		}
		return summedRemainingBalance;
	};

	const getExternalCreditsWithTrimmedIbans = () => {
		let externalCredits = clone(storeExternalCredits);
		for (let externalCredit of externalCredits) {
			externalCredit.iban = trimIban(externalCredit.iban);
		}
		return externalCredits;
	};

	const summedNetAmount = () => {
		const amount = storeAmount ?? CREDIT_AMOUNT_DEFAULT;
		return amount + getSummedExternalCreditAmount();
	};

	const getNormalizedAmount = (amount) => {
		return roundUp(amount, 500, CREDIT_AMOUNT_MAX_VALUE);
	};

	const getTotalAmount = (customAmount = null) => {
		const netAmount = customAmount ?? (storeBcoTotalAmount !== null ? storeBcoTotalAmount : summedNetAmount());
		return netAmount + getInterest();
	};

	const summedRuntime = (customRuntime = null) => {
		const runtime = customRuntime ?? storeRuntime ?? CREDIT_RUNTIME_DEFAULT;
		return runtime ?? 0;
	};

	const getNormalizedRuntime = (runtime) => {
		let normalizedRuntime;
		const index = CREDIT_RUNTIME_STEPS.findIndex((defaultRuntime) => {
			return runtime < defaultRuntime;
		});

		if (index === -1) {
			normalizedRuntime = CREDIT_RUNTIME_STEPS[CREDIT_RUNTIME_STEPS.length - 1];
		} else if (index === 0) {
			normalizedRuntime = CREDIT_RUNTIME_STEPS[0];
		} else {
			normalizedRuntime = CREDIT_RUNTIME_STEPS[index - 1];
		}

		return normalizedRuntime;
	};

	const getJsonData = () => {
		return storeCcbData && storeCcbData.credit_values ? storeCcbData.credit_values : {};
	};

	const getMonthlyRateJsonData = () => {
		const jsonData = getJsonData();
		if (!isEmpty(jsonData)) {
			const normalizedAmountString = getNormalizedAmount(summedNetAmount()).toString();
			const normalizedRuntimeString = getNormalizedRuntime(summedRuntime()).toString();
			try {
				return jsonData['0'][normalizedAmountString][normalizedRuntimeString];
			} catch (e) {
				return null;
			}
		} else {
			return null;
		}
	};

	const getMonthlyRate = (customAmount = null, customRuntime = null) => {
		try {
			if (customAmount || customRuntime) {
				return getTotalAmount(customAmount) / getNormalizedRuntime(summedRuntime(customRuntime));
			} else {
				const jsonData = getMonthlyRateJsonData();
				return jsonData[jsonData.length - 1];
			}
		} catch (e) {
			return 0;
		}
	};

	const getMonthlyRateWithProtect = (customAmount = null, customRuntime = null) => {
		return getMonthlyRate(customAmount, customRuntime) + getProtectMonthlyRate();
	};

	const getProtectValues = (customCoverage = null) => {
		let jsonData = require('data/protect.json');
		const protectCoverage = customCoverage ?? getProtectCoverage();
		if (protectCoverage) {
			return jsonData[protectCoverage + ''];
		} else {
			return null;
		}
	};

	const getProtectMonthlyRateByProtect = (protect, customCoverage = null) => {
		const protectValues = getProtectValues(customCoverage);
		let protectMonthlyRate = 0;
		const protectFormatted = protect ? protect.toLowerCase() : null;
		if (protectFormatted === Protect.STANDARD) {
			protectMonthlyRate = protectValues[1];
		} else if (protectFormatted === Protect.PREMIUM) {
			protectMonthlyRate = protectValues[2];
		} else if (protectFormatted === Protect.BASIC) {
			protectMonthlyRate = protectValues[0];
		}
		return protectMonthlyRate;
	};

	const getProtectMonthlyRate = () => {
		return getProtectMonthlyRateByProtect(storeProtect);
	};

	const getProtectCoverage = () => {
		return storeProtectCoverage ? +storeProtectCoverage : 200;
	};

	const getProtectAmount = () => {
		const protectMonthlyRate = getProtectMonthlyRate();
		return protectMonthlyRate * 60;
	};

	const getEffectiveYearlyInterestRate = () => {
		try {
			const jsonData = getMonthlyRateJsonData();
			return jsonData[0];
		} catch (e) {
			return 0;
		}
	};

	const getFixedBorrowingRate = () => {
		try {
			const jsonData = getMonthlyRateJsonData();
			return jsonData[1];
		} catch (e) {
			return 0;
		}
	};

	const getInterest = () => {
		try {
			const jsonData = getMonthlyRateJsonData();
			return jsonData[2];
		} catch (e) {
			return 0;
		}
	};

	const getMonthlyIncome = () => {
		const income = storeIncome || 0;
		const additionalIncome = storeAdditionalIncome || 0;
		return parseFloat(income) + parseFloat(additionalIncome);
	};

	const occupationCalculateMaxRuntimeValue = (month, year) => {
		let today = new Date();
		let dateFrom = new Date(today.getFullYear(), today.getMonth() + 1);
		let dateTo = new Date(year, month);

		return monthDiff(dateFrom, dateTo) + 6;
	};

	const occupationCheckRunTimeLimit = () => {
		let currentRuntime = storeRuntime;
		let runtimeLimit = CREDIT_RUNTIME_STEPS[CREDIT_RUNTIME_STEPS.length - 1];
		let isValid = true;

		if (storeOccupationLimitedMonth && storeOccupationLimitedMonth.length === 2 && storeOccupationLimitedYear && storeOccupationLimitedYear.length === 4) {
			runtimeLimit = occupationCalculateMaxRuntimeValue(storeOccupationLimitedMonth, storeOccupationLimitedYear);
			runtimeLimit = runtimeLimit < CREDIT_RUNTIME_STEPS[0] ? CREDIT_RUNTIME_STEPS[0] : runtimeLimit;
			runtimeLimit = runtimeLimit > CREDIT_RUNTIME_STEPS[CREDIT_RUNTIME_STEPS.length - 1] ? CREDIT_RUNTIME_STEPS[CREDIT_RUNTIME_STEPS.length - 1] : runtimeLimit;
			runtimeLimit = storeIsSidebarRateChecked ? runtimeLimit - 3 : runtimeLimit;
			runtimeLimit = getValidRuntimeLimit(runtimeLimit).validRuntimeLimit;
			dispatch(setRuntimeLimit(runtimeLimit));
		}

		if (currentRuntime > runtimeLimit) {
			dispatch(setSidebarActive(true));
			dispatch(updateRuntime(runtimeLimit));
			dispatch(updateSidebarRuntimeAnimation(true));
			isValid = false;
		}

		return isValid;
	};

	const mapCurrentRuntimeToYears = () => {
		const runtime = storeRuntime ?? CREDIT_RUNTIME_DEFAULT;
		return Math.ceil(runtime / 12);
	};

	return {
		getValidRuntimeLimit,
		getSummedExternalCreditAmount,
		getExternalCreditsWithTrimmedIbans,
		summedNetAmount,
		getTotalAmount,
		summedRuntime,
		getMonthlyRate,
		getMonthlyRateWithProtect,
		getProtectValues,
		getProtectMonthlyRateByProtect,
		getProtectMonthlyRate,
		getProtectCoverage,
		getProtectAmount,
		getEffectiveYearlyInterestRate,
		getFixedBorrowingRate,
		getInterest,
		getMonthlyIncome,
		occupationCheckRunTimeLimit,
		mapCurrentRuntimeToYears
	};
};
