import React from 'react';
import { useSelector } from 'react-redux';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useIbanUtils } from 'utils/iban/iban';
import { useFlowUtils } from 'utils/flow/flow';
import { useApplicationApi } from 'api/application';
import { useDocumentsApi } from 'api/documents';

import { Row, Col } from 'components/Atoms/Grid';
import { Link, Text, Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import Divider from 'components/Atoms/Divider/Divider';
import Box from 'components/Molecules/Box/Box';
import SvgShareLink from 'components/Atoms/SVG/Icons/SvgShareLink';
import SvgCards from 'components/Atoms/SVG/Icons/SvgCards';
import SvgSavingAccountEuro from 'components/Atoms/SVG/Icons/SvgSavingAccountEuro';
import SvgInformation from 'components/Atoms/SVG/Icons/SvgInformation';
import SvgDownloadLink from 'components/Atoms/SVG/Icons/SvgDownloadLink';

import sanVisaSrc from 'assets/img/san_visa.png';
import sanSparbriefSrc from 'assets/img/sparbrief_account_success.png';

import styles from './AccountSuccess.module.scss';

const AccountSuccess = () => {
	const { getCurrentFlow } = useUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { formatIban } = useIbanUtils();
	const { gmkHyundaiMsgPrefix } = useFlowUtils();
	const { getAccountSwitchUpdate } = useApplicationApi();
	const { getContract } = useDocumentsApi();

	const currentFlow = getCurrentFlow();

	const storeNewAccountIban = useSelector((state => state.newAccount.iban));
	const storeInvestmentAmount = useSelector((state => state.investment.amount));

	const onSubmit = async () => {
		await getAccountSwitchUpdate(
			() => {
				continueValid();
			},
			() => {
				continueInvalid();
			}
		);
	};

	return (
		<>
			<Title mb>{m(`${gmkHyundaiMsgPrefix}accountSuccess.title`, currentFlow)}</Title>
			<Row>
				<Col xs={12} sm={4}>
					{currentFlow === C.FRONTEND.GMK ? (
						<div className={styles.euro}>
							<SvgSavingAccountEuro color="#9E3667" />
						</div>
					) : (
						<>
							{currentFlow === C.FRONTEND.SPARBRIEF ? (
								<div className={styles.sparbriefImg}>
									<img className={styles.sparbriefImg} src={sanSparbriefSrc} alt="Santander Sparbrief" />
								</div>
							) : (
								<div className={styles.card}>
									<img src={sanVisaSrc} alt="Santander Visa" />
								</div>
							)}
						</>
					)}
				</Col>
				<Col xs={12} sm={8}>
					{storeNewAccountIban && (
						<>
							<Text size="l" mb>
								IBAN{currentFlow === C.FRONTEND.GIRO ? ' (am besten gleich notieren)' : ''}<br/>
								<strong>{formatIban(storeNewAccountIban)}</strong>
							</Text>
							<Divider />
						</>
					)}
					<Text mb>
						{m('accountSuccess.subtitle', currentFlow)}
					</Text>
					{(currentFlow === C.FRONTEND.GMK || currentFlow === C.FRONTEND.SPARBRIEF) && (
						<>
							<Divider noLine noMarginBottom />
							<Text>
								<Link onClick={async () => await getContract()}>
									<SvgDownloadLink />
									{m(`${gmkHyundaiMsgPrefix}accountSuccess.contract`, currentFlow)}
								</Link>
							</Text>
						</>
					)}
				</Col>
			</Row>

			{currentFlow === C.FRONTEND.GIRO && storeNewAccountIban && (
				<div className="mt--15">
					<Title size="s" mb>
						{m('accountSuccess.accountChange.title', currentFlow)}
					</Title>
					<Text size="m" mb>
						{m(
							'accountSuccess.accountChange.description',
							currentFlow,
							null,
							true
						)}
					</Text>
					<Form
						onSubmit={onSubmit}
						clearErrors={() => {}}
						submitTabindex={1}
						submitText={m('accountSuccess.accountChange.buttonText', currentFlow)}
					/>
				</div>
			)}
			{currentFlow === C.FRONTEND.GIRO && (
				<>
					<Divider noLine />
					<Box shadow borderRadius>
						<Text mb><SvgCards color="#ec0000" /><strong>{m('accountSuccess.bestCard.title', currentFlow)}</strong></Text>
						<Text mb>{m('accountSuccess.bestCard.description', currentFlow)}</Text>
						<Text>
							<Link
								href="https://www.santander.de/kreditkarten/bestcard-preisvorteil/"
								target="_blank"
							>
								{m('accountSuccess.bestCard.button', currentFlow)}
								<SvgShareLink />
							</Link>
						</Text>
					</Box>
				</>
			)}

			{currentFlow === C.FRONTEND.SPARBRIEF && (
				<div className={styles.sparbriefHintBox}>
					<div className={styles.sparbriefHintBoxIcon}>
						<SvgInformation />
					</div>
					<div>
						<Title size="s" mb>{m('accountSuccess.hint.title', currentFlow)}</Title>
						<Text>{m('accountSuccess.hint.description', currentFlow, { amount: storeInvestmentAmount ?? '0' })}</Text>
					</div>
				</div>
			)}
		</>
	);
};
export default AccountSuccess;
