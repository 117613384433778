import { combineReducers } from 'redux';
import App from './App';
import Page from './Page';
import Loading from './Loading';
import Form from './Form';
import Consent from './Consent';
import Address from './Address';
import Birthdate from './Birthdate';
import DepotAccount from './DepotAccount';
import Nationality from './Nationality';
import Email from './Email';
import TaxInformation from './TaxInformation';
import Tin from './Tin';
import StockProficiency from './StockProficiency';
import Name from './Name';
import MaritalStatus from './MaritalStatus';
import Mobile from './MobileNumber';
import Occupation from './Occupation';
import BankAccount from './BankAccount';
import NewAccount from './NewAccount';
import FinancialStatus from './FinancialStatus';
import ExternalCredit from './ExternalCredit';
import Insurance from './Insurance';
import NationalId from './NationalId';
import DigitalAccountCheck from './DigitalAccountCheck';
import Dispo from './Dispo';
import TermsAndConditions from './TermsAndConditions';
import Features from './Features';
import SecondApplicant from './SecondApplicant';
import Investment from './Investment';
import Tracking from './Tracking';
import Applicant from './Applicant';
import Upsell from './Upsell';
import BestCard from './BestCard';

export default combineReducers({
	[App.constant.NAME]: App.reducers,
	[Page.constant.NAME]: Page.reducers,
	[Loading.constant.NAME]: Loading.reducers,
	[Form.constant.NAME]: Form.reducers,
	[Consent.constant.NAME]: Consent.reducers,
	[Address.constant.NAME]: Address.reducers,
	[Birthdate.constant.NAME]: Birthdate.reducers,
	[DepotAccount.constant.NAME]: DepotAccount.reducers,
	[Nationality.constant.NAME]: Nationality.reducers,
	[Email.constant.NAME]: Email.reducers,
	[TaxInformation.constant.NAME]: TaxInformation.reducers,
	[Tin.constant.NAME]: Tin.reducers,
	[StockProficiency.constant.NAME]: StockProficiency.reducers,
	[NationalId.constant.NAME]: NationalId.reducers,
	[Name.constant.NAME]: Name.reducers,
	[MaritalStatus.constant.NAME]: MaritalStatus.reducers,
	[Mobile.constant.NAME]: Mobile.reducers,
	[Occupation.constant.NAME]: Occupation.reducers,
	[BankAccount.constant.NAME]: BankAccount.reducers,
	[NewAccount.constant.NAME]: NewAccount.reducers,
	[FinancialStatus.constant.NAME]: FinancialStatus.reducers,
	[ExternalCredit.constant.NAME]: ExternalCredit.reducers,
	[Insurance.constant.NAME]: Insurance.reducers,
	[DigitalAccountCheck.constant.NAME]: DigitalAccountCheck.reducers,
	[Dispo.constant.NAME]: Dispo.reducers,
	[TermsAndConditions.constant.NAME]: TermsAndConditions.reducers,
	[Features.constant.NAME]: Features.reducers,
	[SecondApplicant.constant.NAME]: SecondApplicant.reducers,
	[Investment.constant.NAME]: Investment.reducers,
	[Tracking.constant.NAME]: Tracking.reducers,
	[Applicant.constant.NAME]: Applicant.reducers,
	[Upsell.constant.NAME]: Upsell.reducers,
	[BestCard.constant.NAME]: BestCard.reducers
});
