import React, { useEffect, useState, useRef } from 'react';
import C from 'constants/Constants';
import { CREDIT_RUNTIME_STEPS } from 'constants/Credit';
import { NUMBER_INPUT } from 'constants/Regex';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useCreditUtils } from 'utils/credit/credit';
import { useItemsUtils } from 'utils/items/items';
import { useFlowUtils } from 'utils/flow/flow';

import { Col, Row } from 'components/Atoms/Grid';
import Form from 'components/Molecules/Form/Form';
import { Text, Title } from 'components/Atoms/Text';
import { TextInput, DropdownInput, RadioButtonInput } from 'components/Atoms/Form';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import {
	setOccupation,
	setOccupationMonth,
	setOccupationYear,
	setOccupationLimited,
	setOccupationLimitedMonth,
	setOccupationLimitedYear
} from 'store/Occupation/actions';
import { setRuntimeNotification, setRuntimeLimit } from 'store/App/actions';
import { C as FormErrorCode } from 'constants/enums/FormErrorCode';

const Occupation = () => {
	const { getCurrentFlow, isCreditOrCreditCardSubType, isCreditSubType, isStaticSubType } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput, hasDateMonthError, hasDateYearError, hasOccupationSinceError, hasOccupationUntilError, addErrorMessage } = useValidationUtils();
	const { occupationCheckRunTimeLimit } = useCreditUtils();
	const { getOccupationItems, getOccupationLimitedItems } = useItemsUtils();
	const { gmkHyundaiMsgPrefix } = useFlowUtils();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeOccupation = useSelector((state => state.occupation.occupation));
	const storeOccupationMonth = useSelector((state => state.occupation.occupationMonth));
	const storeOccupationYear = useSelector((state => state.occupation.occupationYear));
	const storeOccupationLimited = useSelector((state => state.occupation.occupationLimited));
	const storeOccupationLimitedMonth = useSelector((state => state.occupation.occupationLimitedMonth));
	const storeOccupationLimitedYear = useSelector((state => state.occupation.occupationLimitedYear));
	const storeBirthdate = useSelector((state => state.birthdate.birthdate));

	const [occupationError, setOccupationError] = useState(null);
	const [occupationMonthError, setOccupationMonthError] = useState(null);
	const [occupationYearError, setOccupationYearError] = useState(null);
	const [occupationLimitedError, setOccupationLimitedError] = useState(null);
	const [occupationLimitedMonthError, setOccupationLimitedMonthError] = useState(null);
	const [occupationLimitedYearError, setOccupationLimitedYearError] = useState(null);

	const occupationItems = getOccupationItems();
	if (currentFlow !== C.FRONTEND.GIRO) {
		occupationItems.push({
			label: m('occupation.items.freelancer', 'fields'),
			value: 'FREELANCER'
		});
	}
	if (!isStaticSubType()) {
		occupationItems.push({
			label: m('occupation.items.public', 'fields'),
			value: 'CIVIL_SERVICE_EMPLOYEE'
		});
	}
	const occupationLimitedItems = getOccupationLimitedItems();

	const showOccupationSince = isCreditOrCreditCardSubType();
	const showOccupationLimited = isCreditSubType() && storeOccupation !== 'PENSINER' && storeOccupation !== 'UNEMPLOYED';
	const showOccupationUntil = showOccupationLimited && storeOccupationLimited === 'Befristet';

	const occupationYearRef = useRef(null);
	const occupationLimitedYearRef = useRef(null);

	useEffect(() => {
		if (!showOccupationSince) {
			dispatch(setOccupationMonth(null));
			dispatch(setOccupationYear(null));
		}
	}, [showOccupationSince]);

	useEffect(() => {
		if (!showOccupationLimited) {
			dispatch(setOccupationLimited(null));
		}
	}, [showOccupationLimited]);

	useEffect(() => {
		if (!showOccupationUntil) {
			dispatch(setOccupationLimitedMonth(null));
			dispatch(setOccupationLimitedYear(null));
		}
	}, [showOccupationUntil]);

	useEffect(() => {
		if (storeOccupationLimited === 'Unbefristet') {
			dispatch(setRuntimeLimit(CREDIT_RUNTIME_STEPS[CREDIT_RUNTIME_STEPS.length - 1]));
			dispatch(setRuntimeNotification(false));
		} else if (storeOccupationLimited === 'Befristet') {
			dispatch(setRuntimeNotification(true));
		}
	}, [storeOccupationLimited]);

	const clearErrors = () => {
		setOccupationError(null);
		setOccupationMonthError(null);
		setOccupationYearError(null);
		setOccupationLimitedError(null);
		setOccupationLimitedMonthError(null);
		setOccupationLimitedYearError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;
		let isOccupationMonthValid = true;
		let isOccupationYearValid = true;
		let isOccupationLimitedMonthValid = true;
		let isOccupationLimitedYearValid = true;
		let isOccupationSinceValid = true;
		let isOccupationUntilValid = true;
		let isOccupationRuntimeLimitValid = true;

		if (isEmpty(storeOccupation)) {
			setOccupationError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}
		if (showOccupationSince) {
			if (isEmpty(storeOccupationMonth)) {
				setOccupationMonthError(m('validation.error.required', 'fields'));
				hasEmptyFields = true;
			} else {
				const hasMonthErr = hasDateMonthError(storeOccupationMonth);
				if (hasMonthErr) {
					setOccupationMonthError(m('validation.error.birthdate.month.outOfRange', 'fields'));
					isOccupationMonthValid = false;
				}
			}
			if (isEmpty(storeOccupationYear)) {
				setOccupationYearError(m('validation.error.required', 'fields'));
				hasEmptyFields = true;
			} else {
				const hasYearErr = hasDateYearError(storeOccupationYear);
				if (hasYearErr) {
					setOccupationYearError(m('validation.error.birthdate.year.invalid', 'fields'));
					isOccupationYearValid = false;
				}
			}
		}
		if (showOccupationLimited) {
			if (storeOccupationLimited === null) {
				setOccupationLimitedError(m('validation.error.required', 'fields'));
				hasEmptyFields = true;
			} else if (showOccupationUntil) {
				if (isEmpty(storeOccupationLimitedMonth)) {
					setOccupationLimitedMonthError(m('validation.error.required', 'fields'));
					hasEmptyFields = true;
				} else {
					const hasMonthErr = hasDateMonthError(storeOccupationLimitedMonth);
					if (hasMonthErr) {
						setOccupationLimitedMonthError(m('validation.error.birthdate.month.outOfRange', 'fields'));
						isOccupationLimitedMonthValid = false;
					}
				}
				if (isEmpty(storeOccupationLimitedYear)) {
					setOccupationLimitedYearError(m('validation.error.required', 'fields'));
					hasEmptyFields = true;
				} else {
					const hasYearErr = hasDateYearError(storeOccupationLimitedYear);
					if (hasYearErr) {
						setOccupationLimitedYearError(m('validation.error.birthdate.year.invalid', 'fields'));
						isOccupationLimitedYearValid = false;
					}
				}
			}
		}

		if (!hasEmptyFields) {
			if (showOccupationSince) {
				if (isOccupationMonthValid && isOccupationYearValid && storeBirthdate) {
					const birthdateArray = storeBirthdate.split('.');
					isOccupationSinceValid = !(hasOccupationSinceError(
						storeOccupationMonth,
						storeOccupationYear,
						birthdateArray[0],
						birthdateArray[1],
						birthdateArray[2]
					));
				} else if (!storeBirthdate) {
					isOccupationSinceValid = false;
					addErrorMessage(
						FormErrorCode.OTHER,
						m('validation.error.occupation.noBirthdate', 'fields')
					);
				}
			}
			if (
				showOccupationUntil &&
				isOccupationLimitedMonthValid &&
				isOccupationLimitedYearValid
			) {
				isOccupationUntilValid = !(hasOccupationUntilError(
					storeOccupationMonth,
					storeOccupationYear,
					storeOccupationLimitedMonth,
					storeOccupationLimitedYear
				));
			}
			if (isOccupationUntilValid) {
				isOccupationRuntimeLimitValid = occupationCheckRunTimeLimit();
			}
		}

		await hasEmptyFieldsError(hasEmptyFields);

		const isValid =
			!hasEmptyFields &&
			isOccupationMonthValid &&
			isOccupationYearValid &&
			isOccupationLimitedMonthValid &&
			isOccupationLimitedYearValid &&
			isOccupationSinceValid &&
			isOccupationUntilValid &&
			isOccupationRuntimeLimitValid;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();
		if (currentFlow === C.FRONTEND.GIRO || currentFlow === C.FRONTEND.DEPOT) {
			goToPage(currentFlow + C.ROUTES.TIN);
		} else {
			goToPage(currentFlow + C.ROUTES.BANK_ACCOUNT);
		}
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const autofill = () => {
		dispatch(setOccupation('EMPLOYED'));
		if (showOccupationSince) {
			dispatch(setOccupationMonth('01'));
			dispatch(setOccupationYear('2010'));
		}
		if (showOccupationLimited) {
			dispatch(setOccupationLimited('Unbefristet'));
		}
		dispatch(setOccupationLimitedMonth(null));
		dispatch(setOccupationLimitedYear(null));
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Title mb>{m('pages.occupation.title', 'global', null, true)}</Title>
			<Text size="l" mb>
				{
					currentFlow === C.FRONTEND.GMK || currentFlow === C.FRONTEND.SPARBRIEF
						? m(`${gmkHyundaiMsgPrefix}occupation.subtitle`, currentFlow)
						: null
				}
			</Text>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={5}
				onPrevButton={() => { goToPage(currentFlow + C.ROUTES.MARITAL_STATUS); }}
			>
				<DropdownInput
					value={storeOccupation}
					setValue={(value) => { dispatch(setOccupation(value)); }}
					items={occupationItems}
					label={m('occupation.label', 'fields')}
					hasError={!!occupationError}
					message={occupationError}
					tabindex={1}
					testId="occupation"
				/>

				{showOccupationSince && (
					<>
						<div className="mt--15" />
						<Text size="l" mb>
							{m('pages.occupation.sinceWhen', 'global')}
						</Text>
						<Row>
							<Col xs={6}>
								<TextInput
									value={storeOccupationMonth}
									setValue={(value) => { dispatch(setOccupationMonth(value)); }}
									label={m('month.label', 'fields')}
									placeholder={m('month.placeholder', 'fields')}
									hasError={!!occupationMonthError}
									message={occupationMonthError}
									maxLength={2}
									nextRefOnMaxLength={occupationYearRef}
									regex={NUMBER_INPUT}
									tabindex={2}
									testId="occupation-month"
								/>
							</Col>
							<Col xs={6}>
								<TextInput
									ref={occupationYearRef}
									value={storeOccupationYear}
									setValue={(value) => { dispatch(setOccupationYear(value)); }}
									label={m('year.label', 'fields')}
									placeholder={m('year.placeholder', 'fields')}
									hasError={!!occupationYearError}
									message={occupationYearError}
									maxLength={4}
									regex={NUMBER_INPUT}
									tabindex={3}
									testId="occupation-year"
								/>
							</Col>
						</Row>
					</>
				)}

				{showOccupationLimited && (
					<>
						<div className="mt--15" />
						<Text size="l" mb>
							{m('pages.occupation.limited', 'global')}
						</Text>
						<RadioButtonInput
							value={storeOccupationLimited}
							setValue={(value) => { dispatch(setOccupationLimited(value)); }}
							items={occupationLimitedItems}
							hasError={!!occupationLimitedError}
							message={occupationLimitedError}
							tabindex={4}
							testId="occupation-limited"
						/>
					</>
				)}

				{showOccupationUntil && (
					<>
						<Text size="l" mb>
							{m('pages.occupation.endOfLimitation', 'global')}
						</Text>
						<Row>
							<Col xs={6}>
								<TextInput
									value={storeOccupationLimitedMonth}
									setValue={(value) => { dispatch(setOccupationLimitedMonth(value)); }}
									label={m('month.label', 'fields')}
									placeholder={m('month.placeholder', 'fields')}
									hasError={!!occupationLimitedMonthError}
									message={occupationLimitedMonthError}
									maxLength={2}
									nextRefOnMaxLength={occupationLimitedYearRef}
									regex={NUMBER_INPUT}
									tabindex={5}
									testId="occupation-limited-month"
								/>
							</Col>
							<Col xs={6}>
								<TextInput
									ref={occupationLimitedYearRef}
									value={storeOccupationLimitedYear}
									setValue={(value) => { dispatch(setOccupationLimitedYear(value)); }}
									label={m('year.label', 'fields')}
									placeholder={m('year.placeholder', 'fields')}
									hasError={!!occupationLimitedYearError}
									message={occupationLimitedYearError}
									maxLength={4}
									regex={NUMBER_INPUT}
									tabindex={6}
									testId="occupation-limited-year"
								/>
							</Col>
						</Row>
					</>
				)}
			</Form>
		</>
	);
};
export default Occupation;
