module.exports = {
	title: 'TagesgeldKonto',
	progressBar: {
		step1: 'Ihre Angaben',
		step2: 'Ihre Person',
		step3: 'Antrag senden',
		subline: {
			letsGo: "<strong>Los geht's:</strong> In nur {minutes} Minuten bis zum Tagesgeldkonto."
		}
	},
	birthdate: {
		subtitle: 'Für die Eröffnung eines Tagesgeldkontos müssen Sie volljährig sein.'
	},
	email: {
		subtitle:
			'Ihre E-Mail-Adresse wird benötigt, damit Sie Ihr Tagesgeldkonto digital eröffnen können. Wir schicken Ihnen anschließend eine E-Mail, mit der Sie Ihren Antrag jederzeit fortführen können.'
	},
	maritalStatus: {
		subtitle:
			'Diese Angabe wird für eine Berechnung steuerfreier Zinserträge benötigt.'
	},
	occupation: {
		subtitle: 'Diese Angabe wird für die Eröffnung des Tagesgeldkontos benötigt.'
	},
	bankAccount: {
		subtitle:
			'Geben Sie hier die IBAN des Girokontos an, auf das zukünftige Überweisungen vom Tagesgeldkonto erfolgen sollen.'
	},
	termsAndConditions: {
		acceptTermsAndConditions: {
			description:
				'Ich habe die vorvertraglichen Informationen, die produktspezifischen Bedingungen und Datenschutzhinweise, die Werbehinweise, den Informationsbogen für den Einleger sowie die AGB und das Preisverzeichnis der Santander erhalten und lege diese meinem Antrag zugrunde.',
			linkText: 'Bedingungen und Informationen zu Santander Geldeinlagen'
		},
		summary:
			'Mit dem Anklicken des Buttons beantragen Sie verbindlich bei Santander, für Sie ein Tagesgeldkonto zu eröffnen und die mit dem Tagesgeldkonto verbundenen Services (z.B. Zugang zum Online Banking) einzurichten. Santander wird Ihren Antrag annehmen, wenn Sie sich vollständig legitimiert haben. Über die Annahme informiert Santander Sie gesondert in Textform.',
		submit: 'Jetzt Antrag senden'
	},
	identification: {
		title: 'Ihr letzter Schritt bis zur Kontoeröffnung: Jetzt identifizieren',
		subtitle:
			'Mit dem Ident Verfahren unseres Partners WebID Solutions können Sie sicher, einfach und bequem Ihre Identität bestätigen.',
		kontoIdent: {
			title: 'Konto-Ident',
			subtitle: 'Identifizieren mit einer Überweisung von 1 Cent',
			list:
				'<li>Ohne Wartezeit</li><li>Jederzeit, egal ob zu Hause oder unterwegs</li><li>Sicher und SSL verschlüsselt</li>',
			toggleButton: 'Wie funktioniert es',
			toggleText:
				'<div class="text--primary text--size--m list"><ol class="mb--15"><li>Fotos von Ausweis und Gesicht machen</li><li>Log-in ins Online-Banking, Überweisung von 1 Cent bestätigen</li><li>TAN-Eingabe</li></ol></div>'
		},
		videoIdent: {
			title: 'Video-Ident',
			subtitle: 'Videogespräch mit einem WebID-Agenten',
			list:
				'<li>Kurze Wartezeit</li><li>Von 7:00 bis 22:00 Uhr von überall möglich</li><li>Sicher und SSL verschlüsselt</li>',
			toggleButton: 'Wie funktioniert es',
			toggleText:
				'<div class="text--primary text--size--m list"><ol class="mb--15"><li>Ausweis bereit halten</li><li>Videocall mit WebID-Agenten durchführen</li><li>TAN-Eingabe</li></ol></div>'
		}
	},
	download: {
		contract: {
			application: 'Kontoeröffnungsantrag herunterladen',
			pdftitle: 'Kontoeröffnungsantrag.pdf'
		}
	},
	addressVerification: {
		title: 'Bitte bestätigen Sie Ihre Adresse.',
		subtitle:
			'Um den Vorgang abzuschließen benötigen wir von Ihnen noch eines der folgenden Dokumente: Meldebescheinigung, oder eine Rechnung für Telefon, Strom oder Gas.',
		submitExtension1: 'Kein Dokument zur Hand?',
		submitExtension2: 'Später hochladen'
	},
	addressSelection: {
		title: 'Welche Adresse ist korrekt?',
		subtitle:
			'Die von Ihnen angegebene Adresse stimmt nicht mit der Adresse aus dem Video-Ident Verfahren überein. Welche Adresse ist aktueller?',
		items: {
			webIdAddress: {
				title: 'Adresse in Ihrem Ausweis',
				subtitle: 'Bereits bestätigt. Direkte Kontoeröffnung.'
			},
			originalAddress: {
				title: 'Angegebene Adresse',
				subtitle: 'Erfordert einen weiteren Nachweis.'
			}
		}
	},
	addressVerificationPostponed: {
		title:
			'Wir haben Ihnen eine E-Mail geschickt. So können Sie jederzeit zurückkehren.',
		subtitle:
			'Wir können Ihren Antrag erst dann bearbeiten, wenn Sie alle benötigten Dokumente vollständig hochgeladen haben.'
	},
	activateGiroAccount: {
		title: 'Sie haben sich erfolgreich identifiziert',
		button: 'Jetzt Konto abschliessen'
	},
	accountSuccess: {
		title: 'Geschafft! Ihr Konto ist angelegt.',
		subtitle:
			'In wenigen Tagen bekommen Sie die Zugangsdaten für das Online Banking zugestellt.',
		contract: 'Tagesgeldkonto Eröffnungsantrag'
	},
	thankYou: {
		title:
			'Vielen Dank für Ihre Anfrage. Wir prüfen Ihren Antrag und melden uns bei Ihnen.',
		hint: '<strong class="text--highlight">Wichtiger Hinweis:</strong> Aufgrund der aktuell hohen Nachfrage kann es zu Verzögerungen in der Antragsbearbeitung kommen – <strong>diese kann 10 Werktage dauern.</strong><br />Keine Sorge, der Aktionszins gilt erst ab Kontoeröffnung. Über die Kontoeröffnung informieren wir Sie separat schriftlich.<br />Vielen Dank vorab für Ihr Verständnis.'
	},
	accountExists: {
		title: 'Vielen Dank für Ihre Anfrage! Mit Ihren Daten existiert bereits ein Konto bei uns.',
		location: 'Bitte kontaktieren Sie eine',
		locationLinkText: 'Santander-Filiale in Ihrer Nähe'
	},
	exitPopup: {
		title: 'Tagesgeldkonto Antrag',
		subhead: 'Gehen Sie noch nicht!',
		description: 'Sicher und gebührenfrei sparen mit flexiblem Zugriff auf Ihr Geld.',
		continueButton: 'Fortfahren'
	},
	hyundai: {
		title: 'PartnerSparen',
		progressBar: {
			subline: {
				letsGo: "<strong>Los geht's:</strong> In nur {minutes} Minuten bis zum PartnerSparen."
			}
		},
		birthdate: {
			subtitle: 'Für die Eröffnung eines PartnerSparen müssen Sie volljährig sein.'
		},
		email: {
			subtitle:
				'Ihre E-Mail-Adresse wird benötigt, damit Sie Ihr PartnerSparen digital eröffnen können. Wir schicken Ihnen anschließend eine E-Mail, mit der Sie Ihren Antrag jederzeit fortführen können.'
		},
		occupation: {
			subtitle: 'Diese Angabe wird für die Eröffnung des PartnerSparen benötigt.'
		},
		bankAccount: {
			subtitle:
				'Geben Sie hier die IBAN des Girokontos an, auf das zukünftige Überweisungen vom PartnerSparen Verrechnungskonto erfolgen sollen.'
		},
		termsAndConditions: {
			acceptTermsAndConditions: {
				linkText: 'Informationen zum und Bedingungen für das PartnerSparen'
			},
			summary:
				'Mit dem Anklicken des Buttons beauftragen Sie das PartnerSparen, welches die Eröffnung eines Verrechnungskontos beinhaltet.',
			summaryHint: 'Zudem wird Ihnen ein Zugang zu MySantander eingerichtet. Dies beinhaltet alles wesentliche zu Ihren Santander Finanzdienstleistungen, das Verwalten aller Ihrer Konten im Online Banking und den Erhalt individueller Informationen über weitere Dienstleistungen von Santander auf Basis Ihres Nutzerverhaltens und Ihrer persönlichen Daten.',
			submit: 'PartnerSparen beauftragen'
		},
		accountSuccess: {
			title: 'Geschafft!',
			contract: 'PartnerSparen Eröffnungsantrag'
		},
		exitPopup: {
			title: 'PartnerSparen Antrag'
		}
	}
};
