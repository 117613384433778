module.exports = {
	title: 'Visa Card',
	progressBar: {
		step1: 'Ihre Angaben',
		step2: 'Ihre Person',
		step3: 'Antrag senden',
		subline: {
			letsGo: "<strong>Los geht's:</strong> In nur {minutes} Minuten bis zur Visa Card."
		}
	},
	birthdate: {
		subtitle: 'Für die Beantragung der Kreditkarte müssen Sie volljährig sein.'
	},
	email: {
		subtitle:
			'Ihre E-Mail-Adresse wird benötigt, damit Sie die Visa Card digital eröffnen können. Wir schicken Ihnen anschließend eine E-Mail, mit der Sie Ihren Antrag jederzeit fortführen können.'
	},
	bankAccount: {
		subtitle:
			'Geben Sie hier die IBAN Ihres Girokontos an, von dem die Zahlungen mit der Visa Karte abgebucht werden sollen.'
	},
	termsAndConditions: {
		acceptTermsAndConditions: {
			description:
				'Ich habe die vorvertraglichen Informationen, die produktspezifischen Bedingungen und Datenschutzhinweise, die Werbehinweise sowie die AGB und das Preisverzeichnis der Santander erhalten und lege diese meinem Antrag zugrunde.',
			linkText: 'Bedingungen und Informationen zu Santander Kreditkarten'
		},
		submit: 'Jetzt Kreditkarte beantragen'
	},
	bcoResponseGreen: {
		title:
			'Fast geschafft!<br/>Nach unserer Prüfung können Sie die Visa Karte jetzt final abschließen.',
		infoText: 'Wir brauchen dafür nur noch Ihren unterschriebenen Vertrag.',
		nextSteps: {
			title: 'Ihre letzten Schritte bis zum Abschluss',
			step1: 'Weiter zu WebID Solutions',
			step2: 'Ausweisdokument fotografieren und hochladen',
			step3: 'Antrag digital unterschreiben'
		},
		submit: 'Jetzt fortführen'
	},
	identification: {
		title: 'Wie möchten Sie sich nun identifizieren und unterschreiben?',
		subtitle:
			'Mit dem Ident Verfahren unseres Partners WebID Solutions können Sie sicher, einfach und bequem Ihre Identität bestätigen und digital unterschreiben.',
		kontoIdent: {
			title: 'Konto-Ident',
			subtitle: 'Identifizieren mit einer Überweisung von 1 Cent',
			list:
				'<li>Ohne Wartezeit</li><li>Jederzeit, egal ob zu Hause oder unterwegs</li><li>Sicher und SSL verschlüsselt</li>',
			toggleButton: 'Wie funktioniert es',
			toggleText:
				'<p class="text text--primary text--size--m mb--5"><strong>Identifizieren</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Fotos von Ausweis und Gesicht machen</li><li>Log-in ins Online-Banking, Überweisung von 1 Cent bestätigen</li><li>TAN-Eingabe</li></ol></div><p class="text text--primary text--size--m mb--5"><strong>Digital unterschreiben:</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Vertrag sichten</li><li>Per TAN-Eingabe unterschreiben</li></ol></div>'
		},
		videoIdent: {
			title: 'Video-Ident',
			subtitle: 'Videogespräch mit einem WebID-Agenten',
			list:
				'<li>Kurze Wartezeit</li><li>Von 7:00 bis 22:00 Uhr von überall möglich</li><li>Sicher und SSL verschlüsselt</li>',
			toggleButton: 'Wie funktioniert es',
			toggleText:
				'<p class="text text--primary text--size--m mb--5"><strong>Identifizieren</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Ausweis bereit halten</li><li>Videocall mit WebID-Agenten durchführen</li><li>TAN-Eingabe</li></ol></div><p class="text text--primary text--size--m mb--5"><strong>Digital unterschreiben:</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Vertrag sichten</li><li>Per TAN-Eingabe unterschreiben</li></ol></div>'
		}
	},
	download: {
		contract: {
			application: 'Kreditkartenantrag herunterladen',
			pdftitle: 'Kreditkarten-Vertrag.pdf'
		}
	},
	addressVerification: {
		title: 'Bitte bestätigen Sie Ihre Adresse.',
		subtitle:
			'Um den Vorgang abzuschließen benötigen wir von Ihnen noch eines der folgenden Dokumente: Meldebescheinigung, oder eine Rechnung für Telefon, Strom oder Gas.',
		submitExtension1: 'Kein Dokument zur Hand?',
		submitExtension2: 'Später hochladen'
	},
	addressSelection: {
		title: 'Welche Adresse ist korrekt?',
		subtitle:
			'Die von Ihnen angegebene Adresse stimmt nicht mit der Adresse aus dem Video-Ident Verfahren überein. Welche Adresse ist aktueller?',
		items: {
			webIdAddress: {
				title: 'Adresse in Ihrem Ausweis',
				subtitle: 'Bereits bestätigt. Direkte Kreditkarteneröffnung.'
			},
			originalAddress: {
				title: 'Angegebene Adresse',
				subtitle: 'Erfordert einen weiteren Nachweis.'
			}
		}
	},
	addressVerificationPostponed: {
		title:
			'Wir haben Ihnen eine E-Mail geschickt. So können Sie jederzeit zurückkehren.',
		subtitle:
			'Wir können Ihren Antrag erst dann bearbeiten, wenn Sie alle benötigten Dokumente vollständig hochgeladen haben.'
	},
	thankYou: {
		title:
			'Vielen Dank für Ihre Anfrage. Wir prüfen Ihren Antrag und melden uns bei Ihnen.'
	},
	activateCreditCardAccount: {
		title: 'Sie haben sich erfolgreich identifiziert',
		button: 'Jetzt Kreditkarte aktivieren'
	},
	creditCardSuccess: {
		title: 'Geschafft! Die Karte und Ihre Login Daten für das Online-Banking sind in wenigen Tagen unterwegs zu Ihnen.',
		subtitle: 'Die Zugangsdaten zu Ihrem Bankkonto dürfen Sie niemals an Dritte weitergeben. Santander Mitarbeiter werden Sie niemals per Anruf, SMS, E-Mail, Brief, Messenger-Diensten, wie z. B. WhatsApp, oder ähnlichem kontaktieren, um die Zugangsdaten von Ihnen zu erfragen.',
		noPan: 'Die Kreditkartennummer erhalten Sie in wenigen Tagen.',
		contract: 'Kreditkarten Vertrag (Kreditkarten-Vertrag.pdf)',
		hint: 'Bitte tätigen Sie ab jetzt keine Zahlungen mehr auf Ihr altes Kartenkonto, da der Übertragungsprozess auf Ihr neues Produkt nun initiiert wird. Dies kann einige Tage in Anspruch nehmen. Mit Erhalt der neuen Karte wird auch die neue Kontoverbindung bekanntgegeben.'
	},
	verification: {
		title: 'Kunden- und Kontonummer eingeben',
		subtitle: 'Bitte geben Sie Ihre Kundennummer und Kontonummer ein, welche wir Ihnen per Post zugesandt haben.'
	},
	thankYouRejected: {
		title: 'Vielen Dank für Ihre Anfrage! Leider ist eine Eröffnung online nicht möglich.'
	}
};
