import React, { useState } from 'react';
import C from 'constants/Constants';
import { NUMBER_INPUT } from 'constants/Regex';
import { C as Autocomplete } from 'constants/enums/Autocomplete';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useValidationUtils } from 'utils/validation/validation';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { usePageUtils } from 'utils/page/page';
import { useItemsUtils } from 'utils/items/items';
import { usePhoneUtils } from 'utils/phone/phone';
import { useRegistrationApi } from 'api/registration';

import { Text, Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import { setCountryCode, setMobileNumber } from 'store/MobileNumber/actions';
import { Col, Row } from 'components/Atoms/Grid';
import { DropdownInput, TextInput } from 'components/Atoms/Form';

const MobileNumber = () => {
	const { getCurrentFlow } = useUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput, isMobileNumberValid } = useValidationUtils();
	const { continueInvalid, continueValid } = useTrackingUtils();
	const { goToPage } = usePageUtils();
	const { getCountryCodeDropdownItems } = useItemsUtils();
	const { formatPhoneNumberForInput } = usePhoneUtils();
	const { postGenerateOtp } = useRegistrationApi();

	const countryCodeItems = getCountryCodeDropdownItems();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeCountryCode = useSelector((state => state.mobileNumber.countryCode));
	const storeMobileNumber = useSelector((state => state.mobileNumber.mobileNumber));

	const [countryCodeError, setCountryCodeError] = useState(null);
	const [mobileNumberError, setMobileNumberError] = useState(null);

	const clearErrors = () => {
		setCountryCodeError(null);
		setMobileNumberError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;
		let isValidMobileNumber = false;

		if (isEmpty(storeCountryCode)) {
			setCountryCodeError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}
		if (isEmpty(storeMobileNumber)) {
			setMobileNumberError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}
		if (!isEmpty(storeCountryCode) && !isEmpty(storeMobileNumber)) {
			isValidMobileNumber = isMobileNumberValid(storeCountryCode, storeMobileNumber, setMobileNumberError);
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields && isValidMobileNumber;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			await postGenerateOtp(onValid, onInvalid);
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();
		goToPage(currentFlow + C.ROUTES.OTP + '/registrierung');
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const autofill = () => {
		dispatch(setCountryCode('49'));
		dispatch(setMobileNumber('163123456'));
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Title mb>{m('pages.mobileNumber.title', 'global')}</Title>
			<Text size="l" mb>{m('pages.mobileNumber.subtitle', 'global')}</Text>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={3}
			>
				<Row>
					<Col xs={12} sm={4}>
						<DropdownInput
							value={storeCountryCode}
							setValue={(value) => { dispatch(setCountryCode(value)); }}
							items={countryCodeItems}
							label={m('countryCode.label', 'fields')}
							hasError={!!countryCodeError}
							message={countryCodeError}
							tabindex={1}
							testId="country-code"
						/>
					</Col>
					<Col xs={12} sm={8}>
						<TextInput
							value={storeMobileNumber}
							setValue={(value) => { dispatch(setMobileNumber(value)); }}
							label={m('mobileNumber.label', 'fields')}
							hasError={!!mobileNumberError}
							message={mobileNumberError ?? (storeCountryCode + '' === '49' ? 'z.B. "17012345678"' : '')}
							maxLength={13}
							regex={NUMBER_INPUT}
							format={formatPhoneNumberForInput}
							autoComplete={Autocomplete.TEL_NATIONAL}
							disabled={!storeCountryCode}
							tabindex={2}
							testId="mobile-phone"
						/>
					</Col>
				</Row>
			</Form>
		</>
	);
};
export default MobileNumber;
