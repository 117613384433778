import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { useScrollUtils } from 'utils/scroll/scroll';
import { useKeycodeUtils } from 'utils/keycodes/keycodes';

import SvgClose from 'components/Atoms/SVG/Icons/SvgClose';

import styles from './Modal.module.scss';

const Modal = (props) => {
	const id = uuid();
	const { getReturn } = useKeycodeUtils();
	const { toggleBodyScroll } = useScrollUtils();

	useEffect(() => {
		ReactModal.setAppElement('body');
	}, []);

	useEffect(() => {
		toggleBodyScroll(props.isOpen);
		if (props.isOpen && props.tabindexClose) {
			setTimeout(() => {
				const closeButtonArray = document.querySelectorAll(
					`[tabindex="${props.tabindexClose}"]`
				);
				if (closeButtonArray.length > 0) {
					closeButtonArray[0].focus();
				}
			}, 1);
		}
	}, [props.isOpen]);

	const handleKeyDown = (event) => {
		if (getReturn(event) && props.onClose) {
			props.onClose(event);
		}
	};

	return (
		<ReactModal
			id={id}
			isOpen={props.isOpen}
			onRequestClose={props.onClose}
			overlayClassName={styles.overlay}
			className={classNames(styles.content, props.smallWidth ? styles.smallWidth : null)}
			closeTimeoutMS={150}
		>
			<div
				className={styles.closeButton}
				onClick={props.onClose}
				onKeyDown={handleKeyDown}
				tabIndex={props.tabindexClose}
				aria-label="Dialog schließen"
			>
				<SvgClose />
			</div>
			{props.children}
		</ReactModal>
	);
};

Modal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	smallWidth: PropTypes.bool,
	tabindexClose: PropTypes.number,
	children: PropTypes.any
};

export default Modal;
