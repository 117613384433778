import React, { useMemo } from 'react';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useNumberUtils } from 'utils/number/number';
import { useCreditUtils } from 'utils/credit/credit';

import { Row, Col } from 'components/Atoms/Grid';
import { Text, Title } from 'components/Atoms/Text';
import ApplicationApprovedCreditDetails from './ApplicationApprovedCreditDetails';

import { useSelector } from 'react-redux';

import styles from './ApplicationApprovedCreditContent.module.scss';

const ApplicationApprovedCreditContent = () => {
	const { getCurrentFlow } = useUtils();
	const { formatMoneyValue } = useNumberUtils();
	const { getProtectMonthlyRateByProtect } = useCreditUtils();

	const currentFlow = getCurrentFlow();

	const storeBcoResults = useSelector((state => state.app.bcoResults));
	const storeProtect = useSelector((state => state.insurance.protect));

	const hasProtect = useMemo(() => {
		return storeBcoResults.protect && storeBcoResults.protectCoverage;
	}, [storeBcoResults.protect, storeBcoResults.protectCoverage]);

	const getRuntime = () => {
		return `${storeBcoResults.runtimeInMonths || 0} Monate`;
	};

	const getMonthlyRateLocal = () => {
		const monthlyRate = storeBcoResults.rateMonthlyAmount ?? 0;
		const protect = storeBcoResults.protect ?? storeProtect;
		const protectMonthlyRate = getProtectMonthlyRateByProtect(protect, storeBcoResults.protectCoverage ?? 200);
		return `${formatMoneyValue(monthlyRate + protectMonthlyRate, true)} €`;
	};

	const getFormattedAmount = () => {
		let amountEuro = '0';
		let amountCent = '00';
		if (storeBcoResults?.creditAmountNet) {
			const amount = storeBcoResults.creditAmountNet;
			let amountComplete = formatMoneyValue(amount, true);
			const amountArray = amountComplete.split(',');
			amountEuro = amountArray[0];
			amountCent = amountArray.length > 1 ? amountArray[1] : '00';
		}

		return `<strong>${amountEuro}</strong>,${amountCent}€`;
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.header} />
			<div className={styles.content}>
				<Row justifyContent='center'>
					<Col xs={12} md={7} lg={8}>
						<div className={styles.contentLeft}>
							<div className={styles.applicationId}>
								<Text size="m" color="light">
									{m('applicationId.label', 'fields')}:{' '}
									<span data-testid={'application-id'}>
										{storeBcoResults.applicationId}
									</span>
								</Text>
							</div>
							<Title>
								{m('bcoResponseGreen.title', currentFlow)}
							</Title>
							<div className="mt--5" />
							<Text>
								{m('bcoResponseGreen.subtitle', currentFlow)}
							</Text>
						</div>
					</Col>
					<Col xs={12} md={5} lg={4}>
						<div className={styles.contentRight}>
							<Row justifyContent="center">
								<Col xs={6} sm={6} md={12} orderXs={1} orderSm={2} orderMd={1}>
									<Text size="m" mb>
										<strong>{m(hasProtect ? 'rate.protectFull' : 'monthlyRate.label', 'fields')}</strong>
										<br />
										<span data-testid={'monthly-rate'}>{getMonthlyRateLocal()}</span>
									</Text>
									<Text size="m" mb>
										<strong>{m('runtime.labelLong', 'fields')}</strong>
										<br />
										<span data-testid={'runtime'}>{getRuntime()}</span>
									</Text>
								</Col>
								<Col xs={6} sm={6} md={12} orderXs={2} orderSm={1} orderMd={2}>
									<Text size="m">
										<strong>
											{m('acceptedAmount.label', 'fields')}:
										</strong>
									</Text>
									<div
										className={styles.amount}
										dangerouslySetInnerHTML={{
											__html: getFormattedAmount()
										}}
										data-testid={'amount'}
									/>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
				<ApplicationApprovedCreditDetails />
			</div>
		</div>
	);
};
export default ApplicationApprovedCreditContent;
