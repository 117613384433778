import React, { useState } from 'react';
import C from 'constants/Constants';
import { NUMBER_INPUT } from 'constants/Regex';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useItemsUtils } from 'utils/items/items';

import { Col, Row } from 'components/Atoms/Grid';
import Form from 'components/Molecules/Form/Form';
import { Text, Title } from 'components/Atoms/Text';
import { TextInput, DropdownInput } from 'components/Atoms/Form';
import SvgEuro from 'components/Atoms/SVG/Icons/SvgEuro';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import { setIncome, setExpenditure, setTypeOfLiving } from 'store/FinancialStatus/actions';

const FinancialStatus = () => {
	const { getCurrentFlow, isCreditCardSubType } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput } = useValidationUtils();
	const { getTypeOfLivingItems } = useItemsUtils();

	const typeOfLivingItems = getTypeOfLivingItems();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeIncome = useSelector((state => state.financialStatus.income));
	const storeExpenditure = useSelector((state => state.financialStatus.expenditure));
	const storeTypeOfLiving = useSelector((state => state.financialStatus.typeOfLiving));

	const [incomeError, setIncomeError] = useState(null);
	const [expenditureError, setExpenditureError] = useState(null);
	const [typeOfLivingError, setTypeOfLivingError] = useState(null);

	const clearErrors = () => {
		setIncomeError(null);
		setExpenditureError(null);
		setTypeOfLivingError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;

		if (isEmpty(storeIncome)) {
			setIncomeError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}
		if (isEmpty(storeExpenditure)) {
			setExpenditureError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}
		if (isEmpty(storeTypeOfLiving)) {
			setTypeOfLivingError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();
		goToPage(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const onPrevButton = () => {
		let nextPage = C.ROUTES.BANK_ACCOUNT;
		if (currentFlow === C.FRONTEND.GIRO) nextPage = C.ROUTES.DISPO;
		if (process.env.REACT_APP_ENABLE_BEST_CARD_LIMIT === 'true' && isCreditCardSubType(null, [C.FRONTEND.VISACARD, C.FRONTEND.BEST_CARD_BASIC, C.FRONTEND.BEST_CARD_SMART])) {
			nextPage = C.ROUTES.CARD_OPTIONS;
		}
		goToPage(currentFlow + nextPage);
	};

	const autofill = () => {
		dispatch(setIncome('5000'));
		dispatch(setExpenditure('500'));
		dispatch(setTypeOfLiving('MIETWOHNUNG'));
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Title mb>{m('pages.financialStatus.title', 'global')}</Title>
			<Text size="l" mb>{m('pages.financialStatus.subtitle', 'global')}</Text>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={4}
				onPrevButton={onPrevButton}
			>
				<Row>
					<Col xs={6}>
						<TextInput
							value={storeIncome || storeIncome === 0 ? storeIncome + '' : null}
							setValue={(value) => { dispatch(setIncome(value)); }}
							label={m('income.label', 'fields')}
							hasError={!!incomeError}
							message={incomeError ?? m('income.placeholder', 'fields')}
							regex={NUMBER_INPUT}
							suffix={<SvgEuro />}
							tabindex={1}
							testId="income"
						/>
					</Col>
					<Col xs={6}>
						<TextInput
							value={storeExpenditure || storeExpenditure === 0 ? storeExpenditure + '' : null}
							setValue={(value) => { dispatch(setExpenditure(value)); }}
							label={m('expenditure.label', 'fields')}
							hasError={!!expenditureError}
							message={expenditureError ?? m('expenditure.placeholder', 'fields')}
							regex={NUMBER_INPUT}
							suffix={<SvgEuro />}
							tabindex={2}
							testId="expenditure"
						/>
					</Col>
				</Row>
				<DropdownInput
					value={storeTypeOfLiving}
					setValue={(value) => { dispatch(setTypeOfLiving(value)); }}
					items={typeOfLivingItems}
					label={m('typeOfLiving.label', 'fields')}
					hasError={!!typeOfLivingError}
					message={typeOfLivingError}
					tabindex={3}
					smallItemBox
					testId="type-of-living"
				/>
			</Form>
		</>
	);
};
export default FinancialStatus;
