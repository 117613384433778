import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './ProgressBarConnector.module.scss';

const ProgressBarConnector = (props) => {
	return (
		<div className={classNames([styles.wrapper, props.disabled ? styles.disabled : null])} />
	);
};

ProgressBarConnector.propTypes = {
	disabled: PropTypes.bool
};

export default ProgressBarConnector;
