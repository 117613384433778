module.exports = {
	title: 'BestGiro',
	progressBar: {
		step1: 'Ihre Angaben',
		step2: 'Ihre Person',
		step3: 'Antrag senden',
		subline: {
			letsGo: "<strong>Los geht's:</strong> In nur {minutes} Minuten bis zum BestGiro Konto."
		}
	},
	birthdate: {
		subtitle: 'Für die Kontoeröffnung müssen Sie volljährig sein.'
	},
	email: {
		subtitle:
			'Ihre E-Mail-Adresse wird benötigt, damit Sie Ihr BestGiro digital eröffnen können. Wir schicken Ihnen anschließend eine E-Mail, mit der Sie Ihren Antrag jederzeit fortführen können.'
	},
	taxInformation: {
		tinTitle: 'Deutsche Steuer-ID (wenn griffbereit)'
	},
	termsAndConditions: {
		acceptTermsAndConditions: {
			description:
				'Ich habe die vorvertraglichen Informationen, die produktspezifischen Bedingungen und Datenschutzhinweise, die Werbehinweise, den Informationsbogen für den Einleger sowie die AGB und das Preisverzeichnis der Santander erhalten und lege diese meinem Antrag zugrunde.',
			linkText: 'Bedingungen und Informationen zu Santander Girokonten'
		},
		summary:
			'Mit dem Anklicken des Buttons "Jetzt Konto eröffnen" beauftragen Sie Santander, für Sie ein Girokonto zu eröffnen und die mit dem Girokonto verbunden Services (z.B. Einrichtung des Zugangs zum Online Banking) einzurichten. Santander wird Ihren Antrag annehmen, wenn Sie sich vollständig legitimiert haben (Identitätsüberprüfung per VideoIdent, POSTIDENT oder einer Santander Filiale). Über die Annahme informiert Santander Sie gesondert in Textform.',
		submit: 'Jetzt Konto eröffnen'
	},
	afterPurchaseGiro: {
		titleWithLimit:
			'Vielen Dank für Ihren Antrag! Wir können Ihnen das BestGiro mit Limit anbieten!',
		titleWithoutLimit:
			'Vielen Dank für Ihren Antrag! Wir können Ihnen das BestGiro anbieten!',
		subtitle:
			'Bitte laden Sie sich den Kontoeröffnungsantrag für Ihre persönlichen Unterlagen herunter.',
		submitButton: 'Zur Identifizierung',
		downloadLink: 'BestGiro Konto Vertrag (BestGiro.pdf)',
		phrase:
			'Jetzt nur noch identifizieren, um den Kontoeröffnungsprozess abzuschließen.',
		error:
			'Der gewünschte Dispositionskredit kann derzeit nicht eingeräumt werden.'
	},
	identification: {
		title: 'Ihr letzter Schritt bis zur Kontoeröffnung: Jetzt identifizieren',
		subtitle:
			'Mit dem Ident Verfahren unseres Partners WebID Solutions können Sie sicher, einfach und bequem Ihre Identität bestätigen.',
		kontoIdent: {
			title: 'Konto-Ident',
			subtitle: 'Identifizieren mit einer Überweisung von 1 Cent',
			list:
				'<li>Ohne Wartezeit</li><li>Jederzeit, egal ob zu Hause oder unterwegs</li><li>Sicher und SSL verschlüsselt</li>',
			toggleButton: 'Wie funktioniert es',
			toggleText:
				'<div class="text--primary text--size--m list"><ol class="mb--15"><li>Fotos von Ausweis und Gesicht machen</li><li>Log-in ins Online-Banking, Überweisung von 1 Cent bestätigen</li><li>TAN-Eingabe</li></ol></div>'
		},
		videoIdent: {
			title: 'Video-Ident',
			subtitle: 'Videogespräch mit einem WebID-Agenten',
			list:
				'<li>Kurze Wartezeit</li><li>Von 7:00 bis 22:00 Uhr von überall möglich</li><li>Sicher und SSL verschlüsselt</li>',
			toggleButton: 'Wie funktioniert es',
			toggleText:
				'<div class="text--primary text--size--m list"><ol class="mb--15"><li>Ausweis bereit halten</li><li>Videocall mit WebID-Agenten durchführen</li><li>TAN-Eingabe</li></ol></div>'
		}
	},
	download: {
		contract: {
			application: 'Kontoeröffnungsantrag herunterladen',
			pdftitle: 'Kontoeröffnungsantrag.pdf'
		}
	},
	addressVerification: {
		title: 'Bitte bestätigen Sie Ihre Adresse.',
		subtitle:
			'Um den Vorgang abzuschließen benötigen wir von Ihnen noch eines der folgenden Dokumente: Meldebescheinigung, oder eine Rechnung für Telefon, Strom oder Gas.',
		submitExtension1: 'Kein Dokument zur Hand?',
		submitExtension2: 'Später hochladen'
	},
	addressSelection: {
		title: 'Welche Adresse ist korrekt?',
		subtitle:
			'Die von Ihnen angegebene Adresse stimmt nicht mit der Adresse aus dem Video-Ident Verfahren überein. Welche Adresse ist aktueller?',
		items: {
			webIdAddress: {
				title: 'Adresse in Ihrem Ausweis',
				subtitle: 'Bereits bestätigt. Direkte Kontoeröffnung.'
			},
			originalAddress: {
				title: 'Angegebene Adresse',
				subtitle: 'Erfordert einen weiteren Nachweis.'
			}
		}
	},
	addressVerificationPostponed: {
		title:
			'Wir haben Ihnen eine E-Mail geschickt. So können Sie jederzeit zurückkehren.',
		subtitle:
			'Wir können Ihren Antrag erst dann bearbeiten, wenn Sie alle benötigten Dokumente vollständig hochgeladen haben.'
	},
	thankYou: {
		title:
			'Vielen Dank für Ihre Anfrage. Wir prüfen Ihren Antrag und melden uns bei Ihnen.'
	},
	thankYou2: {
		title:
			'Vielen Dank für Ihre Anfrage. Leider können wir Ihnen das Girokonto nicht anbieten.'
	},
	serviceHotline: {
		content1:
			'Bei Fragen stehen Ihnen unser Service Team unter der Rufnummer',
		content2: 'oder Ihre nächstgelegene Filiale zur Verfügung.'
	},
	dispo: {
		title: 'Möchten Sie einen Dispositionskredit?',
		subtitle:
			'Erhalten Sie finanzielle Flexibilität durch eine eingeräumte Kontoüberziehung.',
		label: 'Limit',
		infoText:
			'Wünschen Sie einen anderen Dispo? Nach erfolgtem Gehalts – oder Renteneingang haben Sie jederzeit die Möglichkeit Ihren Verfügungsrahmen entsprechend Ihren Bedürfnissen anzupassen.',
		tooltipTitle: 'Dispositionskredit',
		tooltipText:
			'auch als eingeräumte Kontoüberziehung bezeichnet, gibt Ihnen die finanzielle Flexibilität auch bei kurzfristigen wirtschaftlichen Engpässen, jederzeit Liquide zu sein. Wann Sie den in Anspruch genommenen Dispositionskredit zurückzahlen, entscheiden Sie dabei selbst.'
	},
	noLimitPossible: {
		title: 'Jetzt kostenloses Girokonto eröffnen',
		subtitle: '',
		check1: '0 € Kontoführungsgebühr',
		check2: 'Kostenlos Bargeld abheben',
		check3: 'Einfach und schnell kontaktlos bezahlen',
		error: 'Der gewünschte Verfügungsrahmen kann derzeit nicht gewährt werden.',
		submit: 'Jetzt Konto eröffnen',
		infoText:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris dapibus lobortis sem vel auctor. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.'
	},
	activateGiroAccount: {
		title: 'Sie haben sich erfolgreich identifiziert',
		button: 'Jetzt Konto abschliessen'
	},
	accountSuccess: {
		title: 'Geschafft! Ihr Konto ist angelegt.',
		subtitle:
			'Ihre Karte ist unterwegs und in wenigen Tagen bekommen Sie die Login Daten für das Konto. Die Zugangsdaten zu Ihrem Bankkonto dürfen Sie niemals an Dritte weitergeben. Santander Mitarbeiter werden Sie niemals per Anruf, SMS, E-Mail, Brief, Messenger-Diensten, wie z. B. WhatsApp, oder ähnlichem kontaktieren, um die Zugangsdaten von Ihnen zu erfragen.',
		accountChange: {
			title: 'Girokonto kostenlos wechseln ohne Stress! (optional)',
			description:
				'Mit Klick auf <strong>"Kontowechsel starten"</strong> akzeptiere ich die Nutzungsbedingungen der fino run Gmbh und willige in die Verarbeitung meiner personenbezogenen Daten ein. Der ausführliche Einwilligungstext sowie ergänzende Datenschutzinformationen, u.a. zu meinem Widerrufrecht, sind hier einsehbar <a href="https://meinkontowechsel.de/santander/privacy.html" class="link primary" target="_blank">Link</a>.',
			buttonText: 'Kontowechsel starten'
		},
		bestCard: {
			title: 'Kreditkarte zum Preisvorteil sichern!',
			description: 'Sichern Sie sich jetzt Ihren Preisvorteil bei Abschluss einer Santander BestCard – die attraktive Visa Karte - zu Ihrem neuen Santander Girokonto. Die BestCard Smart ist für Sie schon ab 0€ Jahresgebühr erhältlich. Der Abschluss erfolgt schnell und einfach in wenigen Minuten.',
			button: 'Jetzt BestCard beantragen'
		}
	},
	accountExists: {
		title: 'Vielen Dank für Ihre Anfrage! Mit Ihren Daten existiert bereits ein Konto bei uns, daher ist eine weitere Eröffnung online leider nicht möglich.',
		location: 'Bitte kontaktieren Sie eine',
		locationLinkText: 'Santander-Filiale in Ihrer Nähe'
	},
	exitPopup: {
		title: 'BestGiro Antrag',
		subhead: 'Gehen Sie noch nicht!',
		description: 'Für Ihr Girokonto nichts bezahlen: Das ist Leistung.',
		continueButton: 'Fortfahren'
	}
};
