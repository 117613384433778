import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useDocumentsApi } from 'api/documents';

import FinalStepsBox from './FinalStepsBox/FinalStepsBox';
import { Row, Col } from 'components/Atoms/Grid';
import { Text, Title } from 'components/Atoms/Text';

import { useDispatch, useSelector } from 'react-redux';
import {
	setCurrentApplicant,
	setFirstApplicantName,
	setFirstApplicantDocumentUploadStatus,
	setFirstApplicantContractSigned,
	setSecondApplicantName,
	setSecondApplicantDocumentUploadStatus,
	setSecondApplicantContractSigned
} from 'store/Applicant/actions';

import styles from './FinalStepsTwoBorrowers.module.scss';

const FinalStepsTwoBorrowers = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid } = useTrackingUtils();
	const { getBorrowerSwitch } = useDocumentsApi();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeFirstApplicantName = useSelector((state => state.applicant.firstApplicantName));
	const storeFirstApplicantDocumentUploadStatus = useSelector((state => state.applicant.firstApplicantDocumentUploadStatus));
	const storeFirstApplicantContractSigned = useSelector((state => state.applicant.firstApplicantContractSigned));
	const storeSecondApplicantName = useSelector((state => state.applicant.secondApplicantName));
	const storeSecondApplicantDocumentUploadStatus = useSelector((state => state.applicant.secondApplicantDocumentUploadStatus));
	const storeSecondApplicantContractSigned = useSelector((state => state.applicant.secondApplicantContractSigned));

	useEffect(() => {
		loadData();
	}, []);

	const loadData = async () => {
		await getBorrowerSwitch(
			(data) => {
				if (data) {
					if (data.borrowers && data.borrowers.length === 2) {
						if (data.borrowers[0].number === 1) {
							dispatch(setFirstApplicantName(data.borrowers[0].name));
							dispatch(setFirstApplicantDocumentUploadStatus(data.borrowers[0].documentUploadStatus ?? C.DOCUMENTS_UPLOADED.NONE));
							dispatch(setFirstApplicantContractSigned(!!data.borrowers[0].contractSigned));
						}
						if (data.borrowers[1].number === 2) {
							dispatch(setSecondApplicantName(data.borrowers[1].name));
							dispatch(setSecondApplicantDocumentUploadStatus(data.borrowers[1].documentUploadStatus ?? C.DOCUMENTS_UPLOADED.NONE));
							dispatch(setSecondApplicantContractSigned(!!data.borrowers[1].contractSigned));
						}
					}
				}
			}
		);
	};

	const onContinue = (number, documentUploadStatus) => {
		dispatch(setCurrentApplicant(number));
		continueValid();
		goToPage(
			currentFlow +
			(documentUploadStatus !== C.DOCUMENTS_UPLOADED.ALL
				? C.ROUTES.UPLOAD_DOCUMENTS
				: C.ROUTES.IDENTIFICATION)
		);
	};

	const firstApplicantComplete = useMemo(() =>
		storeFirstApplicantName
		&& storeFirstApplicantDocumentUploadStatus === 'ALL_DOCUMENTS_UPLOADED'
		&& storeFirstApplicantContractSigned,
	[
		storeFirstApplicantName,
		storeFirstApplicantDocumentUploadStatus,
		storeFirstApplicantContractSigned
	]);

	const secondApplicantComplete = useMemo(() =>
		storeSecondApplicantName
		&& storeSecondApplicantDocumentUploadStatus === 'ALL_DOCUMENTS_UPLOADED'
		&& storeSecondApplicantContractSigned,
	[
		storeSecondApplicantName,
		storeSecondApplicantDocumentUploadStatus,
		storeSecondApplicantContractSigned
	]);

	const allStepsComplete = useMemo(() =>
			firstApplicantComplete && secondApplicantComplete,
	[firstApplicantComplete, secondApplicantComplete]);

	useEffect(() => {
		if (allStepsComplete) {
			continueValid();
			goToPage(currentFlow + C.ROUTES.LOAN_DONE);
		}
	}, [allStepsComplete]);

	return (
		<>
			<Title mb>{m('finalStepsTwoBorrowers.title', currentFlow)}</Title>
			<Text size="l" mb>{m(`finalStepsTwoBorrowers.${firstApplicantComplete ? 'subtitleSa' : 'subtitle'}`, currentFlow)}</Text>

			<Row>
				{storeFirstApplicantName && (
					<Col xs={12} md={6}>
						<div className={styles.applicantBox}>
							<FinalStepsBox
								name={storeFirstApplicantName}
								documents={storeFirstApplicantDocumentUploadStatus ?? C.DOCUMENTS_UPLOADED.NONE}
								contract={storeFirstApplicantContractSigned}
								onContinue={() => { onContinue(1, storeFirstApplicantDocumentUploadStatus); }}
								tabindex={1}
							/>
						</div>
					</Col>
				)}
				{storeSecondApplicantName && (
					<Col xs={12} md={6}>
						<div className={classNames([
							styles.applicantBox,
							!storeFirstApplicantContractSigned ? styles.applicantBoxDisabled : null
						])}>
							<FinalStepsBox
								name={storeSecondApplicantName}
								documents={storeSecondApplicantDocumentUploadStatus ?? C.DOCUMENTS_UPLOADED.NONE}
								contract={storeSecondApplicantContractSigned}
								onContinue={() => { onContinue(2, storeSecondApplicantDocumentUploadStatus); }}
								tabindex={2}
							/>
						</div>
					</Col>
				)}
			</Row>
		</>
	);
};
export default FinalStepsTwoBorrowers;
