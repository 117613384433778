module.exports = {
	title: 'BestCard Basic',
	progressBar: {
		step1: 'Ihre Angaben',
		step2: 'Ihre Person',
		step3: 'Antrag senden',
		subline: {
			letsGo: "<strong>Los geht's:</strong> In nur {minutes} Minuten bis zu Ihrer BestCard."
		}
	},
	birthdate: {
		subtitle: 'Für die Beantragung der Kreditkarte müssen Sie volljährig sein.'
	},
	email: {
		subtitle:
			'Ihre E-Mail-Adresse wird benötigt, damit Sie die BestCard Basic digital eröffnen können. Wir schicken Ihnen anschließend eine E-Mail, mit der Sie Ihren Antrag jederzeit fortführen können.'
	},
	bankAccount: {
		subtitle:
			'Geben Sie hier die IBAN Ihres Girokontos an, von dem die Zahlungen mit der Kreditkarte abgebucht werden sollen.'
	},
	termsAndConditions: {
		acceptTermsAndConditions: {
			description:
				'Ich habe die vorvertraglichen Informationen, die produktspezifischen Bedingungen und Datenschutzhinweise, die Werbehinweise sowie die AGB und das Preisverzeichnis der Santander erhalten und lege diese meinem Antrag zugrunde.',
			linkText: 'Bedingungen und Informationen zu Santander Kreditkarten'
		},
		submit: 'Jetzt Kreditkarte beantragen'
	},
	bcoResponseGreen: {
		title: 'Sie haben die BestCard Basic erfolgreich beantragt.',
		nextSteps: {
			title: 'Ihre letzten Schritte bis zum Abschluss',
			step1: 'Identifikation',
			step2: 'Vertrag unterschreiben',
			step3: 'BestCard erhalten'
		},
		submit: 'Jetzt fortführen',
		bestGiro: {
			banner: 'Jetzt kostenloses BestGiro Konto in einem Schritt mit beantragen',
			label: 'BestGiro Konto jetzt mit eröffnen',
			list: {
				item1: 'Kontowechselservice nutzen und 75€ Prämie sichern',
				item1Tooltip: {
					title: 'Teilnahmebedingungen:',
					item1: 'Gilt nur für Personen, die in den letzten 12 Monaten kein Girokonto bei Santander hatten (Neukunden).',
					item2: 'Abschluss eines BestGiro, 1|2|3 Giro oder BestGiro für Studenten.',
					item3: 'Sie stimmen zu, dass mindestens 4 Zahlungspartner durch unseren Kontowechselservice informiert und angeschrieben werden (Hierzu zählen Lastschriften für z.B. Strom, Handy, Internet, Miete oder Gutschriften wie Gehalt oder Rente).',
					item4: 'Das eröffnete Girokonto darf nicht in ein Pfändungsschutzkonto umgewandelt werden.',
					item5: 'Auszahlung erfolgt 3 Monate nach erfolgreicher Durchführung des Kontowechselservice zum Monatsende auf Ihr Santander Girokonto.',
					item6: 'Der Willkommensbonus ist steuerfrei, wenn Ihre Einkünfte aus sonstigen Leistungen (inkl. Willkommensbonus) weniger als 256 € im Jahr betragen. Darüber hinausgehende Einkünfte sind von Ihnen an das zuständige Finanzamt zu übermitteln.',
					linkText: 'Echtzeitüberweisung'
				},
				item2: 'Keine Kontoführungsgebühren und kein Mindestgeldeingang'
			},
			submit: {
				withBestGiro: 'Weiter mit BestGiro Konto',
				withoutBestGiro: 'Weiter ohne BestGiro Konto'
			}
		}
	},
	bcoResponseGreenLimit: {
		title: 'Gute Neuigkeiten!<br/>Nach unserer Prüfung können wir Ihnen die BestCard Basic mit einem Verfügungsrahmen von {limit}€ anbieten.',
		hint: 'Ihr gewünschter Verfügungsrahmen in Höhe von {limit}€ kann derzeit leider nicht eingeräumt werden.',
		submit: 'Antrag abschließen'
	},
	giroSuccess: {
		titleWithLimit:
			'Super, wir können Ihnen auch das BestGiro mit Limit anbieten',
		titleWithoutLimit:
			'Geschafft! Ihr BestGiro Konto ist beantragt.',
		subtitle:
			'Bitte laden Sie sich den Kontoeröffnungsantrag für Ihre persönlichen Unterlagen herunter.',
		submitButton: 'Jetzt identifizieren',
		downloadLink: 'BestGiro Konto Vertrag (BestGiro.pdf)',
		error:
			'Der gewünschte Dispositionskredit kann derzeit nicht eingeräumt werden.',
		next: {
			title: 'Nur noch zwei Schritte bis zur Aktivierung:',
			list: {
				item1: 'Identifizieren Sie sich für Ihre BestCard und Ihr BestGiro Konto',
				item2: 'Unterschreiben Sie für Ihre BestCard'
			}
		}
	},
	giroFailed: {
		title: 'Wir können Ihnen das BestGiro Konto leider nicht mit anbieten.',
		description: 'Fahren Sie nun final mit den letzten Schritten der BestCard fort.',
		submit: 'Jetzt identifizieren'
	},
	identification: {
		subtitle:
			'Mit dem Ident Verfahren unseres Partners WebID Solutions können Sie sicher, einfach und bequem Ihre Identität bestätigen und digital unterschreiben.',
		kontoIdent: {
			title: 'Konto-Ident',
			subtitle: 'Identifizieren mit einer Überweisung von 1 Cent',
			list:
				'<li>Ohne Wartezeit</li><li>Jederzeit, egal ob zu Hause oder unterwegs</li><li>Sicher und SSL verschlüsselt</li>',
			toggleButton: 'Wie funktioniert es',
			toggleText:
				'<p class="text text--primary text--size--m mb--5"><strong>Identifizieren</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Fotos von Ausweis und Gesicht machen</li><li>Log-in ins Online-Banking, Überweisung von 1 Cent bestätigen</li><li>TAN-Eingabe</li></ol></div><p class="text text--primary text--size--m mb--5"><strong>Digital unterschreiben:</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Vertrag sichten</li><li>Per TAN-Eingabe unterschreiben</li></ol></div>'
		},
		videoIdent: {
			title: 'Video-Ident',
			subtitle: 'Videogespräch mit einem WebID-Agenten',
			list:
				'<li>Kurze Wartezeit</li><li>Von 7:00 bis 22:00 Uhr von überall möglich</li><li>Sicher und SSL verschlüsselt</li>',
			toggleButton: 'Wie funktioniert es',
			toggleText:
				'<p class="text text--primary text--size--m mb--5"><strong>Identifizieren</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Ausweis bereit halten</li><li>Videocall mit WebID-Agenten durchführen</li><li>TAN-Eingabe</li></ol></div><p class="text text--primary text--size--m mb--5"><strong>Digital unterschreiben:</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Vertrag sichten</li><li>Per TAN-Eingabe unterschreiben</li></ol></div>'
		}
	},
	download: {
		contract: {
			application: 'Kreditkartenantrag herunterladen',
			pdftitle: 'Kreditkarten-Vertrag.pdf'
		}
	},
	addressVerification: {
		title: 'Bitte bestätigen Sie Ihre Adresse.',
		subtitle:
			'Um den Vorgang abzuschließen benötigen wir von Ihnen noch eines der folgenden Dokumente: Meldebescheinigung, oder eine Rechnung für Telefon, Strom oder Gas.',
		submitExtension1: 'Kein Dokument zur Hand?',
		submitExtension2: 'Später hochladen'
	},
	addressSelection: {
		title: 'Welche Adresse ist korrekt?',
		subtitle:
			'Die von Ihnen angegebene Adresse stimmt nicht mit der Adresse aus dem Video-Ident Verfahren überein. Welche Adresse ist aktueller?',
		items: {
			webIdAddress: {
				title: 'Adresse in Ihrem Ausweis',
				subtitle: 'Bereits bestätigt. Direkte Kreditkarteneröffnung.'
			},
			originalAddress: {
				title: 'Angegebene Adresse',
				subtitle: 'Erfordert einen weiteren Nachweis.'
			}
		}
	},
	addressVerificationPostponed: {
		title:
			'Wir haben Ihnen eine E-Mail geschickt. So können Sie jederzeit zurückkehren.',
		subtitle:
			'Wir können Ihren Antrag erst dann bearbeiten, wenn Sie alle benötigten Dokumente vollständig hochgeladen haben.'
	},
	thankYou: {
		title:
			'Vielen Dank für Ihre Anfrage. Wir prüfen Ihren Antrag und melden uns bei Ihnen.'
	},
	activateCreditCardAccount: {
		title: 'Sie haben sich erfolgreich identifiziert',
		button: 'Jetzt Kreditkarte aktivieren'
	},
	creditCardSuccess: {
		title: 'Geschafft! Die Karte und Ihre Login Daten für das Online-Banking sind in wenigen Tagen unterwegs zu Ihnen.',
		subtitle: 'Die Zugangsdaten zu Ihrem Bankkonto dürfen Sie niemals an Dritte weitergeben. Santander Mitarbeiter werden Sie niemals per Anruf, SMS, E-Mail, Brief, Messenger-Diensten, wie z. B. WhatsApp, oder ähnlichem kontaktieren, um die Zugangsdaten von Ihnen zu erfragen.',
		noPan: 'Die Kreditkartennummer erhalten Sie in wenigen Tagen.',
		noIban: 'Ihre IBAN erhalten Sie in wenigen Tagen.',
		hint: {
			title: 'Santander BestCard Basic empfehlen und 20€ sichern!',
			description: 'Überzeugen Sie Ihre Freunde und Verwandten von den Vorteilen der Santander BestCard Basic und sichern Sie sich über unseren Kooperationspartner "Aklamio" Ihre Geldprämie.',
			button: 'Zum Empfehlungsportal'
		}
	},
	exitPopup: {
		title: 'BestCard Basic Antrag',
		subhead: 'Gehen Sie noch nicht!',
		description: 'Einfach. Fair. Basic. Ihre Kreditkarte ohne Jahresgebühr.',
		continueButton: 'Fortfahren'
	}
};
