import React, { useMemo, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { m } from 'utils/messages/messages';
import { useNumberUtils } from 'utils/number/number';
import { useCreditUtils } from 'utils/credit/credit';

import { Row, Col } from 'components/Atoms/Grid';
import { Text } from 'components/Atoms/Text';
import ToggleLink from 'components/Atoms/Partials/ToggleLink/ToggleLink';
import ProtectTarif from 'components/Atoms/Partials/Values/ProtectTarif';

import { useSelector } from 'react-redux';

import styles from './ApplicationApprovedCreditDetails.module.scss';

const ApplicationApprovedCreditDetails = () => {
	const { formatMoneyValue } = useNumberUtils();
	const { getProtectMonthlyRateByProtect } = useCreditUtils();

	const storeBcoResults = useSelector((state => state.app.bcoResults));
	const storeFirstRateDate = useSelector((state => state.app.firstRateDate));
	const storeProtect = useSelector((state => state.insurance.protect));

	const [detailsAreOpen, setDetailsAreOpen] = useState(false);
	const [insuranceDetailsAreOpen, setInsuranceDetailsAreOpen] = useState(false);

	const getMonthlyRate = () => {
		const monthlyRate = storeBcoResults.rateMonthlyAmount ?? 0;
		return formatMoneyValue(monthlyRate, true) + ' €';
	};

	const getEffectiveYearlyInterest = () => {
		let yearlyInterest = 0;
		if (
			storeBcoResults.effectiveAnnualInterestRate ||
			storeBcoResults.effectiveAnnualInterestRate === 0
		) {
			yearlyInterest = storeBcoResults.effectiveAnnualInterestRate;
		}
		return formatMoneyValue(yearlyInterest, true) + ' %';
	};

	const getFixedBorrowingRate = () => {
		let fixedBorrowingRate = 0;
		if (
			storeBcoResults.fixedBorrowingRate ||
			storeBcoResults.fixedBorrowingRate === 0
		) {
			fixedBorrowingRate = storeBcoResults.fixedBorrowingRate;
		}
		return formatMoneyValue(fixedBorrowingRate, true) + ' %';
	};

	const getFirstRateDate = () =>
		storeFirstRateDate
			? moment(storeFirstRateDate, 'DD.MM.YYYY').format('DD.MM.YYYY')
			: null;

	const getInterest = () => {
		let interest = 0;
		if (
			storeBcoResults.interest ||
			storeBcoResults.interest === 0
		) {
			interest = storeBcoResults.interest;
		}
		return `${formatMoneyValue(interest, true)} €`;
	};

	const hasProtect = useMemo(() => {
		return storeBcoResults.protect && storeBcoResults.protectCoverage;
	}, [storeBcoResults.protect, storeBcoResults.protectCoverage]);

	const getProtect = () => {
		return storeBcoResults.protect ?? storeProtect;
	};

	const getProtectCoverage = () => {
		return `${formatMoneyValue(storeBcoResults.protectCoverage ?? 200, false)} €`;
	};

	const getProtectMonthlyRate = () => {
		return `${formatMoneyValue(getProtectMonthlyRateByProtect(storeBcoResults.protect ?? storeProtect, storeBcoResults.protectCoverage ?? 200), true)} €`;
	};

	const getNetAmount = () => {
		let netAmount = 0;
		if (
			storeBcoResults.creditAmountNet ||
			storeBcoResults.creditAmountNet === 0
		) {
			netAmount = storeBcoResults.creditAmountNet;
		}
		return `${formatMoneyValue(netAmount, true)} €`;
	};

	const getTotalAmount = () => {
		let totalAmount = 0;
		if (
			storeBcoResults.creditAmountTotal ||
			storeBcoResults.creditAmountTotal === 0
		) {
			totalAmount = storeBcoResults.creditAmountTotal;
		}
		return `${formatMoneyValue(totalAmount, true)} €`;
	};

	return (
		<>
			<div className={styles.moreDetailsLink}>
				<ToggleLink
					active={detailsAreOpen}
					setActive={setDetailsAreOpen}
					size="l"
				>
					{detailsAreOpen
						? m('lessCreditDetails', 'fields')
						: m('moreCreditDetails', 'fields')}
				</ToggleLink>
			</div>
			<div
				className={classNames([
					styles.moreDetailsWrapper,
					detailsAreOpen ? styles.moreDetailsWrapperActive : null
				])}
			>
				<Row>
					<Col xs={12} md={6}>
						<div className={styles.table}>
							{hasProtect && (
								<div className={styles.tableRow}>
									<div
										className={classNames([
											styles.tableCell,
											styles.tableCellLabel
										])}
									>
										<Text size="s"><strong>{m('monthlyRate.labelLong', 'fields')}:</strong></Text>
									</div>
									<div
										className={classNames([
											styles.tableCell,
											styles.tableCellValue
										])}
										data-testid={'monthly-rate'}
									>
										<Text size="s">{getMonthlyRate()}</Text>
									</div>
								</div>
							)}

							<div className={styles.tableRow}>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellLabel
									])}
								>
									<Text size="s"><strong>{m('firstRate.date', 'fields')}:</strong></Text>
								</div>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellValue
									])}
									data-testid={'first-rate-date'}
								>
									<Text size="s">{getFirstRateDate()}</Text>
								</div>
							</div>

							<div className={styles.tableRow}>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellLabel
									])}
								>
									<Text size="s"><strong>{m('netAmount.label', 'fields')}:</strong></Text>
								</div>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellValue
									])}
									data-testid={'net-amount'}
								>
									<Text size="s">{getNetAmount()}</Text>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={12} md={6}>
						<div className={styles.table}>
							<div className={styles.tableRow}>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellLabel
									])}
								>
									<Text size="s"><strong>{m('effectiveYearlyInterest.label', 'fields')}:</strong></Text>
								</div>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellValue
									])}
									data-testid={'effective-yearly-interest'}
								>
									<Text size="s">{getEffectiveYearlyInterest()}</Text>
								</div>
							</div>

							<div className={styles.tableRow}>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellLabel
									])}
								>
									<Text size="s"><strong>{m('fixedBorrowingRate.label', 'fields')}:</strong></Text>
								</div>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellValue
									])}
									data-testid={'fixed-borrowing-rate'}
								>
									<Text size="s">{getFixedBorrowingRate()}</Text>
								</div>
							</div>

							<div className={styles.tableRow}>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellLabel
									])}
								>
									<Text size="s"><strong>{m('interest.label', 'fields')}:</strong></Text>
								</div>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellValue
									])}
									data-testid={'interest'}
								>
									<Text size="s">{getInterest()}</Text>
								</div>
							</div>

							<div className={styles.tableRow}>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellLabel
									])}
								>
									<Text size="s"><strong>{m('totalAmount.label', 'fields')}:</strong></Text>
								</div>
								<div
									className={classNames([
										styles.tableCell,
										styles.tableCellValue
									])}
									data-testid={'total-amount'}
								>
									<Text size="s">{getTotalAmount()}</Text>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
			{hasProtect && (
				<>
					<div className={styles.moreDetailsLink}>
						<ToggleLink
							active={insuranceDetailsAreOpen}
							setActive={setInsuranceDetailsAreOpen}
							size="l"
						>
							{detailsAreOpen
								? m('lessInsuranceDetails', 'fields')
								: m('moreInsuranceDetails', 'fields')}
						</ToggleLink>
					</div>
					<div
						className={classNames([
							styles.moreDetailsWrapper,
							insuranceDetailsAreOpen ? styles.moreDetailsWrapperActive : null
						])}
					>
						<Row>
							<Col xs={12} md={6}>
								<div className={styles.tableRow}>
									<div
										className={classNames([
											styles.tableCell,
											styles.tableCellLabel
										])}
									>
										<Text size="s"><strong>{m('protect.tarif.label', 'fields')}:</strong></Text>
									</div>
									<div
										className={classNames([
											styles.tableCell,
											styles.tableCellValue
										])}
										data-testid={'protect-tarif'}
									>
										<Text size="s"><ProtectTarif customProtect={getProtect()} /></Text>
									</div>
								</div>

								<div className={styles.tableRow}>
									<div
										className={classNames([
											styles.tableCell,
											styles.tableCellLabel
										])}
									>
										<Text size="s"><strong>{m('protect.monthlyRate.label', 'fields')}:</strong></Text>
									</div>
									<div
										className={classNames([
											styles.tableCell,
											styles.tableCellValue
										])}
										data-testid={'protect-monthly-rate'}
									>
										<Text size="s">{getProtectMonthlyRate()}</Text>
									</div>
								</div>
							</Col>
							<Col xs={12} md={6}>
								<div className={styles.tableRow}>
									<div
										className={classNames([
											styles.tableCell,
											styles.tableCellLabel
										])}
									>
										<Text size="s"><strong>{m('protect.coverage.label', 'fields')}:</strong></Text>
									</div>
									<div
										className={classNames([
											styles.tableCell,
											styles.tableCellValue
										])}
										data-testid={'protect-coverage'}
									>
										<Text size="s">{getProtectCoverage()}</Text>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</>
			)}
		</>
	);
};
export default ApplicationApprovedCreditDetails;
