import React from 'react';

const SvgReload = () => {
	const width = 24;
	const height = 24;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path d="m14.54,2.18l.05.08c.14.24.06.54-.18.68l-1.79,1.03c4.68.32,8.38,4.23,8.38,9s-4.03,9.02-9,9.02S3,17.96,3,12.98c0-2.79,1.26-5.29,3.25-6.94l.28-.22c.08-.05.17-.08.27-.08.28,0,.5.22.5.5,0,.13-.05.26-.14.35l-.07.06c-1.88,1.47-3.09,3.76-3.09,6.33,0,4.43,3.58,8.02,8,8.02s8-3.59,8-8.02-3.49-7.92-7.82-8.02l2.12,1.49c.2.14.27.4.17.62l-.05.08c-.14.2-.4.27-.62.17l-.08-.05-3.28-2.3-.04-.03h0l-.1-.1h0l-.05-.07c-.14-.24-.06-.54.18-.68l3.46-2.01c.21-.12.48-.07.63.11Z" />
		</svg>
	);
};
export default SvgReload;
