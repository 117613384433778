import { SET_PROTECT, SET_PROTECT_RUNTIME, SET_PROTECT_COVERAGE } from './actions';

export const defaultState = {
	protect: null,
	protectRuntime: 60,
	protectCoverage: null
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case SET_PROTECT:
			return { ...state, protect: action.payload };
		case SET_PROTECT_RUNTIME:
			return { ...state, protectRuntime: action.payload };
		case SET_PROTECT_COVERAGE:
			return { ...state, protectCoverage: action.payload };
		default:
			return state;
	}
};

export default reducers;
