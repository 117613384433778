import { findIndex, clone } from 'lodash';
import moment from 'moment';
import { C as FormErrorCode } from 'constants/enums/FormErrorCode';
import { IS_EMAIL } from 'constants/Regex';
import { m } from 'utils/messages/messages';
import { useIbanUtils } from 'utils/iban/iban';
import { useDateUtils } from 'utils/date/date';
import { usePhoneUtils } from 'utils/phone/phone';

import { useDispatch, useSelector } from 'react-redux';
import { setErrorMessages, setSuccessMessages } from 'store/Form/actions';

export const useValidationUtils = () => {
	const { getMaxDaysOfMonth, getLastDayOfMonth } = useDateUtils();
	const { hasGermanPhonePrefix } = usePhoneUtils();
	const { isIbanValid } = useIbanUtils();

	const dispatch = useDispatch();
	const storeErrorMessages = useSelector((state => state.form.errorMessages));
	const storeSuccessMessages = useSelector((state => state.form.successMessages));

	const isEmpty = (value, zeroIsTrue = false) => {
		if (value === undefined || value === null) {
			return true;
		}
		if (typeof value === 'object') {
			return Object.keys(value).length === 0;
		} else {
			if (zeroIsTrue) {
				return value !== 0 && value !== '0' && (!value || (value && value.length < 1));
			} else {
				return !value || (value && value.length < 1);
			}
		}
	};

	const clear = () => {
		clearSuccesses();
		clearErrors();
	};

	const clearSuccesses = () => {
		if (storeSuccessMessages.length > 0) {
			dispatch(setSuccessMessages([]));
		}
	};

	const hasSuccessMessage = (code) => {
		const index = findIndex(storeSuccessMessages, (message) => {
			return message.code === code;
		});
		return index !== -1;
	};

	const addSuccessMessage = (code, successMessage) => {
		if (!hasSuccessMessage(code)) {
			const messages = clone(storeSuccessMessages);
			messages.push({ code: code, message: successMessage });
			dispatch(setSuccessMessages(messages));
		}
	};

	const clearErrors = () => {
		if (storeErrorMessages.length > 0) {
			dispatch(setErrorMessages([]));
		}
	};

	const hasErrorMessage = (code) => {
		const index = findIndex(storeErrorMessages, (message) => {
			return message.code === code;
		});
		return index !== -1;
	};

	const addErrorMessage = (code, errorMessage) => {
		if (!hasErrorMessage(code)) {
			const messages = clone(storeErrorMessages);
			messages.push({ code: code, message: errorMessage });
			dispatch(setErrorMessages(messages));
		}
	};

	const hasEmptyFieldsError = (hasEmptyFields) => {
		if (hasEmptyFields) {
			addErrorMessage(FormErrorCode.EMPTY_FIELDS, m('validation.error.emptyFields', 'fields'));
			return true;
		} else {
			return false;
		}
	};

	const showDefaultConnectionError = () => {
		addErrorMessage(FormErrorCode.API_CONNECTION, m('validation.error.connection', 'fields'));
	};

	const showDefaultMissingDataError = () => {
		addErrorMessage(FormErrorCode.API_MISSING_DATA, m('validation.error.missingData', 'fields'));
	};

	const focusFirstErrorInput = () => {
		const errorInputs = document.querySelectorAll('.input--error');
		if (errorInputs.length > 0) {
			errorInputs[0].focus();
		}
	};

	const isAllUpperOrLowercase = (string) => {
		if (string && string.length > 0) {
			return (
				string.trim() === string.trim().toUpperCase() ||
				string.trim() === string.trim().toLowerCase()
			);
		} else {
			return false;
		}
	};

	const hasAllUpperOrLowercaseError = (string) => {
		if (isAllUpperOrLowercase(string)) {
			addErrorMessage(
				FormErrorCode.INVALID_FIELDS,
				m('validation.error.hasInvalidFields', 'fields')
			);
			return true;
		} else {
			return false;
		}
	};

	const hasCompanyInNameError = (lastName) => {
		let isValid = true;
		const companyNames = ['AG', 'GmbH', 'KG', 'EK', 'GbR', 'UG', 'OHG'];
		const match = companyNames.find((company) => {
			return lastName.includes(company);
		});
		if (match !== undefined) {
			isValid = false;
		}
		if (!isValid) {
			addErrorMessage(
				FormErrorCode.INVALID_FIELDS,
				m('validation.error.hasInvalidFields', 'fields')
			);
			return true;
		} else {
			return false;
		}
	};

	const hasDateDayError = (day, month) => {
		const maxDays = getMaxDaysOfMonth(parseInt(month));
		const dayInt = parseInt(day);
		return dayInt < 1 || day.length > 2 || dayInt > maxDays;
	};

	const hasDateMonthError = (month) => {
		const monthInt = parseInt(month);
		return monthInt < 1 || month.length > 2 || monthInt > 12;
	};

	const hasDateYearError = (year) => {
		return year.length !== 4;
	};

	const isYearLessThan1900Error = (year) => {
		return parseInt(year) < 1900;
	};

	const isDateUnder18Error = (day, month, year) => {
		const birthdate = moment(
			year +
			'-' +
			(month.length === 1 ? '0' + month : month) +
			'-' +
			(day.length === 1 ? '0' + day : day),
			'YYYY-MM-DD'
		);
		const years = moment().diff(birthdate, 'years');
		return years < 18;
	};

	const isBirthdateValid = (birthdate, setBirthdateError) => {
		let isValidBirthdate = true;
		const birthdateArray = birthdate.split('.');
		if (
			birthdateArray.length === 3 &&
			birthdateArray[0].length === 2 &&
			birthdateArray[1].length === 2 &&
			birthdateArray[2].length === 4
		) {
			const hasDayErr = hasDateDayError(birthdateArray[0], birthdateArray[1]);
			const hasMonthErr = hasDateMonthError(birthdateArray[1]);
			const hasYearErr = hasDateYearError(birthdateArray[2]);
			if (hasDayErr || hasMonthErr || hasYearErr) {
				setBirthdateError(m('validation.error.birthdate.invalid', 'fields'));
				isValidBirthdate = false;
			} else {
				const isUnder18 = isDateUnder18Error(
					birthdateArray[0],
					birthdateArray[1],
					birthdateArray[2]
				);
				if (isUnder18) {
					setBirthdateError(m('validation.error.birthdate.notOver18', 'fields'));
					isValidBirthdate = false;
				} else {
					const yearLessThan1900 = isYearLessThan1900Error(birthdateArray[2]);
					if (yearLessThan1900) {
						setBirthdateError(m('validation.error.birthdate.notYearLessThan1900', 'fields'));
						isValidBirthdate = false;
					}
				}
			}
		} else {
			setBirthdateError(m('validation.error.birthdate.invalid', 'fields'));
			isValidBirthdate = false;
		}

		if (!isValidBirthdate) {
			addErrorMessage(
				FormErrorCode.INVALID_FIELDS,
				m('validation.error.hasInvalidFields', 'fields')
			);
		}

		return isValidBirthdate;
	};

	const hasEmailError = (email) => {
		if (!IS_EMAIL.test(email)) {
			addErrorMessage(
				FormErrorCode.INVALID_FIELDS,
				m('validation.error.hasInvalidFields', 'fields')
			);
			return true;
		} else {
			return false;
		}
	};

	const hasMobileNumberTooShortError = (mobileNumber) => {
		return mobileNumber.trim().length < 5;
	};

	const hasGermanPhonePrefixError = (countryCode, mobileNumber) => {
		if (countryCode === '49') {
			return !hasGermanPhonePrefix(mobileNumber);
		} else {
			return false;
		}
	};

	const isMobileNumberValid = (countryCode, mobileNumber, setMobileNumberError) => {
		const hasMobileNumberTooShortErr = hasMobileNumberTooShortError(mobileNumber + '');
		const hasGermanPhonePrefixErr = hasGermanPhonePrefixError(countryCode + '', mobileNumber + '');

		if (hasMobileNumberTooShortErr) {
			setMobileNumberError(m('validation.error.mobileNumber.tooShort', 'fields'));
		} else if (hasGermanPhonePrefixErr) {
			setMobileNumberError(m('validation.error.mobileNumber.noGermanPrefix', 'fields'));
		}

		const isValidMobileNumber = !hasMobileNumberTooShortErr && !hasGermanPhonePrefixErr;
		if (!isValidMobileNumber) {
			addErrorMessage(
				FormErrorCode.INVALID_FIELDS,
				m('validation.error.hasInvalidFields', 'fields')
			);
		}
		return isValidMobileNumber;
	};

	const hasOccupationSinceError = (
		occupationMonth,
		occupationYear,
		day,
		month,
		year
	) => {
		const currentDate = moment();
		const occupationDate = moment(occupationYear + '-' + occupationMonth + '-01', 'YYYY-MM-DD');
		const birthdate =
			year && month && day
				? moment(parseInt(year) + 16 + '-' + month + '-' + day, 'YYYY-MM-DD')
				: moment(moment().year() - 80 + '-01-01', 'YYYY-MM-DD');
		if (occupationDate.isAfter(currentDate)) {
			addErrorMessage(
				FormErrorCode.OCCUPATION_START_IN_THE_FUTURE,
				m('validation.error.occupation.since.startInTheFuture', 'fields')
			);
			return true;
		} else if (birthdate.isAfter(occupationDate)) {
			addErrorMessage(
				FormErrorCode.OCCUPATION_START_BEFORE_BIRTHDATE,
				m('validation.error.occupation.since.startBeforeBirthdate', 'fields')
			);
			return true;
		} else {
			return false;
		}
	};

	const hasOccupationUntilError = (
		occupationMonth,
		occupationYear,
		occupationLimitedMonth,
		occupationLimitedYear
	) => {
		const currentDate = moment();
		const occupationDate = moment(occupationYear + '-' + occupationMonth + '-01', 'YYYY-MM-DD');
		const occupationLimitedDate = moment(
			occupationLimitedYear + '-' + occupationLimitedMonth + '-01',
			'YYYY-MM-DD'
		);
		const occupationLimitedDateEnd = moment(
			occupationLimitedYear +
			'-' +
			occupationLimitedMonth +
			'-' +
			getLastDayOfMonth(occupationLimitedYear, occupationLimitedMonth),
			'YYYY-MM-DD'
		);

		if (occupationLimitedDateEnd.isBefore(currentDate)) {
			addErrorMessage(
				FormErrorCode.OCCUPATION_LIMITED_START_IN_THE_PAST,
				m('validation.error.occupation.until.startInThePast', 'fields')
			);
			return true;
		} else if (occupationLimitedDate.isBefore(occupationDate)) {
			addErrorMessage(
				FormErrorCode.OCCUPATION_LIMITED_START_BEFORE_OCCUPATION_START,
				m('validation.error.occupation.until.startBeforeOccupationStart', 'fields')
			);
			return true;
		} else {
			return false;
		}
	};

	const hasIbanError = (iban) => {
		const isValid = isIbanValid(iban);
		return !isValid;
	};

	return {
		isEmpty,
		clear,
		clearSuccesses,
		hasSuccessMessage,
		addSuccessMessage,
		clearErrors,
		hasErrorMessage,
		addErrorMessage,
		hasEmptyFieldsError,
		showDefaultConnectionError,
		showDefaultMissingDataError,
		focusFirstErrorInput,
		isAllUpperOrLowercase,
		hasAllUpperOrLowercaseError,
		hasCompanyInNameError,
		hasDateDayError,
		hasDateMonthError,
		hasDateYearError,
		isBirthdateValid,
		hasEmailError,
		isMobileNumberValid,
		hasOccupationSinceError,
		hasOccupationUntilError,
		hasIbanError
	};
};
