import React, { useState } from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useLegitimationApi } from 'api/legitimation';

import { Row, Col } from 'components/Atoms/Grid';
import { Text, Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import { CheckboxInput } from 'components/Atoms/Form';
import SvgIllustrationPostOffice from 'components/Atoms/SVG/Illustrations/SvgIllustrationPostOffice';

import styles from './DigitalPostidentConfirmation.module.scss';
import Divider from 'components/Atoms/Divider/Divider';

const DigitalPostidentConfirmation = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { hasEmptyFieldsError, focusFirstErrorInput } = useValidationUtils();
	const { postRegisterWithPostident } = useLegitimationApi();

	const currentFlow = getCurrentFlow();

	const [digitalPostidentConfirmation, setDigitalPostidentConfirmation] = useState(false);

	const [digitalPostidentConfirmationError, setDigitalPostidentConfirmationError] = useState(null);

	const clearErrors = () => {
		setDigitalPostidentConfirmationError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;

		if (!digitalPostidentConfirmation) {
			setDigitalPostidentConfirmationError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			await postRegisterWithPostident(
				(data) => {
					continueValid();
					if (data.providerLegitimationUrl1) {
						window.location.href = data.providerLegitimationUrl1;
					} else {
						goToPage(currentFlow + C.ROUTES.DIGITAL_POSTIDENT);
					}
				},
				() => {
					continueInvalid();
				}
			);
		} else {
			continueInvalid();
		}
	};

	return (
		<>
			<Row>
				<Col xs={12} md={6}>
					<div className={styles.iconGroup}>
						<SvgIllustrationPostOffice />
					</div>
				</Col>
				<Col xs={12} md={6}>
					<div className={styles.textGroup}>
						<Title mb>
							{m('pages.digitalPostidentConfirmation.title', 'global')}
						</Title>
						<Text size="l" mb>
							{m('pages.digitalPostidentConfirmation.subtitle', 'global')}
						</Text>
					</div>
				</Col>
			</Row>

			<Divider />

			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={2}
				onPrevButton={() => { goToPage(currentFlow + C.ROUTES.IDENTIFICATION); }}
			>
				<CheckboxInput
					value={digitalPostidentConfirmation}
					setValue={setDigitalPostidentConfirmation}
					label={m('pages.digitalPostidentConfirmation.checkbox', 'global')}
					hasError={!!digitalPostidentConfirmationError}
					message={digitalPostidentConfirmationError}
					tabindex={1}
					testId="digital-postident-confirmation"
				/>
			</Form>
		</>
	);
};
export default DigitalPostidentConfirmation;
