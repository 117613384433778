import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './Ripple.module.scss';

const useDebouncedRippleCleanUp = (rippleCount, cleanUpFunction) => {
	useLayoutEffect(() => {
		let bounce = null;
		if (rippleCount > 0) {
			clearTimeout(bounce);

			bounce = setTimeout(() => {
				cleanUpFunction();
				clearTimeout(bounce);
			}, 2000 * 4);
		}

		return () => clearTimeout(bounce);
	}, [rippleCount, cleanUpFunction]);
};

const Ripple = (props) => {
	const [rippleArray, setRippleArray] = useState([]);

	useDebouncedRippleCleanUp(rippleArray.length, () => {
		setRippleArray([]);
	});

	useEffect(() => {
		if (!props.rippleRef?.current) return;

		const handleMouseDown = (event) => {
			const rippleContainer = props.rippleRef.current.getBoundingClientRect();
			const size = Math.max(rippleContainer.width, rippleContainer.height);
			const x = event.clientX - rippleContainer.left - size / 2;
			const y = event.clientY - rippleContainer.top - size / 2;
			const newRipple = { x, y, size };

			setRippleArray((prevState) => [...prevState, newRipple]);
		};

		const element = props.rippleRef.current;
		element.addEventListener('mousedown', handleMouseDown);

		return () => {
			element.removeEventListener('mousedown', handleMouseDown);
		};
	}, [props.rippleRef]);

	return (
		<div className={styles.ripple}>
			{rippleArray.length > 0 &&
				rippleArray.map((ripple, index) => {
					return (
						<span
							key={'ripple-' + index}
							style={{
								top: ripple.y,
								left: ripple.x,
								width: ripple.size,
								height: ripple.size,
								backgroundColor: props.color
							}}
						/>
					);
				})}
		</div>
	);
};

Ripple.propTypes = {
	color: PropTypes.string,
	rippleRef: PropTypes.any
};

export default Ripple;
