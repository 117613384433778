import React, { useState, useEffect, useMemo } from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useNumberUtils } from 'utils/number/number';

import { Col, Row } from 'components/Atoms/Grid';
import { Text, Title, Link } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import NextSteps from 'components/Atoms/Partials/NextSteps/NextSteps';
import Modal from 'components/Atoms/Modal/Modal';
import Button from 'components/Atoms/Button/Button';
import DownloadContracts from 'components/Atoms/Partials/DownloadContracts/DownloadContracts';
import ApplicationApprovedCreditContent from './ApplicationApprovedCreditContent';
import SvgIllustrationInvestment from 'components/Atoms/SVG/Illustrations/SvgIllustrationInvestment';

import { useSelector } from 'react-redux';

import styles from './ApplicationApprovedCredit.module.scss';
import Divider from 'components/Atoms/Divider/Divider';
import { useCreditUtils } from 'utils/credit/credit';

const ApplicationApprovedCredit = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage, preventHistoryBackFor } = usePageUtils();
	const { continueValid } = useTrackingUtils();
	const { isEmpty } = useValidationUtils();
	const { formatMoneyValue } = useNumberUtils();
	const { getProtectMonthlyRateByProtect } = useCreditUtils();

	const currentFlow = getCurrentFlow();

	const storeHasSecondApplicant = useSelector((state => state.secondApplicant.hasSecondApplicant));
	const storeDacStatus = useSelector((state => state.digitalAccountCheck.status));
	const storeDacTransaction = useSelector((state => state.digitalAccountCheck.transaction));

	const storeHasUpsell = useSelector((state => state.upsell.hasUpsell));
	const storeUpsellAlreadyDone = useSelector((state => state.upsell.upsellAlreadyDone));
	const storeUpsellCreditAmountNet = useSelector((state => state.upsell.creditAmountNet));
	const storeUpsellRateMonthlyAmount = useSelector((state => state.upsell.rateMonthlyAmount));
	const storeProtect = useSelector((state => state.app.bcoResults?.protect));
	const storeProtectCoverage = useSelector((state => state.app.bcoResults?.protectCoverage));

	const [isOpenPopup, setIsOpenPopup] = useState(false);

	useEffect(() => {
		preventHistoryBackFor(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
	}, []);

	useEffect(() => {
		if (storeHasUpsell && !storeUpsellAlreadyDone) {
			setTimeout(() => {
				setIsOpenPopup(true);
			}, 1500);
		}
	}, [storeHasUpsell]);

	const onSubmit = () => {
		continueValid();
		if (!storeHasSecondApplicant && isEmpty(storeDacTransaction)) {
			goToPage(currentFlow + C.ROUTES.FINANCIAL_STATUS_DAC + '/reminder');
		} else if (!isEmpty(storeDacTransaction) && storeDacStatus !== C.DAC_STATUS.GREEN) {
			goToPage(currentFlow + C.ROUTES.UPLOAD_DOCUMENTS);
		} else {
			goToPage(currentFlow + C.ROUTES.IDENTIFICATION);
		}
	};

	const handleUpsellPopupButtonClick = () => {
		goToPage(currentFlow + C.ROUTES.UPSELL);
	};

	const showDocumentUploadSubmitText = useMemo(() => {
		return (!storeHasSecondApplicant && isEmpty(storeDacTransaction))
			|| (!isEmpty(storeDacTransaction) && storeDacStatus !== C.DAC_STATUS.GREEN);
	}, [storeHasSecondApplicant, storeDacTransaction, storeDacStatus]);

	const hasProtect = storeProtect && storeProtectCoverage;

	const getPopupContent = () => {
		return (
			<>
				<Title size="l" mb>
					{m('pages.upsellDownsell.upsell.wantMore', 'global')}
				</Title>
				<Text mb>
					{m('pages.upsellDownsell.upsell.biggerPlans', 'global')}
				</Text>
				<Text mb>
					{m('pages.upsellDownsell.upsell.howAboutThisOffer', 'global')}
				</Text>
				<div className={styles.popUpIcon}>
					<SvgIllustrationInvestment />
				</div>
				<Divider />
				<Row>
					<Col xs={6}>
						<Text size="xl">
							<strong>
								{m('general.creditAmount', 'global')}
							</strong>
						</Text>
					</Col>
					<Col xs={6} textAlign="right">
						<Text size="xl" color="highlight">
							<strong>
								{formatMoneyValue(storeUpsellCreditAmountNet ?? 0, true)} €
							</strong>
						</Text>
					</Col>
				</Row>
				<Row>
					<Col xs={6}>
						<Text>
							{m(hasProtect ? 'rate.protectLong' : 'monthlyRate.label', 'fields')}
						</Text>
					</Col>
					<Col xs={6} textAlign="right">
						<Text>
							<strong>
								{formatMoneyValue((storeUpsellRateMonthlyAmount ?? 0) + (hasProtect ? getProtectMonthlyRateByProtect(storeProtect, storeProtectCoverage) : 0), true)} €
							</strong>
						</Text>
					</Col>
				</Row>
				<Divider noLine noMarginBottom />
				<Row>
					<Col xs={12} textAlign="center">
						<Button onClick={handleUpsellPopupButtonClick}>
							{m('general.moreInfo', 'global')}
						</Button>
					</Col>
				</Row>
			</>
		);
	};

	const getNextSteps = () => {
		const nextSteps = [];
		if (storeDacStatus !== C.DAC_STATUS.GREEN) {
			nextSteps.push(m('bcoResponseGreen.nextSteps.step1', currentFlow));
		}
		nextSteps.push(m('bcoResponseGreen.nextSteps.step2', currentFlow));
		// nextSteps.push(m('bcoResponseGreen.nextSteps.step3', currentFlow));
		return nextSteps;
	};

	return (
		<>
			<ApplicationApprovedCreditContent />
			{storeHasUpsell && !storeUpsellAlreadyDone && (
				<Row>
					<Col xs={12}>
						<div className={styles.toUpsellDownsellLink}>
							<Link
								onClick={() =>
									goToPage(currentFlow + C.ROUTES.UPSELL)
								}
							>
								{m('pages.upsellDownsell.toUpsellDownsell', 'global')}
							</Link>
						</div>
					</Col>
				</Row>
			)}
			<NextSteps
				title={m('bcoResponseGreen.nextSteps.title', currentFlow)}
				steps={getNextSteps()}
				hideUpload={storeDacStatus === C.DAC_STATUS.GREEN}
			/>
			<Form
				onSubmit={onSubmit}
				clearErrors={() => {}}
				submitTabindex={1}
				prevButtonExtension={<DownloadContracts />}
				submitText={m(`bcoResponseGreen.${showDocumentUploadSubmitText ? 'submitDocumentUpload' : 'submitIdentification'}`, currentFlow)}
			/>
			<Modal
				isOpen={isOpenPopup}
				onClose={() => setIsOpenPopup(false)}
				smallWidth
			>
				{getPopupContent()}
			</Modal>
		</>
	);
};
export default ApplicationApprovedCredit;
