import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{fill: props.color ? props.color : null, fillRule: 'evenodd'}}
				d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10S2 17.514 2 12 6.486 2 12 2zm0 1c-4.962 0-9 4.038-9 9s4.038 9 9 9 9-4.038 9-9-4.038-9-9-9zm0 13a.5.5 0 0 1 .492.41l.008.09v1a.5.5 0 0 1-.992.09l-.008-.09v-1a.5.5 0 0 1 .5-.5zM9.75 6.753a3.518 3.518 0 0 1 2.88-.763c1.41.247 2.567 1.404 2.815 2.814a3.523 3.523 0 0 1-1.59 3.599l-.168.098c-.69.38-1.122 1.003-1.18 1.688l-.007.159v.25a.5.5 0 0 1-.992.09l-.008-.09v-.25c0-1.05.564-2.014 1.52-2.614l.185-.109c.952-.524 1.445-1.564 1.254-2.648a2.503 2.503 0 0 0-2.001-2.002 2.492 2.492 0 0 0-2.064.544A2.495 2.495 0 0 0 9.5 9.435a.5.5 0 1 1-1 0c0-1.037.456-2.015 1.25-2.682z"
			/>
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
