import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 12;
	const height = 9;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M0.2788 3.63256C0.650533 3.26201 1.25323 3.26201 1.62496 3.63256L4.31729 6.31628L10.375 0.277908C10.7468 -0.0926363 11.3495 -0.092636 11.7212 0.277908C12.0929 0.648453 12.0929 1.24922 11.7212 1.61977L4.31729 9L0.2788 4.97442C-0.0929333 4.60387 -0.0929333 4.0031 0.2788 3.63256Z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
