import React from 'react';
import { C as Protect } from 'constants/enums/Protect';
import { m } from 'utils/messages/messages';
import { useCreditUtils } from 'utils/credit/credit';
import { useNumberUtils } from 'utils/number/number';

import Col from './Col';
import { RadioInput } from 'components/Atoms/Form';
import SvgProtectRespiteCare from 'components/Atoms/SVG/Icons/Protect/SvgProtectRespiteCare';
import SvgProtectParentalLeave from 'components/Atoms/SVG/Icons/Protect/SvgProtectParentalLeave';
import SvgProtectShortTimeWork from 'components/Atoms/SVG/Icons/Protect/SvgProtectShortTimeWork';
import SvgProtectUnemployment from 'components/Atoms/SVG/Icons/Protect/SvgProtectUnemployment';
import SvgProtectIncapacityForWork from 'components/Atoms/SVG/Icons/Protect/SvgProtectIncapacityForWork';

import { useDispatch, useSelector } from 'react-redux';
import { setProtect } from 'store/Insurance/actions';

import styles from './Table.module.scss';

const Table = () => {
	const { getProtectMonthlyRateByProtect } = useCreditUtils();
	const { formatMoneyValue } = useNumberUtils();

	const dispatch = useDispatch();
	const storeProtect = useSelector((state => state.insurance.protect));

	const setValue = (value) => {
		dispatch(setProtect(value));
	};

	const firstColArray = [
		{ type: 'empty' },
		{
			type: 'spacer',
			content: m('pages.insurance.protect.body.table.spacer.monthlyPayment', 'global')
		},
		{
			type: 'text',
			content: m('pages.insurance.protect.body.table.row1.title', 'global'),
			popup: (
				<div className={styles.popup}>
					<div className={styles.popupHeader}>
						<div className={styles.popupHeaderIcon}>
							<SvgProtectRespiteCare />
						</div>
						<p className={styles.popupHeadline}>{m('pages.insurance.protect.body.table.row1.popup.headline1', 'global')}</p>
					</div>
					<div className={styles.popupText}>{m('pages.insurance.protect.body.table.row1.popup.content1', 'global', null, true)}</div>
					<div className={styles.popupHeader}>
						<div className={styles.popupHeaderIcon}>
							<SvgProtectParentalLeave />
						</div>
						<p className={styles.popupHeadline}>{m('pages.insurance.protect.body.table.row1.popup.headline2', 'global')}</p>
					</div>
					<div className={styles.popupText}>{m('pages.insurance.protect.body.table.row1.popup.content2', 'global', null, true)}</div>
				</div>
			)
		},
		{
			type: 'text',
			content: m('pages.insurance.protect.body.table.row2.title', 'global'),
			popup: (
				<div className={styles.popup}>
					<div className={styles.popupHeader}>
						<div className={styles.popupHeaderIcon}>
							<SvgProtectShortTimeWork />
						</div>
						<p className={styles.popupHeadline}>{m('pages.insurance.protect.body.table.row2.popup.headline1', 'global')}</p>
					</div>
					<div className={styles.popupText}>{m('pages.insurance.protect.body.table.row2.popup.content1', 'global', null, true)}</div>
					<div className={styles.popupHeader}>
						<div className={styles.popupHeaderIcon}>
							<SvgProtectUnemployment />
						</div>
						<p className={styles.popupHeadline}>{m('pages.insurance.protect.body.table.row2.popup.headline2', 'global')}</p>
					</div>
					<div className={styles.popupText}>{m('pages.insurance.protect.body.table.row2.popup.content2', 'global', null, true)}</div>
				</div>
			)
		},
		{
			type: 'text',
			content: m('pages.insurance.protect.body.table.row3.title', 'global'),
			popup: (
				<div className={styles.popup}>
					<div className={styles.popupHeader}>
						<div className={styles.popupHeaderIcon}>
							<SvgProtectIncapacityForWork />
						</div>
						<p className={styles.popupHeadline}>{m('pages.insurance.protect.body.table.row3.popup.headline1', 'global')}</p>
					</div>
					<div className={styles.popupText}>{m('pages.insurance.protect.body.table.row3.popup.content1', 'global', null, true)}</div>
				</div>
			)
		},
		{
			type: 'spacer',
			content: m('pages.insurance.protect.body.table.spacer.oneTimePayment', 'global')
		},
		{
			type: 'text',
			content: m('pages.insurance.protect.body.table.row4.title', 'global'),
			popup: (
				<div className={styles.popup}>
					<div className={styles.popupHeader}>
						<div className={styles.popupHeaderIcon}>
							<SvgProtectParentalLeave />
						</div>
						<p className={styles.popupHeadline}>{m('pages.insurance.protect.body.table.row4.popup.headline1', 'global')}</p>
					</div>
					<div className={styles.popupText}>{m('pages.insurance.protect.body.table.row4.popup.content1', 'global', null, true)}</div>
				</div>
			)
		},
		{ type: 'empty' }
	];

	const standardColArray = [
		{ type: 'headline', content: m('pages.insurance.protect.body.table.standard.title', 'global', null, true) },
		{ type: 'spacer' },
		{ type: 'empty' },
		{ type: 'check' },
		{ type: 'check' },
		{ type: 'spacer' },
		{ type: 'check' },
		{ type: 'select', text: (formatMoneyValue(getProtectMonthlyRateByProtect(Protect.STANDARD), true) ?? 0) + ' €/' + m('month.label', 'fields') }
	];

	const premiumColArray = [
		{ type: 'headline', content: m('pages.insurance.protect.body.table.premium.title', 'global', null, true) },
		{ type: 'spacer' },
		{ type: 'check' },
		{ type: 'check' },
		{ type: 'check' },
		{ type: 'spacer' },
		{ type: 'check' },
		{ type: 'select', text: (formatMoneyValue(getProtectMonthlyRateByProtect(Protect.PREMIUM), true) ?? 0) + ' €/' + m('month.label', 'fields') }
	];

	const basicColArray = [
		{ type: 'headline', content: m('pages.insurance.protect.body.table.basic.title', 'global', null, true) },
		{ type: 'spacer' },
		{ type: 'empty' },
		{ type: 'empty' },
		{ type: 'check' },
		{ type: 'spacer' },
		{ type: 'check' },
		{ type: 'select', text: (formatMoneyValue(getProtectMonthlyRateByProtect(Protect.BASIC), true) ?? 0) + ' €/' + m('month.label', 'fields') }
	];

	return (
		<>
			<div className={styles.table}>
				<Col rows={firstColArray} isFirst />
				<Col
					rows={standardColArray}
					value={Protect.STANDARD}
					onClick={setValue}
					testId='standard-protection'
					selected={storeProtect === Protect.STANDARD}
					tabindex={2}
				/>
				<Col
					rows={premiumColArray}
					value={Protect.PREMIUM}
					onClick={setValue}
					highlightLabel={m('pages.insurance.protect.body.table.premium.higlightLabel', 'global')}
					testId='premium-protection'
					selected={storeProtect === Protect.PREMIUM}
					tabindex={3}
				/>
				<Col
					value={Protect.BASIC}
					rows={basicColArray}
					onClick={setValue}
					testId='basic-protection'
					selected={storeProtect === Protect.BASIC}
					tabindex={4}
				/>
			</div>

			<RadioInput
				value={storeProtect}
				setValue={setValue}
				items={[
					{ label: m('pages.insurance.protect.body.table.noProtect', 'global'), value: ''}
				]}
				tabindex={5}
				name="protect"
				testId="no-protect"
			/>
		</>
	);
};
export default Table;
