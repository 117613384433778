import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import C from 'constants/Constants';
import { CREDIT_AMOUNT_MAX_VALUE, CREDIT_RUNTIME_STEPS } from 'constants/Credit';
import { useUtils } from 'utils/utils';
import { useCreditUtils } from 'utils/credit/credit';
import { useValidationUtils } from 'utils/validation/validation';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useQuotationApi } from 'api/quotation';

import { useDispatch, useSelector } from 'react-redux';
import {
	updateAmount,
	updateCondition,
	updateFirstRateDate,
	updateInitialPageLoad,
	updateRuntime,
	updateRuntimeIndex,
	updateSanc,
	updateSidebarRateChecked,
	updateUid
} from 'store/App/actions';
import {
	updateCurrentFlow,
	updateCurrentSubpage,
	updateCurrentStepCategory
} from 'store/Page/actions';
import { onUpdateIncome } from 'store/FinancialStatus/actions';
import {
	setGtmType,
	setGtmSubType,
	setGtmStep,
	setGtmStatus,
	setGtmSubStatus,
	setGtmIdentificationDocument
} from 'store/Tracking/actions';
import { usePageUtils } from 'utils/page/page';
import { useEnvUtils } from 'utils/env/env';
import { useTargetUtils } from 'utils/target/target';

const RouteInit = (props) => {
	const { getCurrentFlow, isCreditSubType } = useUtils();
	const { targetView } = useTargetUtils();
	const { useLoginRedirect } = useEnvUtils();
	const { clearSuccesses, clearErrors } = useValidationUtils();
	const { getValidRuntimeLimit } = useCreditUtils();
	const { goToPage } = usePageUtils();
	const { virtualPageLoad, setApplicationInfo, ecommerceAddToCart } = useTrackingUtils();
	const { getLoanRates } = useQuotationApi();

	const currentFlow = getCurrentFlow();

	const location = useLocation();
	const dispatch = useDispatch();

	const registrationToken = useSelector((state) => state.app.accessToken);
	const authToken = useSelector((state) => state.app.authToken);
	const vcJwtToken = useSelector((state) => state.app.vcJwtToken);

	const storeInitialPageLoad = useSelector((state => state.app.initialPageLoad));

	const storeGtmType = useSelector((state => state.tracking.gtmType));
	const storeGtmSubType = useSelector((state => state.tracking.gtmSubType));
	const storeGtmStep = useSelector((state => state.tracking.gtmStep));
	const storeGtmStatus = useSelector((state => state.tracking.gtmStatus));
	const storeGtmSubStatus = useSelector((state => state.tracking.gtmSubStatus));
	const storeGtmIdentificationDocument = useSelector((state => state.tracking.gtmIdentificationDocument));

	const [dataUpdated, setDataUpdated] = useState(false);

	useEffect(() => {
		if (
			useLoginRedirect() &&
			((props.needsRegistrationToken && !registrationToken) ||
			(props.needsAuthToken && !authToken) ||
			(props.needsVcJwtToken && !vcJwtToken))
		) {
			goToPage(currentFlow + C.ROUTES.LOGIN, '?redirect=unauthorized');
		}

		window.scrollTo(0, 0);

		clearSuccesses();
		clearErrors();

		const { uid } = setUrlParamsToStore();

		queryLoanRates(uid);

		if (storeInitialPageLoad) {
			dispatch(updateInitialPageLoad(false));
		}
	}, []);

	useEffect(() => {
		dispatch(setGtmType(storeGtmType !== undefined && props.gtmType !== storeGtmType ? storeGtmType : props.gtmType ?? undefined));
		dispatch(setGtmSubType(storeGtmSubType !== undefined && props.gtmSubType !== storeGtmSubType ? storeGtmSubType : props.gtmSubType ?? undefined));
		dispatch(setGtmStep(storeGtmStep !== undefined && props.gtmStep !== storeGtmStep ? storeGtmStep : props.gtmStep ?? undefined));
		dispatch(setGtmStatus(storeGtmStatus !== undefined && props.gtmStatus !== storeGtmStatus ? storeGtmStatus : props.gtmStatus ?? undefined));
		dispatch(setGtmSubStatus(storeGtmSubStatus !== undefined && props.gtmSubStatus !== storeGtmSubStatus ? storeGtmSubStatus : props.gtmSubStatus ?? undefined));
		dispatch(setGtmIdentificationDocument(storeGtmIdentificationDocument !== undefined && props.gtmIdentificationDocument !== storeGtmIdentificationDocument ? storeGtmIdentificationDocument : props.gtmIdentificationDocument ?? undefined));

		setDataUpdated(true);
	}, [location]);

	useEffect(() => {
		dispatch(updateCurrentFlow(props.flow ? props.flow : null));
	}, [props.flow]);

	useEffect(() => {
		dispatch(updateCurrentSubpage(props.subpage ? props.subpage : null));
	}, [props.subpage]);

	useEffect(() => {
		dispatch(updateCurrentStepCategory(props.stepCategory ? props.stepCategory : null));
	}, [props.stepCategory]);

	useEffect(() => {
		if (dataUpdated) {
			setApplicationInfo();
			virtualPageLoad();
			targetView(storeGtmStep);
			ecommerceAddToCart();
			setDataUpdated(false);
		}
	}, [dataUpdated]);

	const setUrlParamsToStore = () => {
		let sanc;
		let uid;
		if (location.search !== undefined) {

			let search = new URLSearchParams(location.search);

			sanc = search.get('sanc');
			uid = search.get('uid');

			if (sanc) {
				dispatch(updateSanc(sanc));
			}
			if (uid) {
				dispatch(updateUid(uid));
			}

			let condition = search.get('condition');
			if (
				condition &&
				(condition.toUpperCase() === C.CONDITIONS.CCB ||
					condition.toUpperCase() === C.CONDITIONS.CCB90 ||
					condition.toUpperCase() === C.CONDITIONS.WUESTENROT)
			) {
				dispatch(updateCondition(condition.toUpperCase()));
				dispatch(updateSidebarRateChecked(condition.toUpperCase() === C.CONDITIONS.CCB90));
			}

			let income = search.get('income');
			if (income && !isNaN(income)) {
				dispatch(onUpdateIncome(parseInt(income)));
			}

			let amount = search.get('amount');
			if (amount && !isNaN(amount)) {
				let tempAmount = parseInt(amount);
				dispatch(updateAmount(tempAmount > CREDIT_AMOUNT_MAX_VALUE ? CREDIT_AMOUNT_MAX_VALUE : tempAmount));
			}

			let runtime = search.get('runtime');
			if (runtime && !isNaN(runtime)) {
				let tempRuntime = parseInt(runtime);
				tempRuntime = tempRuntime > CREDIT_RUNTIME_STEPS[CREDIT_RUNTIME_STEPS.length - 1] ? CREDIT_RUNTIME_STEPS[CREDIT_RUNTIME_STEPS.length - 1] : tempRuntime;
				dispatch(updateRuntime(tempRuntime));
				dispatch(updateRuntimeIndex(getValidRuntimeLimit(tempRuntime).validRuntimeIndex));
			}

		}

		return { sanc: sanc, uid: uid };
	};

	const queryLoanRates = async (uid) => {
		if (isCreditSubType() && storeInitialPageLoad) {
			const specialUids = [
				'bcpt599',
				'bcpt674',
				'bcpt749',
				'bcpt499'
			];
			const defaultCondition = uid && specialUids.includes(uid) ? uid : C.CONDITIONS.CCB;
			let ccb, ccb90, wuestenrot;
			await getLoanRates(defaultCondition, async (data) => {
				ccb = data;
				await getLoanRates(C.CONDITIONS.CCB90, async (data) => {
					ccb90 = data;
					await getLoanRates(C.CONDITIONS.WUESTENROT, (data) => {
						wuestenrot = data;

						Promise.all([ccb, ccb90, wuestenrot]).then(function(values) {
							let data = values[0] ? values[0] : values[1];
							dispatch(updateFirstRateDate(
								data.date_start && data.date_start.length > 0
									? data.date_start[0]
									: null
							));
						});
					});
				});
			});
		}
	};

	return <>{props.children}</>;
};

RouteInit.propTypes = {
	flow: PropTypes.string,
	subpage: PropTypes.string,
	stepCategory: PropTypes.number,
	gtmType: PropTypes.string,
	gtmSubType: PropTypes.string,
	gtmStep: PropTypes.string,
	gtmStatus: PropTypes.string,
	gtmSubStatus: PropTypes.string,
	gtmIdentificationDocument: PropTypes.string,
	needsRegistrationToken: PropTypes.bool,
	needsAuthToken: PropTypes.bool,
	needsVcJwtToken: PropTypes.bool,
	children: PropTypes.any
};

export default RouteInit;
