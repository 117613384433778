import React from 'react';
import PropTypes from 'prop-types';
import { m } from 'utils/messages/messages';
import { useKeycodeUtils } from 'utils/keycodes/keycodes';

import { Text } from 'components/Atoms/Text';
import NetAmount from 'components/Atoms/Partials/Values/NetAmount';
import Runtime from 'components/Atoms/Partials/Values/Runtime';
import MonthlyRate from 'components/Atoms/Partials/Values/MonthlyRate';
import SvgEdit from 'components/Atoms/SVG/Icons/SvgEdit';

import { useSelector } from 'react-redux';

import styles from './CreditInfos.module.scss';

const CreditInfos = (props) => {
	const { getReturn } = useKeycodeUtils();

	const storeProtect = useSelector((state => state.insurance.protect));

	const handleKeyDown = (event) => {
		if (getReturn(event)) {
			props.openSidebar(true);
		}
	};

	return (
		<nav className={styles.wrapper}>
			<div
				className={styles.row}
				onClick={props.openSidebar}
			>
				<div>
					<div className={styles.label}>
						<Text size="s">{m('amount.label', 'fields')}</Text>
					</div>
					<div className={styles.value}>
						<Text size="l"><NetAmount isDecimal /> €</Text>
					</div>
				</div>
				<div>
					<div className={styles.label}>
						<Text size="s">{m('runtime.label', 'fields')}</Text>
					</div>
					<div className={styles.value}>
						<Text size="l"><Runtime showMonthLabel /></Text>
					</div>
				</div>
				<div>
					<div className={styles.label}>
						<Text size="s">{m(`rate.${storeProtect ? 'protect' : 'label'}`, 'fields')}</Text>
					</div>
					<div className={styles.value}>
						<Text size="l"><MonthlyRate isDecimal withProtect /> €</Text>
					</div>
				</div>
				<div className={styles.spacer} />
				<div
					className={styles.editButton}
					tabIndex={97}
					onKeyDown={handleKeyDown}
					aria-label="Kreditrechner öffnen"
				>
					<SvgEdit />
				</div>
			</div>
		</nav>
	);
};

CreditInfos.propTypes = {
	openSidebar: PropTypes.func
};

export default CreditInfos;
