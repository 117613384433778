export const defaultState = {
	allowAdvertising: false,
	acceptTermsAndConditions: false,
	ownResponsibility: false,
	confirmStockBasicInfo: false,
	acceptSchufa: false,
	acceptInsurance: false,
	saAcceptTermsAndConditions: false
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_ALLOW_ADVERTISING':
			return { ...state, allowAdvertising: action.payload };
		case 'SET_ACCEPT_TERMS_AND_CONDITIONS':
			return { ...state, acceptTermsAndConditions: action.payload };
		case 'SET_OWN_RESPONSIBILITY':
			return { ...state, ownResponsibility: action.payload };
		case 'SET_CONFIRM_STOCK_BASIC_INFO':
			return { ...state, confirmStockBasicInfo: action.payload };
		case 'SET_ACCEPT_SCHUFA':
			return { ...state, acceptSchufa: action.payload };
		case 'SET_ACCEPT_INSURANCE':
			return { ...state, acceptInsurance: action.payload };
		case 'SET_SA_ACCEPT_TERMS_AND_CONDITIONS':
			return { ...state, saAcceptTermsAndConditions: action.payload };
		default:
			return state;
	}
};

export default reducers;
