import React, { useMemo } from 'react';
import classNames from 'classnames';

import SvgAlert from 'components/Atoms/SVG/Icons/SvgAlert';
import SvgCheckCircle from 'components/Atoms/SVG/Icons/SvgCheckCircle';

import styles from './HelperText.module.scss';
import PropTypes from 'prop-types';

const HelperText = (props) => {
	if (!props.message) return null;

	const type = useMemo(() => {
		switch (props.type) {
			case 'light':
				return styles.light;
			case 'error':
				return styles.error;
			case 'success':
				return styles.success;
			default:
				return styles.default;
		}
	}, [props.type]);

	return (
		<p className={classNames([styles.message, type])}>
			{props.hasIcon && props.type === 'error' && (
				<span className={styles.icon}>
					<SvgAlert />
				</span>
			)}
			{props.hasIcon && props.type === 'success' && (
				<span className={styles.icon}>
					<SvgCheckCircle />
				</span>
			)}
			<span className={styles.text}>{props.message}</span>
		</p>
	);
};

HelperText.propTypes = {
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	type: PropTypes.string,
	hasIcon: PropTypes.bool
};

export default HelperText;
