import React from 'react';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';

import { Container, Row, Col } from 'components/Atoms/Grid';
import { Text } from 'components/Atoms/Text';

import styles from 'components/Pages/Flows/ShortApplication/FootNote.module.scss';

const FootNote = () => {
	const { getCurrentFlow } = useUtils();

	const currentFlow = getCurrentFlow();

	return (
		<div className={styles.footNote}>
			<Container>
				<Row justifyContent='center'>
					<Col xs={12}>
						<Text size="s">
							<strong><sup>1</sup> {m('footNote', currentFlow)}</strong>
						</Text>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
export default FootNote;
