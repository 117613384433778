import React from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';

import { Text, Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';

const AddressVerificationPostponed = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid } = useTrackingUtils();

	const currentFlow = getCurrentFlow();

	const onSubmit = () => {
		continueValid();
		goToPage(currentFlow + C.ROUTES.ADDRESS_VERIFICATION);
	};

	return (
		<>
			<Title mb>{m('addressVerificationPostponed.title', currentFlow)}</Title>
			<Text size="l" mb>{m('addressVerificationPostponed.subtitle', currentFlow)}</Text>
			<Form
				onSubmit={onSubmit}
				submitText={m('finishNow.label', 'fields')}
				clearErrors={() => {}}
				submitTabindex={1}
			/>
		</>
	);
};
export default AddressVerificationPostponed;
