import { m } from 'utils/messages/messages';

export const useItemsUtils = () => {
	const getSalutationDropdownItems = () => {
		return [
			{ label: m('salutation.items.female', 'fields'), value: 'FEMALE' },
			{ label: m('salutation.items.male', 'fields'), value: 'MALE' }
		];
	};

	const getHasSecondApplicantItems = () => [
		{
			label: m('creditNumberPersons.onePerson', 'fields'),
			value: 'false'
		},
		{
			label: m('creditNumberPersons.twoPersons', 'fields'),
			value: 'true'
		}
	];

	const getCountryCodeDropdownItems = () => {
		return [
			{ label: '+49', value: '49', icon: 'flags/de' },
			{ label: '+34', value: '34', icon: 'flags/es' },
			{ label: '+48', value: '48', icon: 'flags/pl' },
			{ label: '+44', value: '44', icon: 'flags/gb' },
			{ label: '+31', value: '31', icon: 'flags/nl' },
			{ label: '+33', value: '33', icon: 'flags/fr' },
			{ label: '+41', value: '41', icon: 'flags/ch' },
			{ label: '+43', value: '43', icon: 'flags/at' },
			{ label: '+39', value: '39', icon: 'flags/it' },
			{ label: '+45', value: '45', icon: 'flags/dk' },
			{ label: '+32', value: '32', icon: 'flags/be' },
			{ label: '+420', value: '420', icon: 'flags/cz' },
			{ label: '+352', value: '352', icon: 'flags/lu' },
			{ label: '+423', value: '423', icon: 'flags/li' },
			{ label: '+30', value: '30', icon: 'flags/gr' },
			{ label: '+46', value: '46', icon: 'flags/se' },
			{ label: '+47', value: '47', icon: 'flags/no' },
			{ label: '+358', value: '358', icon: 'flags/fi' },
			{ label: '+1', value: '1', icon: 'flags/us' },
			// { label: '+1', value: '1', icon: 'flags/ca' },
			{ label: '+61', value: '61', icon: 'flags/au' },
			{ label: '+93', value: '93', icon: 'flags/af' },
			{ label: '+355', value: '355', icon: 'flags/al' },
			{ label: '+213', value: '213', icon: 'flags/dz' },
			{ label: '+1-684', value: '1684', icon: 'flags/as' },
			{ label: '+376', value: '376', icon: 'flags/ad' },
			{ label: '+244', value: '244', icon: 'flags/ao' },
			{ label: '+1-264', value: '1264', icon: 'flags/ai' },
			{ label: '+672', value: '672', icon: 'flags/aq' },
			{ label: '+1-268', value: '1268', icon: 'flags/ag' },
			{ label: '+54', value: '54', icon: 'flags/ar' },
			{ label: '+374', value: '374', icon: 'flags/am' },
			{ label: '+297', value: '297', icon: 'flags/aw' },
			{ label: '+994', value: '994', icon: 'flags/az' },
			{ label: '+1-242', value: '1242', icon: 'flags/bs' },
			{ label: '+973', value: '973', icon: 'flags/bh' },
			{ label: '+880', value: '880', icon: 'flags/bd' },
			{ label: '+1-246', value: '1246', icon: 'flags/bb' },
			{ label: '+375', value: '375', icon: 'flags/by' },
			{ label: '+501', value: '501', icon: 'flags/bz' },
			{ label: '+229', value: '229', icon: 'flags/bj' },
			{ label: '+1-441', value: '1441', icon: 'flags/bm' },
			{ label: '+975', value: '975', icon: 'flags/bt' },
			{ label: '+591', value: '591', icon: 'flags/bo' },
			{ label: '+387', value: '387', icon: 'flags/ba' },
			{ label: '+267', value: '267', icon: 'flags/bw' },
			{ label: '+55', value: '55', icon: 'flags/br' },
			{ label: '+246', value: '246', icon: 'flags/io' },
			{ label: '+1-284', value: '1284', icon: 'flags/vg' },
			{ label: '+673', value: '673', icon: 'flags/bn' },
			{ label: '+359', value: '359', icon: 'flags/bg' },
			{ label: '+226', value: '226', icon: 'flags/bf' },
			{ label: '+257', value: '257', icon: 'flags/bi' },
			{ label: '+855', value: '855', icon: 'flags/kh' },
			{ label: '+237', value: '237', icon: 'flags/cm' },
			{ label: '+238', value: '238', icon: 'flags/cv' },
			{ label: '+1-345', value: '1345', icon: 'flags/ky' },
			{ label: '+236', value: '236', icon: 'flags/cf' },
			{ label: '+235', value: '235', icon: 'flags/td' },
			{ label: '+56', value: '56', icon: 'flags/cl' },
			{ label: '+86', value: '86', icon: 'flags/cn' },
			{ label: '+61', value: '61', icon: 'flags/cx' },
			// { label: '+61', value: '61', icon: 'flags/cc' },
			{ label: '+57', value: '57', icon: 'flags/co' },
			{ label: '+269', value: '269', icon: 'flags/km' },
			{ label: '+682', value: '682', icon: 'flags/ck' },
			{ label: '+506', value: '506', icon: 'flags/cr' },
			{ label: '+385', value: '385', icon: 'flags/hr' },
			{ label: '+53', value: '53', icon: 'flags/cu' },
			{ label: '+599', value: '599', icon: 'flags/cw' },
			{ label: '+357', value: '357', icon: 'flags/cy' },
			{ label: '+243', value: '243', icon: 'flags/cd' },
			{ label: '+253', value: '253', icon: 'flags/dj' },
			{ label: '+1-767', value: '1767', icon: 'flags/dm' },
			{ label: '+1-809', value: '1809', icon: 'flags/do' },
			{ label: '+1-829', value: '1829', icon: 'flags/do' },
			{ label: '+1-849', value: '1849', icon: 'flags/do' },
			{ label: '+670', value: '670', icon: 'flags/tl' },
			{ label: '+593', value: '593', icon: 'flags/ec' },
			{ label: '+20', value: '20', icon: 'flags/eg' },
			{ label: '+503', value: '503', icon: 'flags/sv' },
			{ label: '+240', value: '240', icon: 'flags/gq' },
			{ label: '+291', value: '291', icon: 'flags/er' },
			{ label: '+372', value: '372', icon: 'flags/ee' },
			{ label: '+251', value: '251', icon: 'flags/et' },
			{ label: '+500', value: '500', icon: 'flags/fk' },
			{ label: '+298', value: '298', icon: 'flags/fo' },
			{ label: '+679', value: '679', icon: 'flags/fj' },
			{ label: '+689', value: '689', icon: 'flags/pf' },
			{ label: '+241', value: '241', icon: 'flags/ga' },
			{ label: '+220', value: '220', icon: 'flags/gm' },
			{ label: '+995', value: '995', icon: 'flags/ge' },
			{ label: '+233', value: '233', icon: 'flags/gh' },
			{ label: '+350', value: '350', icon: 'flags/gi' },
			{ label: '+299', value: '299', icon: 'flags/gl' },
			{ label: '+1-473', value: '1473', icon: 'flags/gd' },
			{ label: '+1-671', value: '1671', icon: 'flags/gu' },
			{ label: '+502', value: '502', icon: 'flags/gt' },
			// { label: '+44-1481', value: '441481', icon: 'flags/gg' },
			{ label: '+224', value: '224', icon: 'flags/gn' },
			{ label: '+245', value: '245', icon: 'flags/gw' },
			{ label: '+592', value: '592', icon: 'flags/gy' },
			{ label: '+509', value: '509', icon: 'flags/ht' },
			{ label: '+504', value: '504', icon: 'flags/hn' },
			{ label: '+852', value: '852', icon: 'flags/hk' },
			{ label: '+36', value: '36', icon: 'flags/hu' },
			{ label: '+354', value: '354', icon: 'flags/is' },
			{ label: '+91', value: '91', icon: 'flags/in' },
			{ label: '+62', value: '62', icon: 'flags/id' },
			{ label: '+98', value: '98', icon: 'flags/ir' },
			{ label: '+964', value: '964', icon: 'flags/iq' },
			{ label: '+353', value: '353', icon: 'flags/ie' },
			// { label: '+44-1624', value: '441624', icon: 'flags/im' },
			{ label: '+972', value: '972', icon: 'flags/il' },
			{ label: '+225', value: '225', icon: 'flags/ci' },
			{ label: '+1-876', value: '1876', icon: 'flags/jm' },
			{ label: '+81', value: '81', icon: 'flags/jp' },
			// { label: '+44-1534', value: '441534', icon: 'flags/je' },
			{ label: '+962', value: '962', icon: 'flags/jo' },
			// { label: '+7', value: '7', icon: 'flags/kz' },
			{ label: '+254', value: '254', icon: 'flags/ke' },
			{ label: '+686', value: '686', icon: 'flags/ki' },
			{ label: '+383', value: '383', icon: 'flags/xk' },
			{ label: '+965', value: '965', icon: 'flags/kw' },
			{ label: '+996', value: '996', icon: 'flags/kg' },
			{ label: '+856', value: '856', icon: 'flags/la' },
			{ label: '+371', value: '371', icon: 'flags/lv' },
			{ label: '+961', value: '961', icon: 'flags/lb' },
			{ label: '+266', value: '266', icon: 'flags/ls' },
			{ label: '+231', value: '231', icon: 'flags/lr' },
			{ label: '+218', value: '218', icon: 'flags/ly' },
			{ label: '+370', value: '370', icon: 'flags/lt' },
			{ label: '+853', value: '853', icon: 'flags/mo' },
			{ label: '+389', value: '389', icon: 'flags/mk' },
			{ label: '+261', value: '261', icon: 'flags/mg' },
			{ label: '+265', value: '265', icon: 'flags/mw' },
			{ label: '+60', value: '60', icon: 'flags/my' },
			{ label: '+960', value: '960', icon: 'flags/mv' },
			{ label: '+223', value: '223', icon: 'flags/ml' },
			{ label: '+356', value: '356', icon: 'flags/mt' },
			{ label: '+692', value: '692', icon: 'flags/mh' },
			{ label: '+222', value: '222', icon: 'flags/mr' },
			{ label: '+230', value: '230', icon: 'flags/mu' },
			{ label: '+262', value: '262', icon: 'flags/yt' },
			{ label: '+52', value: '52', icon: 'flags/mx' },
			{ label: '+691', value: '691', icon: 'flags/fm' },
			{ label: '+373', value: '373', icon: 'flags/md' },
			{ label: '+377', value: '377', icon: 'flags/mc' },
			{ label: '+976', value: '976', icon: 'flags/mn' },
			{ label: '+382', value: '382', icon: 'flags/me' },
			{ label: '+1-664', value: '1664', icon: 'flags/ms' },
			{ label: '+212', value: '212', icon: 'flags/ma' },
			{ label: '+258', value: '258', icon: 'flags/mz' },
			{ label: '+95', value: '95', icon: 'flags/mm' },
			{ label: '+264', value: '264', icon: 'flags/na' },
			{ label: '+674', value: '674', icon: 'flags/nr' },
			{ label: '+977', value: '977', icon: 'flags/np' },
			{ label: '+687', value: '687', icon: 'flags/nc' },
			{ label: '+64', value: '64', icon: 'flags/nz' },
			{ label: '+505', value: '505', icon: 'flags/ni' },
			{ label: '+227', value: '227', icon: 'flags/ne' },
			{ label: '+234', value: '234', icon: 'flags/ng' },
			{ label: '+683', value: '683', icon: 'flags/nu' },
			{ label: '+850', value: '850', icon: 'flags/kp' },
			{ label: '+1-670', value: '1670', icon: 'flags/mp' },
			{ label: '+968', value: '968', icon: 'flags/om' },
			{ label: '+92', value: '92', icon: 'flags/pk' },
			{ label: '+680', value: '680', icon: 'flags/pw' },
			{ label: '+970', value: '970', icon: 'flags/ps' },
			{ label: '+507', value: '507', icon: 'flags/pa' },
			{ label: '+675', value: '675', icon: 'flags/pg' },
			{ label: '+595', value: '595', icon: 'flags/py' },
			{ label: '+51', value: '51', icon: 'flags/pe' },
			{ label: '+63', value: '63', icon: 'flags/ph' },
			{ label: '+64', value: '64', icon: 'flags/pn' },
			{ label: '+351', value: '351', icon: 'flags/pt' },
			{ label: '+1-787', value: '1787', icon: 'flags/pr' },
			{ label: '+1-939', value: '1939', icon: 'flags/pr' },
			{ label: '+974', value: '974', icon: 'flags/qa' },
			{ label: '+242', value: '242', icon: 'flags/cg' },
			{ label: '+262', value: '262', icon: 'flags/re' },
			{ label: '+40', value: '40', icon: 'flags/ro' },
			{ label: '+7', value: '7', icon: 'flags/ru' },
			{ label: '+250', value: '250', icon: 'flags/rw' },
			{ label: '+590', value: '590', icon: 'flags/bl' },
			{ label: '+290', value: '290', icon: 'flags/sh' },
			{ label: '+1-869', value: '1869', icon: 'flags/kn' },
			{ label: '+1-758', value: '1758', icon: 'flags/lc' },
			{ label: '+590', value: '590', icon: 'flags/mf' },
			{ label: '+508', value: '508', icon: 'flags/pm' },
			{ label: '+1-784', value: '1784', icon: 'flags/vc' },
			{ label: '+685', value: '685', icon: 'flags/ws' },
			{ label: '+378', value: '378', icon: 'flags/sm' },
			{ label: '+239', value: '239', icon: 'flags/st' },
			{ label: '+966', value: '966', icon: 'flags/sa' },
			{ label: '+221', value: '221', icon: 'flags/sn' },
			{ label: '+381', value: '381', icon: 'flags/rs' },
			{ label: '+248', value: '248', icon: 'flags/sc' },
			{ label: '+232', value: '232', icon: 'flags/sl' },
			{ label: '+65', value: '65', icon: 'flags/sg' },
			{ label: '+1-721', value: '1721', icon: 'flags/sx' },
			{ label: '+421', value: '421', icon: 'flags/sk' },
			{ label: '+386', value: '386', icon: 'flags/si' },
			{ label: '+677', value: '677', icon: 'flags/sb' },
			{ label: '+252', value: '252', icon: 'flags/so' },
			{ label: '+27', value: '27', icon: 'flags/za' },
			{ label: '+82', value: '82', icon: 'flags/kr' },
			{ label: '+211', value: '211', icon: 'flags/ss' },
			{ label: '+94', value: '94', icon: 'flags/lk' },
			{ label: '+249', value: '249', icon: 'flags/sd' },
			{ label: '+597', value: '597', icon: 'flags/sr' },
			{ label: '+47', value: '47', icon: 'flags/sj' },
			{ label: '+268', value: '268', icon: 'flags/sz' },
			{ label: '+963', value: '963', icon: 'flags/sy' },
			{ label: '+886', value: '886', icon: 'flags/tw' },
			{ label: '+992', value: '992', icon: 'flags/tj' },
			{ label: '+255', value: '255', icon: 'flags/tz' },
			{ label: '+66', value: '66', icon: 'flags/th' },
			{ label: '+228', value: '228', icon: 'flags/tg' },
			{ label: '+690', value: '690', icon: 'flags/tk' },
			{ label: '+676', value: '676', icon: 'flags/to' },
			{ label: '+1-868', value: '1868', icon: 'flags/tt' },
			{ label: '+216', value: '216', icon: 'flags/tn' },
			{ label: '+90', value: '90', icon: 'flags/tr' },
			{ label: '+993', value: '993', icon: 'flags/tm' },
			{ label: '+1-649', value: '1649', icon: 'flags/tc' },
			{ label: '+688', value: '688', icon: 'flags/tv' },
			{ label: '+1-340', value: '1340', icon: 'flags/vi' },
			{ label: '+256', value: '256', icon: 'flags/ug' },
			{ label: '+380', value: '380', icon: 'flags/ua' },
			{ label: '+971', value: '971', icon: 'flags/ae' },
			{ label: '+598', value: '598', icon: 'flags/uy' },
			{ label: '+998', value: '998', icon: 'flags/uz' },
			{ label: '+678', value: '678', icon: 'flags/vu' },
			{ label: '+379', value: '379', icon: 'flags/va' },
			{ label: '+58', value: '58', icon: 'flags/ve' },
			{ label: '+84', value: '84', icon: 'flags/vn' },
			{ label: '+681', value: '681', icon: 'flags/wf' },
			{ label: '+212', value: '212', icon: 'flags/eh' },
			{ label: '+967', value: '967', icon: 'flags/ye' },
			{ label: '+260', value: '260', icon: 'flags/zm' },
			{ label: '+263', value: '263', icon: 'flags/zw' }
		];
	};

	const getStateDropdownItems = () => {
		return [
			{ label: 'Baden-Württemberg', value: 'Baden-Württemberg' },
			{ label: 'Bayern', value: 'Bayern' },
			{ label: 'Berlin', value: 'Berlin' },
			{ label: 'Brandenburg', value: 'Brandenburg' },
			{ label: 'Bremen', value: 'Bremen' },
			{ label: 'Hamburg', value: 'Hamburg' },
			{ label: 'Hessen', value: 'Hessen' },
			{ label: 'Mecklenburg-Vorpommern', value: 'Mecklenburg-Vorpommern' },
			{ label: 'Niedersachsen', value: 'Niedersachsen' },
			{ label: 'Nordrhein-Westfalen', value: 'Nordrhein-Westfalen' },
			{ label: 'Rheinland-Pfalz', value: 'Rheinland-Pfalz' },
			{ label: 'Saarland', value: 'Saarland' },
			{ label: 'Sachsen', value: 'Sachsen' },
			{ label: 'Sachsen-Anhalt', value: 'Sachsen-Anhalt' },
			{ label: 'Schleswig-Holstein', value: 'Schleswig-Holstein' },
			{ label: 'Thüringen', value: 'Thüringen' }
		];
	};

	const getNationalityItems = () => {
		return [
			{ label: 'Deutschland', value: 'DE' },
			{ label: 'Österreich', value: 'AT' },
			{ label: 'Niederlande', value: 'NL' },
			{ label: 'Polen', value: 'PL' },
			{ label: 'Türkei', value: 'TR' },
			{ label: 'Italien', value: 'IT' },
			{ label: 'Spanien', value: 'ES' },
			{ label: 'Rumänien', value: 'RO' },
			{ label: 'Ukraine', value: 'UA' },
			{ label: 'Afghanistan', value: 'AF' },
			{ label: 'Ägypten', value: 'EG' },
			{ label: 'Albanien', value: 'AL' },
			{ label: 'Algerien', value: 'DZ' },
			{ label: 'Angola', value: 'AO' },
			{ label: 'Anguilla', value: 'AI' },
			{ label: 'Antigua', value: 'AG' },
			{ label: 'Arabische', value: 'AE' },
			{ label: 'Argentinien', value: 'AR' },
			{ label: 'Armenien', value: 'AM' },
			{ label: 'Aruba', value: 'AW' },
			{ label: 'Aserbaidschan', value: 'AZ' },
			{ label: 'Äthiopien', value: 'ET' },
			{ label: 'Australien', value: 'AU' },
			{ label: 'Bahamas', value: 'BS' },
			{ label: 'Bahrain', value: 'BH' },
			{ label: 'Bangladesch', value: 'BD' },
			{ label: 'Barbados', value: 'BB' },
			{ label: 'Belarus', value: 'BY' },
			{ label: 'Belgien', value: 'BE' },
			{ label: 'Belize', value: 'BZ' },
			{ label: 'Benin', value: 'BJ' },
			{ label: 'Bermuda', value: 'BM' },
			{ label: 'Bhutan', value: 'BT' },
			{ label: 'Bolivien', value: 'BO' },
			{ label: 'Bosnien-Herzegowina', value: 'BA' },
			{ label: 'Botsuana', value: 'BW' },
			{ label: 'Brasilien', value: 'BR' },
			{ label: 'Brunei', value: 'BN' },
			{ label: 'Bulgarien', value: 'BG' },
			{ label: 'Burkina', value: 'BF' },
			{ label: 'Burundi', value: 'BI' },
			{ label: 'Chile', value: 'CL' },
			{ label: 'Costa Rica', value: 'CR' },
			{ label: 'Curacao', value: 'CW' },
			{ label: 'Dänemark', value: 'DK' },
			{ label: 'Demokr. Rep. Kongo', value: 'CD' },
			{ label: 'Dominica', value: 'DM' },
			{ label: 'Dominikanische Rep.', value: 'DO' },
			{ label: 'Dschibuti', value: 'DJ' },
			{ label: 'Ecuador', value: 'EC' },
			{ label: 'El Salvador', value: 'SV' },
			{ label: 'Elfenbeinküste', value: 'CI' },
			{ label: 'Eritrea', value: 'ER' },
			{ label: 'Estland', value: 'EE' },
			{ label: 'Fidschi', value: 'FJ' },
			{ label: 'Finnland', value: 'FI' },
			{ label: 'Frankreich', value: 'FR' },
			{ label: 'Gabun', value: 'GA' },
			{ label: 'Gambia', value: 'GM' },
			{ label: 'Georgien', value: 'GE' },
			{ label: 'Ghana', value: 'GH' },
			{ label: 'Gibraltar', value: 'GI' },
			{ label: 'Grenada', value: 'GD' },
			{ label: 'Griechenland', value: 'GR' },
			{ label: 'Grossbritannien', value: 'GB' },
			{ label: 'Guatemala', value: 'GT' },
			{ label: 'Guinea', value: 'GN' },
			{ label: 'Guyana', value: 'GY' },
			{ label: 'Haiti', value: 'HT' },
			{ label: 'Honduras', value: 'HN' },
			{ label: 'Indien', value: 'IN' },
			{ label: 'Indonesien', value: 'ID' },
			{ label: 'Irak', value: 'IQ' },
			{ label: 'Iran', value: 'IR' },
			{ label: 'Irland', value: 'IE' },
			{ label: 'Island', value: 'IS' },
			{ label: 'Israel', value: 'IL' },
			{ label: 'Italien', value: 'IT' },
			{ label: 'Jamaika', value: 'JM' },
			{ label: 'Japan', value: 'JP' },
			{ label: 'Jemen', value: 'YE' },
			{ label: 'Jersey', value: 'JE' },
			{ label: 'Jordanien', value: 'JO' },
			{ label: 'Kaimaninseln', value: 'KY' },
			{ label: 'Kambodscha', value: 'KH' },
			{ label: 'Kamerun', value: 'CM' },
			{ label: 'Kanada', value: 'CA' },
			{ label: 'Kap Verde', value: 'CV' },
			{ label: 'Kasachstan', value: 'KZ' },
			{ label: 'Katar', value: 'QA' },
			{ label: 'Kenia', value: 'KE' },
			{ label: 'Kirgisistan', value: 'KG' },
			{ label: 'Kiribati', value: 'KI' },
			{ label: 'Kolumbien', value: 'CO' },
			{ label: 'Komoren', value: 'KM' },
			{ label: 'Kosovo', value: 'XK' },
			{ label: 'Korea, Demokratische Volksrepublik (Nordkorea)', value: 'KP' },
			{ label: 'Korea, Republik (Südkorea)', value: 'KR' },
			{ label: 'Kroatien', value: 'HR' },
			{ label: 'Kuba', value: 'CU' },
			{ label: 'Kuwait', value: 'KW' },
			{ label: 'Laos', value: 'LA' },
			{ label: 'Lesotho', value: 'LS' },
			{ label: 'Lettland', value: 'LV' },
			{ label: 'Libanon', value: 'LB' },
			{ label: 'Liberia', value: 'LR' },
			{ label: 'Libyen', value: 'LY' },
			{ label: 'Liechtenstein', value: 'LI' },
			{ label: 'Litauen', value: 'LT' },
			{ label: 'Luxemburg', value: 'LU' },
			{ label: 'Madagaskar', value: 'MG' },
			{ label: 'Malawi', value: 'MW' },
			{ label: 'Malaysia', value: 'MY' },
			{ label: 'Malediven', value: 'MV' },
			{ label: 'Mali', value: 'ML' },
			{ label: 'Malta', value: 'MT' },
			{ label: 'Marokko', value: 'MA' },
			{ label: 'Marshall-Inseln', value: 'MH' },
			{ label: 'Martinique', value: 'MQ' },
			{ label: 'Mauretanien', value: 'MR' },
			{ label: 'Mauritius', value: 'MU' },
			{ label: 'Mazedonien', value: 'MK' },
			{ label: 'Mexiko', value: 'MX' },
			{ label: 'Mikronesien', value: 'FM' },
			{ label: 'Moldawien', value: 'MD' },
			{ label: 'Monaco', value: 'MC' },
			{ label: 'Mongolei', value: 'MN' },
			{ label: 'Monserrat', value: 'MS' },
			{ label: 'Montenegro', value: 'ME' },
			{ label: 'Mosambik', value: 'MZ' },
			{ label: 'Myanmar', value: 'MM' },
			{ label: 'Namibia', value: 'NA' },
			{ label: 'Nauru', value: 'NR' },
			{ label: 'Nepal', value: 'NP' },
			{ label: 'Neuseeland', value: 'NZ' },
			{ label: 'Nicaragua', value: 'NI' },
			{ label: 'Niger', value: 'NE' },
			{ label: 'Norwegen', value: 'NO' },
			{ label: 'Oman', value: 'OM' },
			{ label: 'Pakistan', value: 'PK' },
			{ label: 'Palästina', value: 'PS' },
			{ label: 'Palau', value: 'PW' },
			{ label: 'Panama', value: 'PA' },
			{ label: 'Papua-Neuguinea', value: 'PG' },
			{ label: 'Paraguay', value: 'PY' },
			{ label: 'Peru', value: 'PE' },
			{ label: 'Philippinen', value: 'PH' },
			{ label: 'Portugal', value: 'PT' },
			{ label: 'Puerto', value: 'PR' },
			{ label: 'Republik Kongo', value: 'CG' },
			{ label: 'Republik Serbien', value: 'RS' },
			{ label: 'Reunion', value: 'RE' },
			{ label: 'Ruanda', value: 'RW' },
			{ label: 'Russland', value: 'RU' },
			{ label: 'Salomonen', value: 'SB' },
			{ label: 'Sambia', value: 'ZM' },
			{ label: 'Samoa', value: 'WS' },
			{ label: 'San Marino', value: 'SM' },
			{ label: 'Sao Tome u. Princip', value: 'ST' },
			{ label: 'Saudi-Arabien', value: 'SA' },
			{ label: 'Schweden', value: 'SE' },
			{ label: 'Schweiz', value: 'CH' },
			{ label: 'Senegal', value: 'SN' },
			{ label: 'Seychellen', value: 'SC' },
			{ label: 'Sierra Leone', value: 'SL' },
			{ label: 'Simbabwe', value: 'ZW' },
			{ label: 'Singapur', value: 'SG' },
			{ label: 'Slowakei', value: 'SK' },
			{ label: 'Slowenien', value: 'SI' },
			{ label: 'Somalia', value: 'SO' },
			{ label: 'Sri Lanka', value: 'LK' },
			{ label: 'Südafrika', value: 'ZA' },
			{ label: 'Sudan', value: 'SD' },
			{ label: 'Suriname', value: 'SR' },
			{ label: 'Swasiland', value: 'SZ' },
			{ label: 'Tadschikistan', value: 'TJ' },
			{ label: 'Taiwan', value: 'TW' },
			{ label: 'Tansania', value: 'TZ' },
			{ label: 'Thailand', value: 'TH' },
			{ label: 'Timor-Leste', value: 'TL' },
			{ label: 'Togo', value: 'TG' },
			{ label: 'Tonga', value: 'TO' },
			{ label: 'Trinidad', value: 'TT' },
			{ label: 'Tschad', value: 'TD' },
			{ label: 'Tschechien', value: 'CZ' },
			{ label: 'Tunesien', value: 'TN' },
			{ label: 'Turkmenistan', value: 'TM' },
			{ label: 'Uganda', value: 'UG' },
			{ label: 'Ungarn', value: 'HU' },
			{ label: 'Uruguay', value: 'UY' },
			{ label: 'USA', value: 'US' },
			{ label: 'Usbekistan', value: 'UZ' },
			{ label: 'Vanuatu', value: 'VU' },
			{ label: 'Vatikanstadt', value: 'VA' },
			{ label: 'Venezuela', value: 'VE' },
			{ label: 'Vietnam', value: 'VN' },
			{ label: 'Volksrep. China', value: 'CN' },
			{ label: 'Zentralafrika', value: 'CF' },
			{ label: 'Zypern', value: 'CY' }
		];
	};

	const getMaritalStatusItems = () => {
		return [
			{
				label: m('maritalStatus.items.partnered', 'fields'),
				value: 'PARTNERED'
			},
			{
				label: m('maritalStatus.items.separated', 'fields'),
				value: 'SEPARATED'
			},
			{
				label: m('maritalStatus.items.divorced', 'fields'),
				value: 'DIVORCED'
			},
			{
				label: m('maritalStatus.items.widowed', 'fields'),
				value: 'WIDOWED'
			}
		];
	};

	const getMaritalStatusItemsFull = () => {
		const items = getMaritalStatusItems();
		items.unshift({
			label: m('maritalStatus.items.married', 'fields'),
			value: 'MARRIED'
		});
		items.unshift({
			label: m('maritalStatus.items.single', 'fields'),
			value: 'SINGLE'
		});
		return items;
	};

	const getOccupationItems = () => {
		return [
			{ label: m('occupation.items.employed', 'fields'), value: 'EMPLOYED' },
			{
				label: m('occupation.items.pensioner', 'fields'),
				value: 'PENSINER'
			},
			{ label: m('occupation.items.officer', 'fields'), value: 'OFFICER' },
			{ label: m('occupation.items.student', 'fields'), value: 'STUDENT' },
			{
				label: m('occupation.items.unemployed', 'fields'),
				value: 'UNEMPLOYED'
			}
		];
	};

	const getOccupationItemsFull = () => {
		const items = getOccupationItems();
		items.push({
			label: m('occupation.items.freelancer', 'fields'),
			value: 'FREELANCER'
		});
		items.push({
			label: m('occupation.items.public', 'fields'),
			value: 'CIVIL_SERVICE_EMPLOYEE'
		});
		return items;
	};

	const getOccupationLimitedItems = () => {
		return [
			{ label: m('occupationLimited.items.unlimited', 'fields'), value: 'Unbefristet' },
			{ label: m('occupationLimited.items.limited', 'fields'), value: 'Befristet' }
		];
	};

	const getTypeOfLivingItems = () => {
		return [
			{
				label: m('typeOfLiving.items.rentedApartment', 'fields'),
				value: 'MIETWOHNUNG'
			},
			{
				label: m('typeOfLiving.items.ownerOccupiedApartment', 'fields'),
				value: 'EIGENTUMSWOHNUNG'
			},
			{
				label: m('typeOfLiving.items.ownHouse', 'fields'),
				value: 'EIGENES_HAUS'
			},
			{
				label: m('typeOfLiving.items.residentWithTheParents', 'fields'),
				value: 'WOHNHAFT_BEI_ELTERN'
			}
		];
	};

	const getNumberChildrenDropdownItems = () => {
		return [
			{ label: '3', value: '3' },
			{ label: '4', value: '4' },
			{ label: '5', value: '5' },
			{ label: '6', value: '6' },
			{ label: '7', value: '7' },
			{ label: '8', value: '8' },
			{ label: '9', value: '9' },
			{ label: '10', value: '10' }
		];
	};

	const getTinTypeItems = () => {
		return [
			{ label: m('no', 'fields'), value: 'Nein' },
			{ label: m('pages.taxNumber.yesUS', 'global'), value: 'US' },
			{ label: m('pages.taxNumber.yesOthers', 'global'), value: 'Ja' }
		];
	};

	const getUsTinReasonDropdownItems = () => {
		return [
			{
				label: m('usTin.reason.items.citizenship', 'fields'),
				value: 'STAATSBÜRGERSCHAFT'
			},
			{
				label: m('usTin.reason.items.taxResidency', 'fields'),
				value: 'STEUERLICHE ANSÄSSIGKEIT'
			},
			{ label: m('usTin.reason.items.both', 'fields'), value: 'BEIDES' }
		];
	};

	const getInvestmentPaymentTypeItems = () => {
		return [
			{ label: 'Jährlich', value: 'YEARLY' },
			{ label: 'Zum Laufzeitende', value: 'END_OF_TERM' }
		];
	};

	const getInvestmentRuntimeItems = () => {
		return [
			{ label: '6 Monate', value: '6' },
			{ label: '1 Jahr', value: '12' },
			{ label: '2 Jahre', value: '24' },
			{ label: '3 Jahre', value: '36' },
			{ label: '4 Jahre', value: '48' },
			{ label: '5 Jahre', value: '60' },
			{ label: '6 Jahre', value: '72' },
			{ label: '7 Jahre', value: '84' },
			{ label: '8 Jahre', value: '96' }
		];
	};

	const getHasStockProficiencyItems = () => [
		{
			label: m('yes', 'fields'),
			value: 'true'
		},
		{
			label: m('no', 'fields'),
			value: 'false'
		}
	];

	const getStockProficiencyFrenquencyItems = () => [
		{ label: m('stockProficiency.frequencies.items.zero', 'fields'), value: 'ZERO' },
		{ label: m('stockProficiency.frequencies.items.toFive', 'fields'), value: 'TO_FIVE' },
		{ label: m('stockProficiency.frequencies.items.toTen', 'fields'), value: 'TO_TEN' },
		{ label: m('stockProficiency.frequencies.items.aboveTen', 'fields'), value: 'ABOVE_TEN' }
	];

	const getStockProficiencyVolumeItems = () => [
		{ label: m('stockProficiency.volumes.items.to5k', 'fields'), value: 'TO_5K' },
		{ label: m('stockProficiency.volumes.items.to25k', 'fields'), value: 'TO_25K' },
		{ label: m('stockProficiency.volumes.items.to50k', 'fields'), value: 'TO_50K' },
		{ label: m('stockProficiency.volumes.items.above50k', 'fields'), value: 'ABOVE_50K' }
	];

	return {
		getSalutationDropdownItems,
		getHasSecondApplicantItems,
		getCountryCodeDropdownItems,
		getStateDropdownItems,
		getNationalityItems,
		getMaritalStatusItems,
		getMaritalStatusItemsFull,
		getOccupationLimitedItems,
		getOccupationItems,
		getOccupationItemsFull,
		getTypeOfLivingItems,
		getNumberChildrenDropdownItems,
		getTinTypeItems,
		getUsTinReasonDropdownItems,
		getInvestmentPaymentTypeItems,
		getInvestmentRuntimeItems,
		getHasStockProficiencyItems,
		getStockProficiencyFrenquencyItems,
		getStockProficiencyVolumeItems
	};
};
