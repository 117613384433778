import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { m } from 'utils/messages/messages';
import { useValidationUtils } from 'utils/validation/validation';

import { Row, Col } from 'components/Atoms/Grid';
import Divider from 'components/Atoms/Divider/Divider';
import Button from 'components/Atoms/Button/Button';
import Alert from 'components/Atoms/Alert/Alert';
import SecureConnection from 'components/Atoms/Partials/SecureConnection/SecureConnection';
import SvgChevronRight from 'components/Atoms/SVG/Icons/SvgChevronRight';
import SvgChevronLeft from 'components/Atoms/SVG/Icons/SvgChevronLeft';

import { useSelector } from 'react-redux';

import styles from './Form.module.scss';

const Form = (props) => {
	const storeAppLoading = useSelector((state => state.app.appLoading));
	const storeButtonLoading = useSelector((state => state.app.buttonLoading));
	const storeErrorMessages = useSelector((state => state.form.errorMessages));
	const storeSuccessMessages = useSelector((state => state.form.successMessages));

	const { clear } = useValidationUtils();

	const [clearingErrors, setClearingErrors] = useState(true);

	useEffect(() => {
		if (storeAppLoading === 'none') {
			setTimeout(() => {
				const firstInputArray = document.querySelectorAll('[tabindex="1"]');
				if (firstInputArray.length > 0) {
					firstInputArray[0].focus();
				}
			}, 1);
		}
	}, [storeAppLoading]);

	useEffect(() => {
		if (!clearingErrors) {
			if (props.onSubmit) {
				props.onSubmit();
			}
		}
	}, [clearingErrors]);

	const onSubmit = async (event) => {
		event.preventDefault();
		setClearingErrors(true);
		if (props.clearErrors) {
			await clear();
			props.clearErrors();
			setClearingErrors(false);
		} else {
			setClearingErrors(false);
		}
	};

	const submitButton = () => {
		return (
			<>
				{props.onSubmit && (
					<div className={classNames([styles.buttonWrapper, styles.end])}>
						<Button
							buttonType="submit"
							onClick={onSubmit}
							disabled={!!props.submitDisabled}
							loading={storeButtonLoading}
							tabindex={props.submitTabindex}
							testId="submit"
							appendIcon={!props.submitText && <SvgChevronRight />}
						>
							{props.submitText ?? m('next.label', 'fields')}
						</Button>
					</div>
				)}
			</>
		);
	};

	return (
		<form
			onSubmit={(event) => {
				event.preventDefault();
			}}
		>
			{props.children}

			<div className={styles.alerts}>
				{props.formError && <Alert headline="Hinweis" messages={[{ message: props.formError }]} type="error" />}
				<Alert headline="Hinweis" messages={storeErrorMessages} type="error" />
				<Alert headline="Erfolg" messages={storeSuccessMessages} type="success" />
			</div>

			{props.submitOnly ? (
				<div className={styles.submitOnlyWrapper}>{submitButton()}</div>
			) : (
				<>
					{props.showSubmitDivider && <Divider />}
					<Row noWrap flexColumnMobile>
						<Col noGrow order={3} orderSm={1}>
							<div className={styles.buttonWrapper}>
								{props.onPrevButton && (
									<Button
										buttonStyle={props.prevButtonStyle ?? 'link'}
										onClick={(event) => props.onPrevButton(event)}
										prependIcon={props.prevButtonIcon ?? <SvgChevronLeft />}
									>
										{props.prevButtonText ? props.prevButtonText : m('prev.label', 'fields')}
									</Button>
								)}
								{props.prevButtonExtension && (
									<div className={styles.prevButtonExtension}>
										{props.prevButtonExtension}
									</div>
								)}
							</div>
						</Col>
						<Col isSpacer order={3} orderSm={2} />
						<Col noGrow order={1} orderSm={3}>
							{submitButton()}
						</Col>
					</Row>

					<div className={styles.submitFooter}>
						<Divider onlyMobile />
						<Row noWrap flexColumnMobile>
							{props.footerContent && <Col noGrow>{props.footerContent}</Col>}
							<Col isSpacer/>
							<Col noGrow>
								{props.onSubmit && (
									<>
										{props.submitExtension && (
											<div className={styles.submitExtension}>
												{props.submitExtension}
											</div>
										)}
										<div className={styles.secureConnection}>
											<SecureConnection />
										</div>
									</>
								)}
							</Col>
						</Row>
					</div>
				</>
			)}
		</form>
	);
};

Form.propTypes = {
	showSubmitDivider: PropTypes.bool,
	onSubmit: PropTypes.func,
	submitText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	submitDisabled: PropTypes.bool,
	submitTabindex: PropTypes.number,
	submitExtension: PropTypes.any,
	submitOnly: PropTypes.bool,
	clearErrors: PropTypes.func,
	formError: PropTypes.string,
	onPrevButton: PropTypes.func,
	prevButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	prevButtonStyle: PropTypes.oneOf(['primary', 'secondary', 'link', 'link-border']),
	prevButtonExtension: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	prevButtonIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	footerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	children: PropTypes.any
};

export default Form;
