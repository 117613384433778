import React from 'react';
import PropTypes from 'prop-types';

const Svg = (props) => {
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height} style={style}>
			<path d="M14.996 19.004a.998.998 0 0 1-.71-.29l-5.99-6a1 1 0 0 1 0-1.42l5.99-6a1.002 1.002 0 0 1 1.419 0 1.005 1.005 0 0 1 0 1.42l-5.293 5.29 5.293 5.29a1 1 0 0 1-.71 1.71z" />
		</svg>
	);
};

Svg.propTypes = {
	color: PropTypes.string
};

export default Svg;
